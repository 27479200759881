/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import DisplayRangeRender from './DisplayRangeRender';
import DateRender from './DateRender';
import PhoneRender from './PhoneRender';
import CurrencyRender from './CurrencyRender';
Vue.component(DisplayRangeRender.name, DisplayRangeRender);
Vue.component(DateRender.name, DateRender);
Vue.component(PhoneRender.name, PhoneRender);
Vue.component(CurrencyRender.name, CurrencyRender);
export default {
  name: "field-render",
  props: {
    val: [String, Number],
    renderType: [String, Object],
    renderConf: [String, Object],
    dataType: [String],
    format: [String]
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  watch: {}
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { Dialog } from 'vant';
import { unique } from "@/assets/app.js";
import MyTableInfo from "./MyTableInfo";
export default {
  props: {
    tremListId: {
      default: []
    }
  },
  components: {
    MyTableInfo
  },
  data() {
    return {
      showInfo: false,
      ListId: [],
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 10,
      result: [],
      itemString: "",
      wpInfoData: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
  },
  computed: {
    ListIdNew() {
      this.ListId = [];
      this.tremListId.map(item => {
        this.ListId.push(item.pkId);
      });
      return this.ListId; //watch监听要求
    }
  },

  watch: {
    ListIdNew: function (newid) {
      this.$refs.checkboxGroup.toggleAll();
      this.result = newid;
    }
  },
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var url = _baseUrl + `/customizeProc/supBasicTab/supBasicTab/listData.do?pageIndex=${pageNum}&pageSize=${this.pageSize}`;
      this.$ajax.post(url).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.identifyLabel) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 10);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        this.getCgwpInfo();
      } else {
        Dialog({
          message: '请正确输入页数！'
        });
      }
    },
    goodsInfo(item) {
      this.showInfo = true;
      this.wpInfoData = item;
    },
    //确定
    onSelects() {
      let result = [];
      this.mount.map(item => {
        this.result.map(pkid => {
          if (pkid == item.pkId) {
            result.push(item);
          }
        });
      });
      this.$emit("cgwpList", result);
    }
  }
};
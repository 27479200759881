/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import MyTable from "../common/ypcg/MyTable";
import MyTableInfo from "../common/ypcg/MyTableInfo";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format, unique } from "@/assets/app.js";
export default {
  name: "Ypcg",
  components: {
    MyTable,
    MyTableInfo
  },
  props: {
    dataMessage: {},
    cgwpDatas: Array
  },
  data() {
    return {
      showDataCG: false,
      showsyqck: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      resultFa: [],
      // cgwpDatas:[],
      wpInfoData: {}
    };
  },
  created() {},
  methods: {
    dayTime(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      return year + "-" + month + "-" + date;
    },
    onConfirmCG(timestamp) {
      this.dataMessage.cgrq = this.dayTime(timestamp);
      this.showDataCG = false;
    },
    showList() {
      this.showListpage = false;
    },
    cgwpList(list) {
      let cgwpData = [];
      this.cgwpDatas.map(item => {
        cgwpData.push(item);
      });
      list.map(item => {
        cgwpData.push(item);
      });
      this.cgwpDatas = unique(cgwpData);
      this.showListpage = false;
      console.log(this.cgwpDatas);
    },
    //查看具体信息
    goodsInfo(item) {
      this.showGinfo = true;
      this.wpInfoData = item;
    },
    //删除
    delectWP() {
      // for(let i=0;i<this.resultFa.length;i++){
      //   for(let y=0;y<this.cgwpDatas.length;y++){
      //     if (this.resultFa[i] == this.cgwpDatas[y].pkId) {
      //       this.cgwpDatas.splice(this.cgwpDatas[y],1);
      //     }
      //   }
      // }
    }
    // datas(){
    //   var jsonDataObj = {
    //     id:"",
    //     cgsy: this.data.cgsy,
    //     cgsqr: this.data.cgsqr,
    //     cgrq:this.data.cgrq,
    //     SUB_datagrid1:this.cgwpDatas,
    //   };
    //   var jsonData = JSON.stringify(jsonDataObj);
    //   var params = new FormData();
    //   params.append("solId", this.solId);
    //   params.append("bpmInstId", "");
    //   params.append("jsonData", jsonData);
    //   return params;
    // },
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import SysUser from './SysUser';
import SysGroup from './SysGroup';
import RxDialog from './RxDialog';
import RxPopup from './RxPopup';
import RxSearch from './RxSearch';
import RxSearchPanel from './RxSearchPanel';
import rxRadio from './rxRadio';
import rxCheckbox from './rxCheckbox';
import rxCheckbox_right from './rxCheckbox_right';
import FieldSet from './FieldSet';
import tabList from './tabList';
import RxTab from './RxTab';
import RxTabPanel from './RxTabPanel';
import RxForm from './rxForm';
import RxForms from './rxForms';
Vue.component(RxTab.name, RxTab);
Vue.component(RxTabPanel.name, RxTabPanel);
Vue.component(tabList.name, tabList);
Vue.component(SysUser.name, SysUser);
Vue.component(SysGroup.name, SysGroup);
Vue.component(RxDialog.name, RxDialog);
Vue.component(RxSearch.name, RxSearch);
Vue.component(RxSearchPanel.name, RxSearchPanel);
Vue.component(rxRadio.name, rxRadio);
Vue.component(rxCheckbox.name, rxCheckbox);
Vue.component(rxCheckbox_right.name, rxCheckbox_right);
Vue.component(FieldSet.name, FieldSet);
Vue.component(RxPopup.name, RxPopup);
Vue.component(RxForm.name, RxForm);
Vue.component(RxForms.name, RxForms);
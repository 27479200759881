/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.push.js";
import Popup from "vux/src/components/popup/index.vue";
import Checklist from "vux/src/components/checklist/index.vue";
import { componentAjaxJson } from "@/assets/app.js";
export default {
  components: {
    Popup,
    Checklist
  },
  name: "rx-combobox",
  props: {
    conf: String,
    name: String,
    data: Object,
    maindata: Object,
    bodefid: String,
    value: [Object, String],
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请选择"
    }
  },
  data() {
    return {
      selectJson: [],
      parentField: "",
      linkField: "",
      showPop: false,
      checkValue: [],
      config: {}
    };
  },
  computed: {
    newData() {
      if (this.data) {
        return JSON.parse(JSON.stringify(this.data));
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.data[this.name]) {
        this.checkValue = this.data[this.name].split(",");
      }
      if (!this.conf) return;
      var conf = JSON.parse(this.conf);
      this.config = conf;
      //设置自定义默认值
      var from = conf.from;
      var params = {};
      if (from == 'dic') {
        var dickey = conf.dickey;
        params = {
          dic: dickey
        };
        componentAjaxJson(conf, params, this, data => {
          this.selectJson = data;
        }); //字典是key value形式  不需要转换
      } else if (from == 'self') {
        var data = conf.data;
        this.selectJson = this.changeAttr("key", "name", "key", "value", data);
      } else if (from == 'sql' || from == 'url') {
        var param = {};
        var links = conf.conf ? JSON.parse(conf.conf) : "";
        var gridInput = [];
        if (links.sql && links.sql.customQueryCombobox) {
          gridInput = links.sql.customQueryCombobox.gridInput;
          param = this.getParamsInput(gridInput, false);
        }
        params = {
          params: param
        };
        componentAjaxJson(conf, params, this, data => {
          var textfield = conf.textfield;
          var valuefield = conf.valuefield;
          this.rtnData = data;
          this.setDefault();
          this.selectJson = this.changeAttr(valuefield, textfield, "key", "value", data);
        });
        // debugger
        //联动判断
        if (!this.readonly && gridInput && gridInput.length > 0) {
          // debugger;
          var param = {};
          for (let i = 0, j = gridInput.length; i < j; i++) {
            var linkObj = gridInput[i];
            var bindField = linkObj.bindVal;
            var index = bindField.indexOf("_gridSubTables_");
            let isSub = false;
            if (index > -1) {
              bindField = bindField.substring(index, bindField.length).replaceAll("_gridSubTables_", "");
              isSub = true;
            }
            var watchObj = isSub ? "data." + bindField : "maindata." + bindField;
            this.$watch(watchObj, function (val, oldVal) {
              this.data[this.name] = "";
              this.data[this.name + '_name'] = "";
              var param = this.getParamsInput(gridInput, isSub);
              var params = {
                params: param
              };
              var textfield = conf.textfield;
              var valuefield = conf.valuefield;
              componentAjaxJson(conf, params, this, data => {
                this.selectJson = this.changeAttr(valuefield, textfield, "key", "value", data);
              });
            });
          }
        }
      }
    }, 0);
  },
  methods: {
    getParamsInput(gridInput, isSub) {
      if (!gridInput || gridInput.length == 0) return {};
      var param = {};
      for (let i = 0, j = gridInput.length; i < j; i++) {
        var linkObj = gridInput[i];
        var bindField = linkObj.bindVal;
        var index = bindField.indexOf("_gridSubTables_");
        //子表取子表字段
        let isSub = false;
        if (index > -1) {
          bindField = bindField.substring(index, bindField.length).replaceAll("_gridSubTables_", "");
          isSub = true;
        }
        var data = isSub ? this.data : this.maindata;
        if (!data[bindField]) {//父联动字段为空
          //param[linkObj.name] = "---";
        } else {
          param[linkObj.name] = data[bindField];
        }
      }
      return param;
    },
    onChange() {
      var checkValue = this.checkValue;
      var selectJson = this.selectJson;
      var names = [];
      for (let i = 0, j = selectJson.length; i < j; i++) {
        for (let m = 0, n = checkValue.length; m < n; m++) {
          if (checkValue[m] == selectJson[i].key) {
            names.push(selectJson[i].value); //显示值
            break;
          }
        }
      }
      this.data[this.name] = checkValue.toString();
      this.data[this.name + '_name'] = names.toString();
      this.showPop = false;
    },
    onCancel() {
      this.showPop = false;
    },
    onClear() {
      this.checkValue = [];
      this.data[this.name] = "";
      this.data[this.name + '_name'] = "";
      this.showPop = false;
    },
    //属性转换
    changeAttr(valueField, textField, key, value, data) {
      var ary = [];
      for (var i = 0; i < data.length; i++) {
        var ob = {};
        ob[key] = data[i][valueField]; //key是实际值 value是本文值
        ob[value] = data[i][textField];
        ary.push(ob);
      }
      return ary;
    },
    setDefault() {
      var customScript = this.config.url == '${ctxPath}/sys/core/sysInvokeScript/data/getWorker.do';
      if (!customScript) return;
      var data = this.rtnData;
      for (var i = 0; i < data.length; i++) {
        if (data[i].isMain == "YES") {
          this.config.defaultvalue = data[i][this.config.valuefield];
          break;
        }
      }
    },
    initDefalut() {
      //设置默认值
      if (this.data[this.name + '_name']) return;
      if (!this.selectJson) return;
      let defaultvalue = this.config.defaultvalue;
      if (!defaultvalue) return;
      let filterVal = this.selectJson.filter(function (v) {
        return defaultvalue == v.key || defaultvalue == v.value;
        ;
      });
      if (!filterVal || filterVal.length == 0) {
        //直接设置
        this.data[this.name] = defaultvalue;
        this.data[this.name + '_name'] = defaultvalue;
      } else {
        this.data[this.name] = filterVal[0].key;
        this.data[this.name + '_name'] = filterVal[0].value;
      }
    }
  },
  watch: {
    selectJson: function (val, oldVal) {
      this.initDefalut();
    },
    value: {
      handler: function (newVal, oldVal) {
        if (oldVal == undefined) {
          this.initDefalut();
        }
      },
      deep: true
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import RichTextEditor from '@/components/common/RichTextEditor';
Vue.component(RichTextEditor.name, RichTextEditor);
export default {
  name: "rx-richtex",
  data() {
    return {
      cvalue: this.value
    };
  },
  props: {
    value: String,
    readonly: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "1.5rem"
    }
  },
  computed: {},
  mounted() {},
  watch: {
    cvalue: function (val, oldVal) {
      this.$emit("input", val);
    },
    value: function (val, oldVal) {
      this.cvalue = val;
    }
  }
};
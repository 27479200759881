/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('rx-boxs', {
    attrs: {
      "containstyle": "width:2.2rem;",
      "textField": "text",
      "valField": "id",
      "single": _vm.single,
      "showid": _vm.showid,
      "readonly": _vm.readonly,
      "type": 'user'
    },
    on: {
      "select": _vm.selectUserDialog
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
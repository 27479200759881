/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.readonly ? _c('div', {
    staticClass: "readOnlyBgColor"
  }, [_vm._v(_vm._s(_vm.comment))]) : _c('div', {
    staticClass: "rx-button",
    on: {
      "click": _vm.showDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.comment) + " ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
/* this file is transformed by vux-loader */
/* eslint-disable */
import { RxUtil } from "@/assets/util.js";
export default {
  name: 'rx-fieldset',
  data() {
    return {
      showContainer: '0'
    };
  },
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    }
  },
  mounted() {
    var rtn = RxUtil.getCache("fieldset_" + this.id);
    if (rtn == undefined) {
      this.showContainer = '1';
      return;
    }
    if (rtn) {
      this.showContainer = '1';
    } else {
      this.showContainer = '0';
    }
  },
  methods: {
    toggle() {
      if (this.showContainer == "1") {
        this.showContainer = "0";
      } else {
        this.showContainer = "1";
      }
      RxUtil.setCache("fieldset_" + this.id, this.showContainer);
    }
  }
};
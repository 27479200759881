import { render, staticRenderFns } from "./RxSignature.vue?vue&type=template&id=03b7d54e&scoped=true&"
import script from "./RxSignature.vue?vue&type=script&lang=js&"
export * from "./RxSignature.vue?vue&type=script&lang=js&"
import style0 from "./RxSignature.vue?vue&type=style&index=0&id=03b7d54e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b7d54e",
  null
  
)

export default component.exports
/* this file is transformed by vux-loader */
/* eslint-disable */
import { RxUtil } from "@/assets/util.js";
export default {
  name: "rx-checkbox",
  props: {
    value: String,
    conf: String,
    name: String,
    data: Object,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: ""
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.conf) return;
      var configObj = JSON.parse(this.conf);
      //没有值的话就初始化值
      if (!this.data[this.name]) {
        if (configObj.checked == 'true') {
          this.checked = 'true';
          this.data[this.name] = configObj.truevalue;
        } else {
          this.checked = 'false';
          this.data[this.name] = configObj.falsevalue;
        }
      } else {
        //本身有值
        if (this.data[this.name] == configObj.truevalue) {
          this.checked = 'true';
        } else if (this.data[this.name] == configObj.falsevalue) {
          this.checked = 'false';
        }
      }
    },
    change() {
      var inputCheckbox = this.$refs.input;
      var config = JSON.parse(this.conf);
      if (inputCheckbox.checked) {
        this.data[this.name] = config.truevalue;
      } else {
        this.data[this.name] = config.falsevalue;
      }
      this.checked = inputCheckbox.checked.toString();
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      console.info("newVal:" + newVal + ",oldVal:" + oldVal);
      this.init();
    }
  }
};
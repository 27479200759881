/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'rx-list-item',
  props: {
    type: {
      type: String,
      validator(value) {
        return ['link', 'a', 'div'].indexOf(value) > -1;
      },
      default: 'a'
    },
    href: [String, Object],
    img: String
  }
};
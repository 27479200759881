/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "rx-textarea",
  data() {
    return {
      cvalue: this.value,
      iserror: false,
      isempty: true,
      checkReq: true,
      placeholder: ""
    };
  },
  props: {
    value: String,
    conf: [Object, String],
    showClear: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxlength: Number,
    showCounter: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: function (val, oldVal) {
      this.cvalue = val;
    },
    cvalue: function (val, oldVal) {
      this.$emit("input", val);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.conf) return;
      var conf = JSON.parse(this.conf);
      //conf.value是默认值
      if (conf.value && !this.cvalue) {
        this.cvalue = conf.value;
      }

      //空文本提示
      if (conf.emptytext) {
        this.placeholder = conf.emptytext;
      }
    },
    valid(chkReq_) {
      var val = this.cvalue;
      if (chkReq_ && this.required) {
        if (val.trim() == "") {
          //this.iserror=true;
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    blurHandler(e) {
      this.iserror = !this.valid(this.checkReq);
      this.onblur && this.onblur(e);
    },
    focusHandler(e) {
      this.showClear = true;
      this.onfocus && this.onfocus(e);
    },
    clearInput() {
      this.cvalue = '';
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import router from '@/router/index';
import Signature from '@/components/common/Signature';
Vue.component(Signature.name, Signature);
export default {
  name: "rx-signature",
  data() {
    return {
      //files: [{fileId:"sd",fileName:"dd.jpg"},{fileId:"sd",fileName:"dd.xls"},{fileId:"sd",fileName:"dd.ppt"}],
      files: [],
      showSelect: false,
      showSign: false,
      single: true
    };
  },
  props: {
    value: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeChild() {
      this.showSign = false;
    },
    previewImag(index) {
      this.$preview.on('imageLoadComplete', (e, item) => {
        console.log(this.$preview.self);
      });
    },
    isImg(file) {
      var mediaType = ['.jpg', '.png', 'jpeg'];
      if (file) {
        for (var i = 0, j = mediaType.length; i < j; i++) {
          if (file.fileName && file.fileName.toLowerCase().endsWith(mediaType[i])) {
            return true;
          }
        }
      }
      return false;
    },
    imageUrl(file) {
      return this.getRealUrl() + "/sys/core/file/download/" + file.fileId + ".do";
    },
    /**
     * 删除选测图片
     */
    removeSelect(idx) {
      this.files.splice(idx, 1);
    },
    initValue(val) {
      if (!val) return [];
      var fileAry = JSON.parse(val);
      for (var i = 0; i < fileAry.length; i++) {
        var file = fileAry[i];
        file.selected = false;
      }
      this.files = fileAry;
    },
    handFiles(json) {
      var aryFiles = json.data;
      if (this.single) {
        this.files = [];
      }
      for (var i = 0; i < aryFiles.length; i++) {
        var file = aryFiles[i];
        this.files.push({
          fileId: file.fileId,
          fileName: file.fileName,
          selected: false
        });
      }
    }
  },
  mounted() {
    this.initValue(this.value);
  },
  watch: {
    files: {
      handler(newValue, oldValue) {
        var str = JSON.stringify(newValue);
        this.$emit('input', str);
      },
      deep: true
    },
    value: function (val, oldVal) {
      if (val != oldVal) {
        this.initValue(val);
      }
    }
  }
};
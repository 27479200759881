/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { loadRefreshList } from '@/assets/app.js';
import listItem from '@/components/common/ListItem';
import radio from '@/components/common/rxRadio';
import checkbox from '@/components/common/rxCheckbox';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
Vue.component(listItem.name, listItem);
Vue.component(radio.name, radio);
Vue.component(checkbox.name, checkbox);
export default {
  name: "rx-sysuser",
  data() {
    return {
      orgList: [],
      list: [],
      pageIndex: 0,
      pageSize: 20,
      total: 0,
      searchName: "",
      selectUserId: "",
      selectedUser: [],
      orgUrls: [],
      showList: false,
      showCancelBtn: false,
      showHisMenu: true,
      showUsersMenu: false,
      usersHis: [],
      conf: {},
      initdim: "",
      initranklevel: "",
      groupid: ""
    };
  },
  computed: {},
  props: {
    single: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.loadHis();
  },
  computed: {
    classBtn: function () {
      var rtn = {
        single: this.single,
        multity: !this.single
      };
      return rtn;
    }
  },
  methods: {
    initConfig(conf) {
      try {
        this.config = JSON.parse(conf);
        this.initdim = this.config.initdim;
        this.initranklevel = this.config.initranklevel;
        this.groupid = this.config.groupid;
      } catch (e) {}
    },
    mainOrg() {
      this.orgUserList();
    },
    orgUserList() {
      this.orgUrls = [];
      var url = "";
      var params = {};
      if (this.groupid) {
        url = _baseUrl + "/sys/org/sysOrg/listByDimId.do";
        params = {
          dimId: this.initdim,
          initParentId: this.groupid
        };
      } else {
        var url = _baseUrl + "/mobile/oa/jsonAll.do";
        params = {
          dimId: this.initdim
        };
      }
      this.loadLevel(params, url);
    },
    search() {
      this.orgUserList();
    },
    cancel() {
      this.$emit('cancel');
    },
    //设置选中的帐号。
    setValue(val) {
      this.selectUserId = val;
    },
    handleClick(item, index) {
      if (item.dimId && !item.groupId) {
        var url = _baseUrl + "/mobile/oa/listGroupsAndUsers.do";
        var params = {
          dimId: item.dimId,
          groupId: item.groupId,
          initRankLevel: this.initranklevel,
          loadUsers: 'true'
        };
        this.loadLevel(params, url);
      } else if (item.groupId) {
        var url = _baseUrl + "/mobile/oa/listGroupsAndUsers.do";
        var params = {
          groupId: item.groupId,
          initRankLevel: this.initranklevel,
          loadUsers: 'true'
        };
        this.loadLevel(params, url);
      } else {
        return;
      }
      if (!isNaN(index)) {
        this.orgUrls = this.orgUrls.slice(0, index + 1);
      } else {
        this.orgUrls.push(item);
      }
    },
    loadLevel(params, url) {
      this.$ajax({
        url: url,
        method: 'POST',
        params: params
      }).then(response => {
        this.orgList = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    loadHis() {
      this.usersHis = RxUtil.getSearchHis('user');
    },
    userList(initPage, merge) {
      if (initPage) {
        this.pageIndex = 0;
      } else {
        this.pageIndex++;
      }
      var url = _baseUrl + "/mobile/oa/getUsersSearch.do";
      var params = "pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
      if (this.searchName) {
        params += "&searchVal=" + this.searchName;
      }
      //加上维度限制
      params += "&initdim=" + this.initdim + "&initranklevel=" + this.initranklevel;
      loadRefreshList(url, params, this, data => {
        this.total = data.total;
      }, merge);
    },
    getFilePath(fileId) {
      return _baseUrl + "/sys/core/file/previewFile.do?fileId=" + fileId;
    },
    ok() {
      this.$emit('ok', this.selectedUser);
    },
    noDo() {
      this.$refs.infinitescroll.$emit('ydui.infinitescroll.loadedDone');
    },
    del(index) {
      this.selectedUser.splice(index, 1);
    },
    onFocus() {
      this.showCancelBtn = true;
      this.showList = true;
      this.showHisMenu = true;
      this.showUsersMenu = false;
    },
    closeSearch() {
      this.searchName = "";
      this.showList = false;
      this.showCancelBtn = false;
    },
    searchUsers() {
      if (this.searchName && this.searchName.trim()) {
        this.usersHis = RxUtil.addSearchHis(this.searchName, "user");
        this.userList(true, false);
        this.showHisMenu = false;
        this.showUsersMenu = true;
      }
    },
    clearSearchVal() {
      this.searchName = "";
      this.showHisMenu = true;
      this.showUsersMenu = false;
    },
    clearHis() {
      RxUtil.clearSearchHis("user");
      this.usersHis = [];
    },
    hisClick(val) {
      this.searchName = val;
      this.showHisMenu = false;
      this.showUsersMenu = true;
      this.userList(true, false);
    }
  },
  watch: {
    selectUserId: function (val, oldVal) {
      if (!val) {
        this.selectedUser = [];
        return;
      }
      //处理增加的用户，意思是当前选择的用户不在选择的列表中。
      var aryVal = this.selectUserId.split(",");
      var selectMap = RxUtil.covertList2Map("userId", this.selectedUser);
      //合并左右两边的人员数组
      var list = this.list.concat(this.orgList);
      var allMap = RxUtil.covertList2Map("userId", list);
      //当前用户不在选中的用户中，添加
      for (var i = 0; i < aryVal.length; i++) {
        var tmp = aryVal[i];
        if (!selectMap[tmp]) {
          this.selectedUser.push(allMap[tmp]);
        }
      }
      //删除已选
      for (var i = this.selectedUser.length - 1; i >= 0; i--) {
        var o = this.selectedUser[i];
        var id = o["userId"];
        var isIn = RxUtil.isInAry(aryVal, id);
        if (!isIn) {
          this.selectedUser.splice(i, 1);
        }
      }
    }
  }
};
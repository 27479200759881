/* this file is transformed by vux-loader */
/* eslint-disable */
import { calcRight } from "@/assets/app.js";
import { RxUtil, _validVtype } from "@/assets/util.js";
export default {
  name: "rx-input",
  props: {
    value: [String, Number],
    placeholder: [String, Object]
  },
  data() {
    return {
      currentValue: this.value
    };
  },
  mounted() {},
  methods: {
    clearInput() {
      this.currentValue = '';
    }
  },
  watch: {
    currentValue: function (val, oldVal) {
      this.$emit('input', val);
      //是否为空
    },

    value: function (val, oldVal) {
      if (val != oldVal) {
        this.currentValue = this.value;
      }
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
export default {
  name: 'rx-tab',
  data() {
    return {
      navList: [],
      activeIndex: 0,
      tmpIndex: 0
    };
  },
  props: {
    change: Function
  },
  methods: {
    init(update) {
      const tabPanels = this.$children[0].$children.filter(item => item.$options.name === 'rx-tab-panel');
      let num = 0;
      tabPanels.forEach((panel, index) => {
        if (update === 'label') {
          return this.navList[index] = panel;
        }
        if (!update) {
          this.navList.push({
            label: panel.label,
            _uid: panel._uid,
            tabkey: panel.tabkey
          });
        }
        if (panel.active) {
          this.activeIndex = this.tmpIndex = panel._uid;
          this.emitChange(panel.label, panel.tabkey);
        } else {
          ++num;
          if (num >= tabPanels.length) {
            this.activeIndex = this.tmpIndex = tabPanels[0]._uid;
            this.emitChange(tabPanels[0].label, tabPanels[0].tabkey);
          }
        }
      });
    },
    emitChange(label, tabkey) {
      typeof this.change == 'function' && this.change(label, tabkey);
    },
    changeHandler(uid, label, tabkey) {
      if (this.tmpIndex != uid) {
        this.activeIndex = this.tmpIndex = uid;
        this.emitChange(label, tabkey);
      }
      if (this.tmpIndex != this.navList[0]._uid) {
        this.$parent.$parent.$parent.tabbarBtn = false;
      } else {
        this.$parent.$parent.$parent.tabbarBtn = true;
      }
    }
  },
  mounted() {
    this.init(false);
    var hs = document.getElementById('tab-Box');
    var thisParent = hs.parentNode.parentNode.parentNode;
    var h = thisParent.offsetHeight;
    hs.style.height = h + "px";
  }
};
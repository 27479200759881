/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.replace-all.js";
export var RxUtil = {};
/**
 * 设置缓存。
 */
RxUtil.setCache = function (key, value) {
  localStorage[key] = value;
};

/**
 * 获取缓存
 */
RxUtil.getCache = function (key) {
  return localStorage[key];
};

/**
 * 删除缓存
 */
RxUtil.cleanCache = function (key) {
  localStorage.removeItem(key);
};

/**
 * 设置缓存，value 为JSON对象。
 */
RxUtil.setJSON = function (key, value) {
  var json = JSON.stringify(value);
  localStorage[key] = json;
};

/**
 * 根据键获取json对象。
 */
RxUtil.getJSON = function (key) {
  var json = localStorage[key];
  if (json == undefined) return null;
  return JSON.parse(json);
};
RxUtil.getParameters = function () {
  var locUrl = window.location.search.substr(1);
  var aryParams = locUrl.split("&");
  var json = {};
  for (var i = 0; i < aryParams.length; i++) {
    var pair = aryParams[i];
    var aryEnt = pair.split("=");
    var key = aryEnt[0];
    var val = aryEnt[1];
    if (json[key]) {
      json[key] = json[key] + "," + val;
    } else {
      json[key] = val;
    }
    return json;
  }
};

/**
 * 根据参数名称，获取上下文中的参数。
 */
RxUtil.getParameter = function (name) {
  var locUrl = window.location.search.substr(1);
  var aryParams = locUrl.split("&");
  var rtn = "";
  for (var i = 0; i < aryParams.length; i++) {
    var pair = aryParams[i];
    var aryEnt = pair.split("=");
    var key = aryEnt[0];
    var val = aryEnt[1];
    if (key != name) continue;
    if (rtn == "") {
      rtn = val;
    } else {
      rtn += "," + val;
    }
  }
  return rtn;
};
RxUtil.getParameterByIndex = function (index) {
  var locUrl = window.location.hash.replace("#/");
  var aryParams = locUrl.split("/");
  if (index >= 0 && index < aryParams.length) {
    return aryParams[index];
  }
  return "";
};
RxUtil.get = function (id) {
  return document.getElementById(id);
};

/**
 * 将对象序列化成地址栏参数。
 * 
 * @param {Object} obj
 */
RxUtil.serialJsonToForm = function (obj) {
  var aryForm = [];
  for (var key in obj) {
    if (obj[key]) {
      aryForm.push(key + "=" + obj[key]);
    }
  }
  if (aryForm.length == 0) return "";
  return aryForm.join("&");
};
RxUtil.sendXmlHttpRequest = function (url, json) {
  var params = this.serialJsonToForm({
    "params": JSON.stringify(json)
  });
  //创建xhr对象 
  var xhr = new XMLHttpRequest();
  //创建一个 post 请求，采用同步
  xhr.open('post', url, false);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
  xhr.send(params);
  return xhr.responseText;
};

/**
 * 将list转成map对象
 * [{id:"u1",name:name1},{id:"u2",name:name2}]
 * 转换成
 * {
 * 	u1:{id:"u1",name:name1},
 *  u2:{id:"u2",name:name2}
 * }
 * @param {Object} key
 * @param {Object} list
 */
RxUtil.covertList2Map = function (key, list) {
  if (!list || list.length == 0) return {};
  var rtnObj = {};
  var aryKeys = key.split(".");
  for (var i = 0; i < list.length; i++) {
    var o = list[i];
    var tmpObj = o;
    for (var j = 0; j < aryKeys.length; j++) {
      var tmpKey = aryKeys[j];
      tmpObj = tmpObj[tmpKey];
    }
    rtnObj[tmpObj] = o;
  }
  return rtnObj;
};

/**
 *  
 * 数组中是否存在key值。
 * [1,2,3] 是否存在3
 */
RxUtil.isInAry = function (ary, val) {
  for (var i = 0; i < ary.length; i++) {
    if (ary[i] == val) return true;
  }
  return false;
};

/**
 * 
 * 判断列表中指定 数据。
 * 示例：
 * [{id:"u1",name:name1},{id:"u2",name:name2}]
 * name 为id
 * val为 u1
 */
RxUtil.isInList = function (ary, name, val) {
  for (var i = 0; i < ary.length; i++) {
    if (ary[i][name] == val) return true;
  }
  return false;
};

/**
 * 在数组中删除指定的值。
 * @param {Object} ary
 * @param {Object} val
 */
RxUtil.removeByVal = function (ary, val) {
  for (var i = ary.length - 1; i >= 0; i--) {
    if (ary[i] != val) continue;
    ary.splice(i, 1);
  }
};

/**
 * 设置会话缓存。
 */
RxUtil.setSessionCache = function (key, value) {
  sessionStorage[key] = value;
};

/**
 * 获取会话缓存
 */
RxUtil.getSessionCache = function (key) {
  return sessionStorage[key];
};

/**
 * 根据键获取json对象。
 */
RxUtil.getSessionJSON = function (key) {
  var json = sessionStorage[key];
  if (json == undefined) return null;
  return JSON.parse(json);
};

/**
 * 设置JSON数据。
 * @param {Object} key
 * @param {Object} json
 */
RxUtil.setSessionJSON = function (key, json) {
  sessionStorage[key] = JSON.stringify(json);
};

/**
 * 清除会话缓存。
 * @param {Object} key
 */
RxUtil.clearSessionCache = function (key) {
  sessionStorage.removeItem(key);
};

/**
 * 中文转换
 * @param {Object} n
 */
RxUtil.toChineseMoney = function (n) {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return "数据非法";
  var unit = "千百拾亿千百拾万千百拾元角分",
    str = "";
  n += "00";
  var p = n.indexOf('.');
  if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
  unit = unit.substr(unit.length - n.length);
  for (var i = 0; i < n.length; i++) str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
  return str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
};

/**
 * 设置HTML
 * @param {Object} id
 * @param {Object} html
 */
RxUtil.setHtml = function (id, html) {
  var reg = new RegExp("&#39;", "g");
  html = html.replace(reg, "'");
  reg = new RegExp("&amp;", "g");
  html = html.replace(reg, "&");
  var container = document.getElementById(id);
  container.innerHTML = html;
  var aryScripts = container.getElementsByTagName('script');
  if (!aryScripts || aryScripts.length == 0) return;
  for (var i = 0; i < aryScripts.length; i++) {
    var oldScript = aryScripts[i];
    container.removeChild(oldScript);
    var src = oldScript.getAttribute("src");
    var newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    if (src) {
      newScript.setAttribute("src", src);
    } else {
      newScript.innerHTML = oldScript.innerHTML;
    }
    container.parentNode.appendChild(newScript);
  }
};

/**
 * 插入脚本
 * id：容器ID
 * aryScript:脚本对象。
 *[
 *	{type:"src",content:"脚本地址"},
 *	{type:"script",content:"脚本内容"}
 *]
 */
RxUtil.insertScript = function (id, aryScript) {
  if (!aryScript) return;
  var container = document.getElementById(id);
  for (var i = 0; i < aryScript.length; i++) {
    var obj = aryScript[i];
    var newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    if (obj.type == "src") {
      newScript.setAttribute("src", obj.content);
    } else {
      newScript.innerHTML = obj.content;
    }
    container.appendChild(newScript);
  }
};

/**
 * 解析html 将html和脚本进行分离。
 * 返回数据格式为:
 *	{html:"html内容",
 * 		script:[
 * 			{type:"src",content:"脚本地址"},
 * 			{type:"script",content:"脚本内容"}
 * 		]
 *	}
 * @param {Object} html
 */
RxUtil.parseHtml = function (html) {
  var regSrc = /<script\s*?src=('|")(.*?)\1\s*>(.|\n|\r)*?<\/script>/img;
  var match = regSrc.exec(html);
  var aryToReplace = [];
  var rtn = [];
  while (match != null) {
    aryToReplace.push(match[0]);
    var o = {
      type: "src",
      content: match[2]
    };
    rtn.push(o);
    match = regSrc.exec(html);
  }
  //替换脚本
  for (var i = 0; i < aryToReplace.length; i++) {
    html = html.replace(aryToReplace[i], "");
  }
  aryToReplace = [];
  var regScript = /<script(?:\s+[^>]*)?>((.|\n|\r)*?)<\/script>/img;
  var match = regScript.exec(html);
  var scripts = "";
  while (match != null) {
    aryToReplace.push(match[0]);
    scripts += match[1];
    match = regScript.exec(html);
  }
  rtn.push({
    type: "script",
    content: scripts
  });
  //替换脚本
  for (var i = 0; i < aryToReplace.length; i++) {
    html = html.replace(aryToReplace[i], "");
  }
  return {
    html: html,
    script: rtn
  };
};

//判断字符串是否为json字符串
RxUtil.isJson = function (str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('error：' + str + '!!!' + e);
      return false;
    }
  }
  console.log('It is not a string!');
};

/**
 * 判断数据是否为空。 
 * @param {Object} val
 */
RxUtil.isEmpty = function (val) {
  if (val == undefined) return true;
  if (val == null) return true;
  if (typeof val == "string") {
    return val.trim() == "";
  }
  return false;
};
RxUtil.getSearchHis = function (key) {
  let his = localStorage.getItem(key + "_searchHis");
  if (his) {
    try {
      his = JSON.parse(his);
    } catch (e) {
      return [];
    }
  } else {
    his = [];
  }
  return his;
};
RxUtil.addSearchHis = function (data, key) {
  let his = localStorage.getItem(key + "_searchHis");
  if (his) {
    try {
      his = JSON.parse(his);
    } catch (e) {
      his = [];
    }
  } else {
    his = [];
  }
  var obj = {};
  obj.val = data;
  his.splice(0, 0, obj);
  localStorage.setItem(key + "_searchHis", JSON.stringify(his));
  return his;
};
RxUtil.clearSearchHis = function (key) {
  localStorage.setItem(key + "_searchHis", "");
};
Date.prototype.Format = function (fmt) {
  //author: meizz
  var o = {
    "M+": this.getMonth() + 1,
    //月份
    "d+": this.getDate(),
    //日
    "h+": this.getHours(),
    //小时
    "m+": this.getMinutes(),
    //分
    "s+": this.getSeconds(),
    //秒
    "q+": Math.floor((this.getMonth() + 3) / 3),
    //季度
    "S": this.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  return fmt;
};

/**
 * 日期相减。
 * 参数1： date 
 * 参数2：减的日期格式。
 * @param {Object} date
 * @param {Object} format
 */
Date.prototype.subtract = function (date, format, fixed) {
  var minute = (this.getTime() - date.getTime()) / (60 * 1000);
  var day = parseFloat(minute / (24 * 60));
  var hour = parseFloat(minute / 60);
  var rtn = 0;
  switch (format) {
    //分钟
    case 1:
      rtn = minute;
      break;
    //小时
    case 2:
      rtn = hour;
      break;
    //天数
    default:
      rtn = day;
  }
  if (fixed) {
    rtn = rtn.toFixed(fixed);
  }
  return rtn;
};
String.prototype.replaceAll = function (toReplace, replaceWith) {
  var reg = new RegExp(toReplace, "igm");
  var rtn = this.replace(reg, replaceWith);
  return rtn;
};
String.prototype.startWith = function (str) {
  var reg = new RegExp("^" + str);
  return reg.test(this);
};
String.prototype.endWith = function (str) {
  var reg = new RegExp(str + "$");
  return reg.test(this);
};
String.prototype.trim = function () {
  return this.replace(/(^\s*)|(\s*$)/g, "");
};

/**
 * 将字符串解析为日期。 
 */
String.prototype.parseDate = function () {
  //yyyy-MM-dd
  var year = 1970;
  //yyyy-MM-dd
  var aryDateTime = this.split(" ");
  var date = aryDateTime[0];
  var aryTmp = date.split("-");
  var year = parseInt(aryTmp[0]);
  var mon = parseInt(aryTmp[1]) - 1;
  var day = parseInt(aryTmp[2]);
  var hour = 0;
  var minute = 0;
  if (aryDateTime.length == 2) {
    var time = aryDateTime[1];
    var aryTmp = time.split(":");
    hour = parseInt(aryTmp[0]);
    minute = parseInt(aryTmp[1]);
  }
  return new Date(year, mon, day, hour, minute);
};
Array.prototype.contains = function (obj) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == obj) {
      return true;
    }
  }
  return false;
};

/**
 * 自定义验证规则。 
 */
export var VTypes = {
  isEnglish: {
    msg: "必须输入英文或下划线",
    valid: function (v) {
      if (/^[a-zA-Z\_]+$/g.test(v)) return true;
      return false;
    }
  },
  isEnglishAndNumber: {
    msg: "必须输入英文或数字",
    valid: function (v) {
      if (/^[0-9a-zA-Z\_]+$/g.test(v)) return true;
      return false;
    }
  },
  isKeyLabel: {
    msg: "必须输入英文开头",
    valid: function (v) {
      if (/^[a-zA-Z][a-zA-Z0-9]*$/.test(v)) return true;
      return false;
    }
  },
  isNumber: {
    msg: "必须输入数字",
    valid: function (v) {
      if (/^[-+]?[0-9]+(\.[0-9]+)?$/g.test(v)) return true;
      return false;
    }
  },
  isInteger: {
    msg: "必须输入整数",
    valid: function (v) {
      var re = new RegExp("^[-+]?[0-9]+$");
      if (re.test(v)) return true;
      return false;
    }
  },
  isPositiveInteger: {
    msg: "必须输入正整数",
    valid: function (v) {
      if (/^[1-9]\d*$/g.test(v)) return true;
      return false;
    }
  },
  isNegtiveInteger: {
    msg: "必须输入负整数",
    valid: function (v) {
      if (/^-[0-9]\d*$/g.test(v)) return true;
      return false;
    }
  },
  isFloat: {
    msg: "必须输入浮点数",
    valid: function (v) {
      if (/^-?([1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0)$/g.test(v)) return true;
      return false;
    }
  },
  isPositiveFloat: {
    msg: "必须输入正浮点数",
    valid: function (v) {
      if (/^([1-9]\d*\.\d*|0\.\d*[1-9]\d*)$/g.test(v)) return true;
      return false;
    }
  },
  isNegtiveFloat: {
    msg: "必须输入负浮点数",
    valid: function (v) {
      if (/^-([1-9]\d*\.\d*|0\.\d*[1-9]\d*)$/g.test(v)) return true;
      return false;
    }
  },
  isChinese: {
    msg: "必须输入中文",
    valid: function (v) {
      var re = new RegExp("^[\u4e00-\u9fa5]+$");
      if (re.test(v)) return true;
      return false;
    }
  },
  IDCard: {
    msg: "必须输入15-18位数字",
    valid: function (v) {
      if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(v)) return true;
      return false;
    }
  },
  isUrl: {
    msg: "必须输入合法Url地址",
    valid: function (v) {
      var reg = new RegExp("^[A-Za-z]+://[A-Za-z0-9-_]+\\.[A-Za-z0-9-_%&\?\/.=]+$");
      if (reg.test(v)) return true;
      return false;
    }
  },
  isEmail: {
    msg: "必须输入合法Email地址",
    valid: function (v) {
      if (/^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/g.test(v)) return true;
      return false;
    }
  },
  isChinesePostCode: {
    msg: "必须输入合法中国邮编地址",
    valid: function (v) {
      if (/^[1-9]\d{5}(?!\d)$/g.test(v)) return true;
      return false;
    }
  },
  isChinesePhone: {
    msg: "必须输入合法中国固话号码",
    valid: function (v) {
      if (/^\d{3}-\d{8}|\d{4}-\d{7}$/g.test(v)) return true;
      return false;
    }
  },
  isChineseMobile: {
    msg: "必须输入合法中国手机号码",
    valid: function (v) {
      if (/^1[3456789]\d{9}$/.test(v)) return true;
      return false;
    }
  },
  isIP: {
    msg: "必须输入合法IP地址",
    valid: function (v) {
      if (/^\d+\.\d+\.\d+\.\d+$/g.test(v)) return true;
      return false;
    }
  },
  isQQ: {
    msg: "必须输入正确QQ号码",
    valid: function (v) {
      if (/^[1-9][0-9]{4,}$/g.test(v)) return true;
      return false;
    }
  },
  float: {
    msg: "必须输入浮点数",
    valid: function (v) {
      if (/^-?([1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0|\d*)$/g.test(v)) return true;
      return false;
    }
  },
  len: {
    msg: function (v, args) {
      return "数据长度:" + v.toString().length + ",超出设定" + args;
    },
    valid: function (v, args) {
      if (!v) return true;
      if (v.toString().length <= parseInt(args)) {
        return true;
      }
      return false;
    }
  },
  length: {
    msg: function (v, args) {
      return "数据长度:" + v.toString().length + ",超出设定" + args;
    },
    valid: function (v, args) {
      if (!v) return true;
      if (v.toString().length <= parseInt(args)) {
        return true;
      }
      return false;
    }
  },
  minnum: {
    msg: "小于最小边界",
    valid: function (v, args) {
      if (v < parseFloat(args)) {
        return false;
      }
      return true;
    }
  },
  maxnum: {
    msg: "大于最小边界",
    valid: function (v, args) {
      if (v > parseFloat(args)) {
        return false;
      }
      return true;
    }
  }
};

/**
 * 根据vtype 进行验证。 
 */
export function _validVtype(val, vtype) {
  var aryRule = vtype.split(";");
  for (var i = 0; i < aryRule.length; i++) {
    var rule = aryRule[i];
    var aryTmp = rule.split(":");
    var pre = aryTmp[0];
    var params = "";
    var validObj = VTypes[pre];
    if (!validObj) continue;
    if (aryTmp.length == 1) {
      var rtn = validObj.valid(val);
      if (!rtn) {
        return {
          valid: false,
          msg: validObj.msg
        };
      }
    } else if (aryTmp.length == 2) {
      params = aryTmp[1];
      var rtn = validObj.valid(val, params);
      if (!rtn) {
        return {
          valid: false,
          msg: typeof validObj.msg == 'function' ? validObj.msg(val, params) : validObj.msg
        };
      }
    }
  }
  return {
    valid: true,
    msg: validObj.msg
  };
}
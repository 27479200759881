/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
export default {
  name: 'signature',
  data() {
    return {
      stageInfo: '',
      imgUrl: '',
      imgUrlList: [],
      client: {},
      points: [],
      canvasTxt: null,
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      endY: 0,
      endX: 0,
      w: null,
      h: null,
      isDown: false
    };
  },
  mounted() {
    let canvas = this.$refs.canvasF;
    canvas.height = this.$refs.canvasHW.offsetHeight - 0;
    canvas.width = this.$refs.canvasHW.offsetWidth - 0;
    this.canvasTxt = canvas.getContext('2d');
    this.canvasTxt.lineWidth = 4;
    this.stageInfo = canvas.getBoundingClientRect();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      // session.clear()
    },

    //mobile
    touchStart(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clienX,
          y: ev.targetTouches[0].clientY
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
      }
    },
    touchMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stageInfo.left,
          y: ev.targetTouches[0].clientY - this.stageInfo.top
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },
    touchEnd(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stageInfo.left,
          y: ev.targetTouches[0].clientY - this.stageInfo.top
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
      }
    },
    //pc
    mouseDown(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (1) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();

        // this.canvasTxt.strokeRect(20,20,80,100);
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.isDown = true;
      }
    },
    mouseMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (this.isDown) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },
    mouseUp(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (1) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.points.push({
          x: -1,
          y: -1
        });
        this.isDown = false;
      }
    },
    //重写
    overwrite() {
      this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height);
      this.points = [];
    },
    //确定签名
    commit() {
      this.imgUrl = this.$refs.canvasF.toDataURL();
      this.rotateBase64(this.imgUrl).then(base64String => {
        var bytes = window.atob(base64String.split(',')[1]);

        //处理异常，将ASCII码小于0的转换为大于0，这里有两种写法
        var ab = new ArrayBuffer(bytes.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i); //这里有点疑惑，ia是怎么改变ab的？注：①
        }
        //Blob对象
        var blob = new Blob([ab], {
          type: 'image/jpeg'
        }); //type为图片的格式

        //FormData对象
        var formdata = new FormData();
        //TDOD Ajax或者其他方式上传FormData对象

        //FormData对象接受三个参数，第三个参数为文件名，通常我们只传前两个参数，第三个参数不传则使用默认文件名，这里使用的Blob对象，所以需要一个文件名，用时间戳代替。
        formdata.append('file', blob, Date.now() + '.jpg');
        var url = this.getRealUrl() + "/oa/signature/uploadSignature.do";
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        var _self = this;
        this.$dialog.loading.open('正在上传...');
        this.$uploadJson.post(url, formdata, config).then(response => {
          _self.$dialog.loading.close();
          if (response.status == 200) {
            _self.$parent.handFiles(response.data);
            _self.close();
          }
        }).catch(error => {
          _self.$dialog.loading.close();
          this.errorToast('上传签名出错！', 1000);
        });

        //this.imgUrlList.push(this.imgUrl)
        //if(this.imgUrlList.length > 0) {
        //	this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height)
        //	this.points = []
        //}
      });
    },

    deleteAll() {
      this.imgUrlList = [];
    },
    close() {
      this.$emit("close");
    },
    // 提交签名给前一页
    commitAll() {
      // 用canvas合并多张图片的base64为一张图的base64
      var canvas = document.createElement("canvas");
      canvas.width = 75 * this.imgUrlList.length;
      canvas.height = 100;
      var context = canvas.getContext("2d");
      context.rect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "#fff";
      context.fill();
      var myImage = new Image();
      myImage.crossOrigin = 'Anonymous';
      // 当签名列表有值时
      if (this.imgUrlList.length > 0) {
        for (let i = 0; i < this.imgUrlList.length; i++) {
          myImage.src = this.imgUrlList[i];
          // 多张图片绘制成一张图片
          context.drawImage(myImage, 50 * i, 0, 75, 75); //context.drawImage(img,x,y,width,height);
          // context.font = "60px Courier New";
          // context.fillText("我是文字",350,450);
        }

        var base64 = canvas.toDataURL("image/jpg"); //"image/jpg" 这里注意一下
      }
    },

    rotateBase64(url) {
      //传入需要旋转的base64图片
      return new Promise((resolve, reject) => {
        const imgView = new Image();
        imgView.src = url;
        var canvas = document.createElement("canvas");
        const context = canvas.getContext('2d');
        const cutCoor = {
          sx: 0,
          sy: 0,
          ex: 0,
          ey: 0
        }; // 裁剪坐标
        imgView.onload = () => {
          const imgW = imgView.width;
          const imgH = imgView.height;
          const size = imgH;
          canvas.width = size * 2;
          canvas.height = size * 2;
          cutCoor.sx = size;
          cutCoor.sy = size - imgW;
          cutCoor.ex = size + imgH;
          cutCoor.ey = size + imgW;
          context.translate(size, size);
          context.rotate(Math.PI / 2 * 3);
          context.drawImage(imgView, 0, 0);
          const imgData = context.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
          canvas.width = imgH;
          canvas.height = imgW;
          context.putImageData(imgData, 0, 0);
          resolve(canvas.toDataURL());
        };
      });
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export const pageModule = {
  //state 数据
  state: {
    topTabData: 0,
    keyboard: true,
    fromUser: [],
    countNum: {},
    messagePopup: {},
    moveIndex: {
      "aa": 1
    }
  },
  //同步定义
  mutations: {
    setTopTabData(state, data) {
      state.topTabData = data;
    },
    setKeyboard(state, data) {
      /* 窗口变化数据，解决安卓输入法问题
       * 用法参考login.vue 的 computed
       */
      state.keyboard = data;
    },
    srtFromUser(state, data) {
      state.fromUser = data;
    },
    indexCount(state, data) {
      state.countNum = data;
    },
    changeCount(state, data) {
      if (data.vel == '+') {
        state.countNum[data.mes] += 1;
      } else if (data.vel == '-') {
        state.countNum[data.mes] -= 1;
      }
    },
    messagePopup(state, data) {
      state.messagePopup = data;
    },
    indexNum(state, data) {
      state.moveIndex[data.address] = data.index;
    }
  },
  actions: {
    //异步数据管理
    getCountNum({
      commit
    }, This) {
      var url = _baseUrl + "/mobile/bpm/getCounts.do";
      This.$ajax.get(url).then(response => {
        commit("indexCount", response.data);
      });
    }
  }
  /*
  getters: {
   getCount(state) {
     return state.count + 5;
   },
  }*/
};
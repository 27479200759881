/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'fwygypcg-Module',
  data() {
    return {
      data1: [],
      data2: [],
      show: false,
      item: {}
    };
  },
  props: {
    instId: String,
    user_No: String
  },
  created() {
    this.getHistory();
  },
  methods: {
    showPopup(item) {
      this.item = item;
      this.show = true;
    },
    getHistory() {
      var url = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + this.instId + "&userAccount=" + this.user_No;
      var pkid = '';
      this.$ajax.post(url).then(response => {
        var resData = response.data.data.bos[0];
        pkid = resData.pkId;
        var url2 = _baseUrl + "/servicegyp/fwgypcgsqg/fwGypCgSqg/getJson.do?ids=" + pkid; //
        this.$ajax.post(url2).then(response => {
          this.data1 = response.data;
          var data_save = {};
          data_save.id = this.data1.id;
          data_save.cgdbh = this.data1.cgdbh;
          data_save.cgsqr = this.data1.cgsqr;
          data_save.cgbm = this.data1.cgbm;
          data_save.cgrq = this.data1.cgrq;
          data_save.cgsy = this.data1.cgsy;
          data_save.bz = this.data1.bz;
          data_save.totalprice = this.data1.totalprice;
          this.$store.commit('setErrorModuleData', data_save); // 把数据传出去。传给errorModuleData,可以搜定义，在审批界面可以取到。
          // console.log(this.data1);
        });

        var url3 = _baseUrl + "/servicegyp/fwgypcgdetail/fwGypCgDetail/listData.do?Q_CGID_S_LK=" + pkid;
        this.$ajax.post(url3).then(res => {
          this.data2 = res.data.data;
          this.$store.commit('setErrorModuleData2', this.data2); //传出去再我的待办里可能要用到。
        });
      });
    },

    dateToTime(cTime) {
      let date = new Date(cTime);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      date = year + '-' + month + '-' + day;
      //console.log(date); // 2018-10-09
      return date;
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { loadFormInst, convertBoJson, initFormModels, format } from '@/assets/app.js';
import { openDialogByAlias } from '@/assets/CustomDialog.js';
import { RxUtil } from "@/assets/util.js";
import { storage } from "../../assets/myjs/glovar";
import router from '@/router/index';
import '@/components/common/form';
import '@/components/common';
import ApproveOpinions from '@/components/bpm/ApproveOpinions';
import FwygypcgModule from '@/components/bpm/ErrorModule/fwygypcg.vue'; //服务与供应品采购
import QjhcjhModule from '@/components/bpm/ErrorModule/qjhcjh.vue'; //期间核查计划

Vue.component(FwygypcgModule.name, FwygypcgModule);
Vue.component(QjhcjhModule.name, QjhcjhModule);
Vue.component(ApproveOpinions.name, ApproveOpinions);
import Ypcg from "../../view/form/Ypcg";
// import QjjcList from "../../view/common/qjhcInfo/QjjcList.vue";
Vue.component(Ypcg.name, Ypcg);
// Vue.component(QjjcList.name,QjjcList);
export default {
  data() {
    return {
      newOrOld: 1,
      //1 Old  2 new
      switchModule: {
        fwygypcg: false,
        //服务与供应品采购 开关
        qjhcjh: false //期间核查计划
        //用品采购先不做
      },

      tabbarBtn: true,
      data: {},
      formData: {},
      bpmTask: {},
      bpmTask2: {},
      //没用的对象。不写组件不显示。

      destNodeUserList: [],
      isReachEndEvent: false,
      title: "任务审批",
      formModels: [],
      taskId: "",
      instId: '',
      //busad:'',
      user_No: '',
      bpmTypeName: "",
      buttonType: "",
      flowImageId: "",
      showMenus: false,
      nodeUsers: [],
      canReject: false,
      currentViews: [],
      formVms: [],
      buttons: [],
      isShowDiscardBtn: false,
      bpmInst: {},
      allowChangePath: false,
      //form显示与隐藏
      otherShow: true,
      ypcgShow: false,
      qjhcShow: false,
      ypcgMessge: {},
      wpLists: []
    };
  },
  beforeRouteLeave(to, from, next) {
    this.saveData();
    var opened = false;
    if (typeof this.formVms == 'undefined') {
      next();
      return;
    }
    var formVmsL = this.formVms.length;
    if (formVmsL == 0) next();
    for (var i = 0; i < formVmsL; i++) {
      var vm = this.formVms[i];
      if (vm.showUserDialog || vm.showGroupDialog) {
        opened = true;
        vm.showUserDialog = false;
        vm.showGroupDialog = false;
      }
      if (!opened) {
        next();
      }
    }
  },
  mounted() {
    console.log(this.formVms);
    this.user_No = RxUtil.getCache("userNo");
    this.taskId = this.$route.params.taskId;
    this.instId = this.$route.params.instId;
    //this.busad = this.this.$route.params.
    this.bpmTypeName = this.$route.params.bpmTypeName; //模块名称

    console.log("TaskDeetail...params = ", this.$route.params);

    //this.bpmTask={ instId:this.$route.params.instId};//先把instId保存把审批列表拿到

    // var ErrorModule=[];//需要自己写的有错误的模块 '服务与供用品采购','会议室申请'
    // if( ErrorModule.indexOf(this.bpmTypeName)==-1)//模块不在错误名单中，用以前的oldNet
    // {
    //   this.newOrOld = 2;
    //   this.oldNet();
    // }else{
    //   this.newOrOld = 1;
    //   this.newNet();  //错误名单中的调用方法
    // }

    this.oldNet();
  },
  methods: {
    newComponents() {
      if (this.bpmTypeName == '服务与供用品采购') {
        this.otherShow = false;
        this.switchModule.fwygypcg = true; //开启对应的组件
      } else if (this.bpmTypeName == '期间核查计划') {
        this.otherShow = false;
        this.switchModule.qjhcjh = true; //开启对应的组件
      }
    },

    oldNet() {
      var formMobileIds = RxUtil.getCache("formMobileIds");
      var url = _baseUrl + "/mobile/bpm/getTaskInfo.do?taskId=" + this.taskId;
      var params = {};
      if (formMobileIds) {
        params = "versionJson=" + formMobileIds;
      }
      this.$ajax.post(url, params).then(response => {
        this.newComponents(); //界面展示有问题的模块，换组件展示
        console.log(response);
        //-------------改--------------
        this.handResult(response);

        // if(response.data.formModels[0].formKey=="PUR_REQUISITION" ){
        // 	this.otherShow=false;
        // 	this.ypcgShow=true;
        // 	let busKey = response.data.bpmInst.busKey;
        // 	let urlwp= _baseUrl+`/customizeProc/purRequisition/purRequisition/getJson.do?ids=${busKey}`;
        //   this.$ajax.post(urlwp).then(resData=>{
        // 		this.ypcgMessge = resData.data;
        // 		this.ypcgMessge.cgrq = format(this.ypcgMessge.cgrq);
        // 		storage.setItem("ypcgMessge",JSON.stringify(this.ypcgMessge));
        // 	})
        // 	let urlWPlist = _baseUrl+`/customizeProc/supIosTab/supIosTab/findByCgid.do?cgid=${busKey}&pageIndex=0&pageSize=1000000`;
        // 	this.$ajax.post(urlWPlist).then(resList=>{
        // 		this.wpLists = resList.data.data;
        // 		storage.setItem("wpLists",JSON.stringify(this.wpLists));
        // 	})
        // }else if(response.data.formModels[0].formKey=="QJHCJHSP"){
        // 	this.otherShow=false;
        // 	this.qjhcShow=true;
        // }
      });
    },

    // goBack(){
    // 	this.$router.go(-1);
    // },
    //固定值判断
    calcButton(alias) {
      var bts = this.buttons;
      if (bts.length == 0) return true;
      var flag = false;
      //有按钮权限则返回true
      bts.forEach(item => {
        if (item.alias == alias) {
          flag = true;
          return;
        }
      });
      return flag;
    },
    calcName(alias, defaultName) {
      var bts = this.buttons;
      if (bts.length == 0) return defaultName;
      //有按钮权限则返回true
      bts.forEach(item => {
        if (item.alias == alias) {
          defaultName = item.name;
          return;
        }
      });
      return defaultName;
    },
    handleReject() {
      router.push({
        name: 'reject'
      });
    },
    handleCommu() {
      router.push({
        name: 'commu',
        params: {
          taskId: this.taskId
        }
      });
    },
    handleTrans() {
      router.push({
        name: 'trans',
        params: {
          taskId: this.taskId
        }
      });
    },
    handleRevokeCommu() {
      router.push({
        name: 'RevokeCommu',
        params: {
          taskId: this.taskId
        }
      });
    },
    handleReplyCommu() {
      router.push({
        name: 'ReplyCommu',
        params: {
          taskId: this.taskId
        }
      });
    },
    saveData() {
      this.$store.commit('setData', this.data);
    },
    handleTask() {
      var aryVm = this.formVms;
      console.log(aryVm);
      //return;

      if (aryVm && aryVm.length > 0) {
        for (var i = 0; i < aryVm.length; i++) {
          var vm = aryVm[i];
          var rtn = vm.valid(true);
          //校验主子表字段
          if (!rtn.valid) {
            this.errorToast(rtn.msg, 2000);
            return;
          }
          //校验子表行数据
          var res = vm.validSubTable();
          if (!res.success) {
            this.errorToast(res.msg);
            return;
          }
          let bodefid = vm.boDefId;
          if (window['selfValidate_' + bodefid]) {
            var tmp = window['selfValidate_' + bodefid](vm, true);
            if (tmp && !tmp.valid) {
              this.errorToast(tmp.msg, 2000);
              return;
            }
          }
        }
      }

      //提交信息传递
      var jsonData = convertBoJson(this.formModels);
      if (window.handFormData) {
        var data = {
          jsonData: jsonData,
          bpmTask: this.bpmTask
        };
        var rtn = window.handFormData(data);
        if (!rtn.isValid) {
          this.errorToast(rtn.message, 2000);
          return;
        }
      }
      router.push({
        name: 'approve',
        params: {
          bpmTypeName: this.bpmTypeName
        }
      });
    },
    handResult(response) {
      var rtn = response.data;
      console.log(rtn);
      if (rtn.hasOwnProperty("success") && !rtn.success) {
        console.log('1');
        // if(rtn.formModels[0].description == "用品采购") {
        // 	this.errorToastBack("用品采购审批请到cp端审批");
        // }else{
        this.errorToastBack(rtn.message);
        // }
      } else if (rtn.result == "taskEnd") {
        console.log('2');
        this.errorToastBack('任务已经结束');
      } else if (rtn.result == "noForm") {
        console.log('3');
        this.errorToastBack('流程没有配置手机表单，请前往PC端配置');
      } else {
        console.log('4');
        var data = this.$store.state.bpmModule.taskData;
        let from = this.$route.params.from;
        if (data && data.bpmTask && this.taskId == data.bpmTask.id && data.formModels && data.formModels.length > 0 && from != 'tasks') {
          rtn = data;
          console.log('4');
        }
        console.log(rtn);
        // if(rtn.formModels[0].formKey != "ypcg"){
        this.init(rtn);
        // }
      }
    },

    init(data) {
      this.data = data;
      initFormModels(data.formModels);
      this.formModels = data.formModels;
      var bpmTask = data.bpmTask;
      this.bpmTask = bpmTask;

      //简化顶部标题
      this.title = bpmTask.description;
      this.destNodeUserList = data.destNodeUserList;
      this.isReachEndEvent = data.isReachEndEvent;
      this.nodeUsers = data.destNodeUserList;
      console.log(this.nodeUsers);
      var taskConfig = data.taskConfig;
      this.taskConfig = taskConfig;
      this.canReject = data.canReject;
      this.isShowDiscardBtn = data.isShowDiscardBtn;
      this.bpmInst = data.bpmInst;
      if (taskConfig && taskConfig.buttons && taskConfig.buttons.length > 0) {
        this.buttons = taskConfig.buttons;
      }
      this.allowChangePath = taskConfig.allowChangePath == 'false' ? false : true;
      //保留vue实例
      if (this.formModels[0].formKey == "ypcg") {} else if (this.formModels[0].formKey == "QJHCJHSP") {} else {
        console.log('loadFormInst方法....');
        loadFormInst(this, false);
        console.log(this.formVms);
      }
      //保存历史数据
      this.saveData();
    },
    discardInst() {
      this.$dialog.confirm({
        mes: '确定要作废该流程吗?',
        opts: [{
          txt: '取消'
        }, {
          txt: '确定',
          color: '#25c4b5',
          callback: () => {
            var url = _baseUrl + "/bpm/core/bpmInst/discardInst.do";
            var params = "instId=" + this.bpmInst.instId;
            this.$ajax.post(url, params).then(res => {
              // debugger;
              if (res.data.success) {
                this.successToast("作废成功！", 1500, 'index');
              }
            });
          }
        }]
      });
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'rx-search',
  props: {
    value: String,
    senior: true
  },
  data() {
    return {
      currentValue: ""
    };
  },
  methods: {
    search(e) {
      this.$emit("input", this.currentValue);
    },
    showSenior() {
      this.$emit("showsenior", this);
    }
  },
  created() {
    this.currentValue = this.value;
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export const mailModule = {
  //state 数据
  state: {
    folderData: {}
  },
  //同步定义
  mutations: {
    setFolderData(state, data) {
      state.folderData = data;
    },
    cleanFolderData(state) {
      state.folderData = {};
    }
  }
  /*
  getters: {
   getCount(state) {
     return state.count + 5;
   },
  }*/
};
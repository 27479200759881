/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { RxUtil } from "@/assets/util.js";
export default {
  name: "rx-check",
  props: ["value", "val"],
  mounted() {
    this.init();
  },
  methods: {
    change(value) {
      var checked = this.$refs.input.checked;
      var aryChk = !this.value ? [] : this.value.split(",");
      if (checked) {
        aryChk.push(this.val);
      } else {
        RxUtil.removeByVal(aryChk, this.val);
      }
      this.$emit('input', aryChk.join(","));
    },
    init() {
      var checked = false;
      if (this.value) {
        var aryChk = this.value.split(",");
        checked = RxUtil.isInAry(aryChk, this.val);
      }
      this.$refs.input.checked = checked;
    },
    checkedThis() {
      var checked = this.$refs.input.checked;
      var aryChk = !this.value ? [] : this.value.split(",");
      if (checked) {
        this.$refs.input.checked = false;
        RxUtil.removeByVal(aryChk, this.val);
      } else {
        this.$refs.input.checked = true;
        aryChk.push(this.val);
      }
      this.$emit('input', aryChk.join(","));
    },
    handClick() {
      this.$emit('ctlClick');
    }
  },
  //值发生改变的时候重新初始化
  watch: {
    value: function (val, oldVal) {
      this.init();
    }
  }
};
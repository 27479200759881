/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "phone-render",
  props: {
    val: [String, Number],
    format: [String]
  },
  data() {
    return {
      styleObj: {},
      currentValue: this.val
    };
  },
  mounted() {
    if (this.format) {
      this.currentValue = this.$moment(this.currentValue).format(this.format.toUpperCase());
    }
  },
  methods: {
    stop() {
      event.stopPropagation();
    }
  },
  watch: {}
};
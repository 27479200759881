/* this file is transformed by vux-loader */
/* eslint-disable */
export const userModule = {
  //state 数据
  state: {
    user: {},
    //已经登录成功了
    isLogin: ""
  },
  //同步定义
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setIsLogin(state, data) {
      state.isLogin = data;
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import RxInput from './RxInput';
Vue.component(RxInput.name, RxInput);
import RxSpiner from './RxSpiner';
Vue.component(RxSpiner.name, RxSpiner);
import RxTextArea from './RxTextArea';
Vue.component(RxTextArea.name, RxTextArea);
import RxCheckBoxList from './RxCheckBoxList';
Vue.component(RxCheckBoxList.name, RxCheckBoxList);
import RxCombobox from './RxCombobox';
Vue.component(RxCombobox.name, RxCombobox);
import RxRadioButtonList from './RxRadioButtonList';
Vue.component(RxRadioButtonList.name, RxRadioButtonList);
import RxComplex from './RxComplex';
Vue.component(RxComplex.name, RxComplex);
import RxDate from './RxDate';
Vue.component(RxDate.name, RxDate);
import RxAttachment from './RxAttachment';
Vue.component(RxAttachment.name, RxAttachment);
import RxCheckbox from './RxCheckbox';
Vue.component(RxCheckbox.name, RxCheckbox);
import RxUser from './RxUser';
Vue.component(RxUser.name, RxUser);
import RxGroup from './RxGroup';
Vue.component(RxGroup.name, RxGroup);
import RxMonth from './RxMonth';
Vue.component(RxMonth.name, RxMonth);
import RxTime from './RxTime';
Vue.component(RxTime.name, RxTime);
import RxButtonEdit from './RxButtonEdit';
Vue.component(RxButtonEdit.name, RxButtonEdit);
import RxRichEditor from './RxRichEditor';
Vue.component(RxRichEditor.name, RxRichEditor);
import RxSignature from './RxSignature';
Vue.component(RxSignature.name, RxSignature);
import RxImg from './RxImg';
Vue.component(RxImg.name, RxImg);
import RxButton from './RxButton';
Vue.component(RxButton.name, RxButton);
/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "rx-complex",
  props: {
    data: Object,
    name: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      right: ""
    };
  },
  computed: {
    display: function () {
      var tmp = this.name + "_name";
      return this.data[tmp];
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "rx-radio",
  props: ["value", "val", "name"],
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$refs.input.checked = this.isChecked();
    },
    change(value) {
      this.$refs.input.checked = true;
      this.$emit('input', value);
    },
    isChecked() {
      return this.value == this.$refs.input.value;
    },
    checkedThis() {
      this.$refs.input.checked = true;
      this.$emit('input', this.val);
    },
    handClick() {
      this.$emit('ctlClick');
    }
  },
  //值发生改变的时候重新初始化
  watch: {
    value: function (val, oldVal) {
      this.init();
    }
  }
};
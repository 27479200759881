/* this file is transformed by vux-loader */
/* eslint-disable */
import { RxUtil } from "@/assets/util.js";
import Datetime from "vux/src/components/datetime/index.vue";
export default {
  name: "rx-month",
  components: {
    Datetime
  },
  props: {
    value: String,
    conf: String,
    bodefid: String,
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    data: Object
  },
  data() {
    return {
      emptyVal: true,
      currentValue: this.value,
      format: "YYYY-MM"
    };
  },
  watch: {
    currentValue: function (val, oldVal) {
      this.$emit('input', val);
      if (window['dateValHandler_' + this.bodefid]) {
        window['dateValHandler_' + this.bodefid](this.data, this);
      }
    },
    value: function (val, oldVal) {
      if (val == oldVal) return;
      this.currentValue = val;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.currentValue = this.value;
    },
    showPlugin() {
      const self = this;
      var setting = {
        cancelText: '取消',
        clearText: '清空',
        confirmText: '确定',
        format: self.format,
        value: self.currentValue,
        onConfirm(val) {
          self.currentValue = val;
        },
        onClear(val) {
          self.currentValue = "";
        }
      };
      this.$vux.datetime.show(setting);
    }
  }
};
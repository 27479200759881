/* this file is transformed by vux-loader */
/* eslint-disable */
import { RxUtil } from "@/assets/util.js";
export default {
  name: "rx-input",
  props: {
    value: [String, Number],
    conf: [Object, String],
    showClear: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * 验证表达式
     */
    vtype: {
      type: String,
      default: ""
    },
    onblur: Function,
    onfocus: Function
  },
  data() {
    return {
      currentValue: this.value,
      iserror: false,
      isempty: true,
      checkReq: true,
      placeholder: "",
      only_read: false,
      type: "text"
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.conf) return;
      var conf = JSON.parse(this.conf);

      //空文本提示
      if (conf.emptytext) {
        this.placeholder = conf.emptytext;
      }
      if (conf.only_read == 1) {
        this.only_read = true;
      }
      if (conf.datatype == 'number') {
        this.type = 'number';
      }
    },
    blurHandler(e) {
      this.onblur && this.onblur(e);
    },
    focusHandler(e) {
      this.showClear = true;
      this.onfocus && this.onfocus(e);
    },
    clearInput() {
      this.currentValue = '';
    }
  },
  watch: {
    currentValue: function (val, oldVal) {
      this.$emit('input', val + "");
      //是否为空
      this.isempty = RxUtil.isEmpty(val);
    },
    value: function (val, oldVal) {
      if (val != oldVal) {
        this.currentValue = this.value;
      }
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
/**
 * 使用方法：
 * import RxCombo "@/components/common/RxCombo";
 * Vue.component(RxCombo.name, RxCombo);
 * 
 * <rx-combo v-model="" conf="配置json" ></rx-combo>
 * 
 * conf 参数配置:
 * 1.下拉选项
 * {
 * 		form:"self",
 * 		data:"[{'name':'在职','key':'IN_JOB'},{'name':'离职','key':'OUT_JOB'}]"
 * },
 * 2.url 配置
 * {
 * 		from:"url",
 * 		textfield:"",
 * 		valuefield:"",
 * 		url:""
 * }
 * 3.数据字典
 * {
 * 		from:"dic",
 * 		dic:""
 * },
 * 4:SQL 配置
 * {
 * 		from:"sql",
 * 		textfield:"",
 * 		valuefield:"",
 * 		data_options:"{"sql":{"param":"REF_ID_","parent":"url","sql":"AA"}}"
 * }
 * 
 */
import Checklist from "vux/src/components/checklist/index.vue";
import { componentAjaxJson } from "@/assets/app.js";
export default {
  components: {
    Checklist
  },
  name: "rx-combo",
  props: {
    conf: String,
    value: String
  },
  data() {
    return {
      selectJson: [],
      showPop: false,
      checkValue: [],
      showVal: ""
    };
  },
  mounted() {
    var conf = eval("(" + this.conf + ")");
    var from = conf.from;
    var params = {};
    if (from == 'dic') {
      params = {
        dic: conf.dic
      };
      componentAjaxJson(conf, params, this, data => {
        this.selectJson = data;
      }); //字典是key value形式  不需要转换
    } else if (from == 'self') {
      var data = conf.data;
      this.selectJson = this.changeAttr("key", "name", "key", "value", data);
    } else if (from == 'sql' || from == 'url') {
      var sqlKey = conf.sql;
      var options = conf.data_options;
      var param = {};
      if (options && options.sql && options.sql.parent) {
        //下拉框联动
        //sql设定
        var sqlSetting = options.sql;
        var parentField = sqlSetting.parent;
        var parentKey = sqlSetting.param;
        param[parentKey] = !this.data[parentField] ? "-1" : this.data[parentField];
      }
      params = {
        params: param
      };
      componentAjaxJson(conf, params, this, data => {
        var textfield = conf.textfield;
        var valuefield = conf.valuefield;
        this.selectJson = this.changeAttr(valuefield, textfield, "key", "value", data);
      });
    }
  },
  methods: {
    //属性转换
    changeAttr(valueField, textField, key, value, data) {
      var ary = [];
      for (var i = 0; i < data.length; i++) {
        var ob = {};
        ob[key] = data[i][valueField]; //key是实际值 value是本文值
        ob[value] = data[i][textField];
        ary.push(ob);
      }
      return ary;
    },
    onChange() {
      this.showVal = this.checkValue.toString();
      this.showPop = false;
    },
    onCancel() {
      this.showPop = false;
    },
    onClear() {
      this.showVal = "";
      this.checkValue = [];
      this.showPop = false;
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
export default {
  name: 'rx-dialog',
  props: {
    title: String
  },
  data() {
    return {
      style: "transform:translate(-100%)",
      screenHeight: document.body.clientHeight,
      beforeHeight: document.body.clientHeight,
      resize: false,
      entName: ""
    };
  },
  methods: {
    handOK() {
      if (this.$parent) {
        var rtn = this.$parent.validSubRow(this.entName);
        if (!rtn.valid) {
          this.errorToast(rtn.msg);
          return;
        }
      }
      this.hideDialog();
      var self_ = this;
      setTimeout(function () {
        self_.$emit("handok", self_);
      }, 200);
    },
    hideDialog() {
      this.style = "transform:translate(-100%)";
    },
    show() {
      this.style = "transform:translate(0)";
    }
  },
  mounted() {
    const that = this;
    window.onresize = function () {
      that.screenHeight = document.body.clientHeight;
    };
  },
  watch: {
    screenHeight(val) {
      this.resize = this.beforeHeight > val;
    }
  }
};
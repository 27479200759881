/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import RxDate from '@/components/list/query/RxDate';
import RxInput from '@/components/list/query/RxInput';
import RxCombo from '@/components/list/query/RxCombo';
import RxBtnEdit from '@/components/list/query/RxBtnEdit';
Vue.component(RxDate.name, RxDate);
Vue.component(RxInput.name, RxInput);
Vue.component(RxCombo.name, RxCombo);
Vue.component(RxBtnEdit.name, RxBtnEdit);
export default {
  name: 'rx-searchpanel',
  props: {
    name: String
  },
  methods: {
    reset() {},
    handSearch() {
      this.hiddenSearch();
      var self_ = this;
      setTimeout(function () {
        self_.$emit("search", self_);
      }, 200);
    },
    resetSearch() {
      this.hiddenSearch();
      var self_ = this;
      setTimeout(function () {
        self_.$emit("reset", self_);
      }, 200);
    },
    hiddenSearch() {
      var panel = document.getElementById("searchPanel_" + this.name);
      panel.style.display = "none";
    },
    show() {
      var panel = document.getElementById("searchPanel_" + this.name);
      panel.style.display = "inline-block";
      //panel.style.transform="translate(0)";
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "range-render",
    style: _vm.styleObj
  }, [_vm._v(" " + _vm._s(_vm.currentValue) + " ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
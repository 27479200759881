/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { loadScrollList } from '@/assets/app.js';
import radio from '@/components/common/rxRadio';
import checkbox from '@/components/common/rxCheckbox';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
Vue.component(radio.name, radio);
Vue.component(checkbox.name, checkbox);
export default {
  name: "rx-sysgroup",
  data() {
    return {
      orgList: [],
      list: [],
      pageIndex: 0,
      pageSize: 20,
      total: 0,
      searchName: "",
      selectGroupId: "",
      selectedGroup: [],
      orgUrls: [],
      showList: false,
      showCancelBtn: false,
      showHisMenu: true,
      showGroupsMenu: false,
      groupsHis: [],
      config: "",
      groups: [],
      type: ""
    };
  },
  props: {
    single: {
      type: Boolean,
      default: true
    }
  },
  created() {},
  computed: {},
  methods: {
    initConfig(conf) {
      try {
        this.config = JSON.stringify(JSON.parse(conf).config);
      } catch (e) {}
    },
    search() {
      this.showHisMenu = false;
      this.showGroupsMenu = true;
      if (this.type == 'dep') {
        this.listDeps();
      } else if (this.type == 'group') {
        this.listGroups();
      }
    },
    listGroups() {
      var url = _baseUrl + "/mobile/oa/jsonAll.do";
      var params = "";
      this.$ajax.post(url, params).then(response => {
        this.orgList = response.data;
        this.orgUrls = [];
      }).catch(error => {
        console.log(error);
      });
    },
    listDeps() {
      var url = _baseUrl + "/sys/org/sysOrg/getInitData.do";
      var params = "config=" + this.config;
      this.$ajax.post(url, params).then(response => {
        this.groups = response.data;
        this.listTopGroups();
      }).catch(error => {
        console.log(error);
      });
    },
    listTopGroups() {
      var groupIds = [];
      for (let i = 0, j = this.groups.length; i < j; i++) {
        let group = this.groups[i];
        if (!groupIds.contains(group.pkId)) {
          groupIds.push(group.pkId);
        }
      }
      var topGroups = [];
      for (let m = 0, n = this.groups.length; m < n; m++) {
        let group1 = this.groups[m];
        if (!groupIds.contains(group1.parentId)) {
          topGroups.push(group1);
        }
      }
      this.orgList = topGroups;
      this.orgUrls = [];
    },
    listChilds(item) {
      var childGroups = [];
      for (let m = 0, n = this.groups.length; m < n; m++) {
        let group = this.groups[m];
        if (item.pkId == group.parentId) {
          childGroups.push(group);
        }
      }
      this.orgList = childGroups;
    },
    ajaxGroups(item) {
      var url = _baseUrl + "/mobile/oa/listGroupsAndUsers.do";
      var params = {};
      if (item.dimId && !item.groupId) {
        params = {
          dimId: item.dimId
        };
      } else if (item.groupId) {
        params = {
          groupId: item.groupId
        };
      }
      this.loadLevel(params, url);
    },
    loadLevel(params, url) {
      this.$ajax({
        url: url,
        method: 'POST',
        params: params
      }).then(response => {
        this.orgList = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    ok() {
      this.$emit('ok', this.selectedGroup);
    },
    cancel() {
      this.$emit('cancel');
    },
    //设置选中的帐号。
    setValue(val) {
      this.selectGroupId = val;
    },
    handleClick(item, index) {
      if (this.type == 'dep') {
        this.listChilds(item);
      } else if (this.type == 'group') {
        this.ajaxGroups(item);
      }
      if (!isNaN(index)) {
        this.orgUrls = this.orgUrls.slice(0, index + 1);
      } else {
        this.orgUrls.push(item);
      }
    },
    groupList() {
      if (this.searchName) {
        var url = _baseUrl + "/mobile/oa/getGroupsSearch.do";
        var params = "";
        if (this.searchName) {
          params = "&Q_NAME__S_LK=" + this.searchName;
        }
        this.$ajax.post(url, params).then(response => {
          this.list = response.data.rowList;
        }).catch(function (error) {});
      }
    },
    del(index) {
      this.selectedGroup.splice(index, 1);
    },
    onFocus() {
      this.showCancelBtn = true;
      this.showList = true;
      this.showHisMenu = true;
      this.showGroupsMenu = false;
    },
    closeSearch() {
      this.searchName = "";
      this.showList = false;
      this.showCancelBtn = false;
    },
    searchGroups() {
      if (this.searchName && this.searchName.trim()) {
        this.groupsHis = RxUtil.addSearchHis(this.searchName, "group");
        this.groupList(true, false);
        this.showHisMenu = false;
        this.showGroupsMenu = true;
      }
    },
    clearSearchVal() {
      this.searchName = "";
      this.showHisMenu = true;
      this.showGroupsMenu = false;
    },
    clearHis() {
      RxUtil.clearSearchHis("group");
      this.groupsHis = [];
    },
    hisClick(val) {
      this.searchName = val;
      this.showHisMenu = false;
      this.showGroupsMenu = true;
      this.groupList(true, false);
    }
  },
  watch: {
    selectGroupId: function (val, oldVal) {
      if (!val) {
        this.selectedGroup = [];
        return;
      }
      //处理增加的用户，意思是当前选择的用户不在选择的列表中。
      var aryVal = this.selectGroupId.split(",");
      var selectMap = RxUtil.covertList2Map("groupId", this.selectedGroup);
      //合并左右两边的
      var list = this.list.concat(this.orgList);
      var allMap = RxUtil.covertList2Map("groupId", list);
      //当前用户不在选中的用户中，添加
      for (var i = 0; i < aryVal.length; i++) {
        var tmp = aryVal[i];
        if (!selectMap[tmp]) {
          this.selectedGroup.push(allMap[tmp]);
        }
      }
      //删除已选
      for (var i = this.selectedGroup.length - 1; i >= 0; i--) {
        var o = this.selectedGroup[i];
        var id = o["groupId"];
        var isIn = RxUtil.isInAry(aryVal, id);
        if (!isIn) {
          this.selectedGroup.splice(i, 1);
        }
      }
    }
  }
};
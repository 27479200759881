/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'rx-popup',
  data() {
    return {
      show: this.value
    };
  },
  props: {
    position: {
      validator(value) {
        return ['bottom', 'center', 'left', 'right'].indexOf(value) > -1;
      },
      default: 'bottom'
    },
    height: {
      type: String,
      default: 'auto'
    },
    width: {
      type: String,
      default: 'auto'
    },
    value: {
      type: Boolean
    },
    closeOnMasker: {
      type: Boolean,
      default: true
    },
    maskerOpacity: {
      validator(val) {
        return /^([0]|[1-9]\d*)?(\.\d*)?$/.test(val);
      },
      default: .5
    }
  },
  watch: {
    value(val) {
      this.show = val;
    }
  },
  computed: {
    classes() {
      return (this.position === 'center' ? 'yd-popup-center ' : 'yd-popup yd-popup-' + this.position) + (this.show ? ' yd-popup-show ' : '');
    },
    styles() {
      if (this.position === 'left' || this.position === 'right') {
        return {
          width: this.width
        };
      } else if (this.position === 'bottom') {
        return {
          width: '100%',
          height: this.height
        };
      } else {
        return {
          width: this.width,
          height: this.height,
          zIndex: 10000
        };
      }
    }
  },
  methods: {
    close() {
      if (this.closeOnMasker) {
        this.show = false;
        this.$emit('input', false);
      }
    }
  }
};
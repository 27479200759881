/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { loadCustomList, Dialog } from '@/assets/app.js';
import { RxUtil } from '@/assets/util.js';

/**
 * 打开自定义对话框。 
 * @param {Object} alias		对话框别名
 * @param {Object} inParams		输入参数 {FULLNAME_:"郭靖"}
 * @param {Object} callBack		回调函数
 */
export function openDialogByAlias(alias, inParams, callBack) {
  openCustomDialog("wrapper", alias, inParams, callBack);
}

/**
 * 打开自定义对话框。
 * @param {Object} wrapperId	对话框容器。
 * @param {Object} alias		对话框别名
 * @param {Object} inParams		输入参数 {FULLNAME_:"郭靖"}
 * @param {Object} callBack		回调函数
 * openCustomDialog("wrapper","user",inParams,function(data){
 * 	
 * })
 */
export function openCustomDialog(wrapperId, alias, inParams, callBack) {
  var url = _baseUrl + "/sys/core/sysBoList/" + alias + "/mobile.do";
  globalVm.$dialog.loading.open('正在加载对话框...');
  globalVm.$ajax.get(url).then(response => {
    globalVm.$dialog.loading.close();
    Dialog.open(wrapperId, alias, function (alias) {
      var data = response.data;
      var isShare = data.isShare;
      var idField = data.idField;
      var html = response.data.mobileHtml;
      RxUtil.setHtml("dialog_" + alias, html);
      var listUrl = isShare == "YES" ? "/dev/cus/customData/share/" + alias + "/getData.do" : "/dev/cus/customData/" + alias + "/getData.do";
      if (inParams) {
        var urlParam = RxUtil.serialJsonToForm(inParams);
        if (urlParam) {
          listUrl += "?" + RxUtil.serialJsonToForm(inParams);
        }
      }
      listUrl = _baseUrl + listUrl;
      var params = eval("params_json");
      var vue = new Vue({
        el: '#dialog_' + alias,
        data: {
          list: [],
          page: 0,
          pageSize: 20,
          total: 0,
          search: params.search,
          selectIds: "",
          selectData: [],
          topBtnsJson: {},
          showNav: true,
          isSearch: false,
          //判断是否搜索
          scriptBtnPop: false,
          scriptBtns: [],
          checkMode: false,
          checkIds: [],
          isAll: false
        },
        created() {
          this.loadList(true, false);
        },
        watch: {
          selectIds: function (val, oldVal) {
            if (val == oldVal) return;
            if (!this.selectIds) {
              this.selectData = [];
              return;
            }
            //添加到selectData
            var aryIds = this.selectIds.split(",");
            for (var i = 0; i < aryIds.length; i++) {
              var id = aryIds[i];
              //判断是否已在选择范围中。
              var isExist = RxUtil.isInList(this.selectData, idField, id);
              if (isExist) continue;
              for (var j = 0; j < this.list.length; j++) {
                var o = this.list[j];
                if (o[idField] == id) {
                  this.selectData.push(o);
                }
              }
            }
            //删除不在选中列表中的项
            for (var i = this.selectData.length - 1; i >= 0; i--) {
              var o = this.selectData[i];
              var tmpId = o[idField];
              var isExist = RxUtil.isInAry(aryIds, tmpId);
              if (!isExist) {
                this.selectData.splice(i, 1);
              }
            }
          },
          search: {
            handler(val, oldValue) {
              var isSearch = false;
              for (let key in val) {
                if (val[key]) {
                  isSearch = true;
                }
              }
              this.isSearch = isSearch;
            },
            deep: true
          }
        },
        methods: {
          checkAll() {
            let customdatas = this.$refs.customdatas; //[0].children[0].children[1]
            let checkIds = [];
            this.isAll = this.$refs.check.$refs.input.checked;
            let isAll = this.isAll;
            customdatas.forEach(function (obj, index) {
              let inputObj = obj.children[0].children[1];
              inputObj.checked = isAll;
            });
          },
          resetSearch() {
            for (let key in this.search) {
              this.search[key] = "";
            }
            this.loadList(true, false);
          },
          hasClass(elem, cls) {
            cls = cls || '';
            if (cls.replace(/\s/g, '').length == 0) return false;
            return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ');
          },
          addClass(ele, cls) {
            if (!this.hasClass(ele, cls)) {
              ele.className = ele.className == '' ? cls : ele.className + ' ' + cls;
            }
          },
          removeClass(ele, cls) {
            if (this.hasClass(ele, cls)) {
              let newClass = ' ' + ele.className.replace(/[\t\r\n]/g, '') + ' ';
              while (newClass.indexOf(' ' + cls + ' ') >= 0) {
                newClass = newClass.replace(' ' + cls + ' ', ' ');
              }
              ele.className = newClass.replace(/^\s+|\s+$/g, '');
            }
          },
          loadList(initPage, merge) {
            if (initPage) {
              this.page = 0;
            } else {
              this.page++;
            }
            this.removeClass(globalVm.$el.children[0].children[2], "scroll");
            var searchParams = "pageIndex=" + this.page + "&pageSize=" + this.pageSize;
            var tmp = RxUtil.serialJsonToForm(this.search);
            if (tmp) {
              searchParams += "&" + tmp;
            }
            loadCustomList(listUrl, searchParams, this, data => {
              this.total = data instanceof Array ? data.length : data.total;
            }, merge);
          },
          showSenior() {
            this.$refs.searchPanel.show();
          },
          handSearch() {
            this.loadList(true, false);
          },
          toggle(item) {
            item.expand = !item.expand;
          },
          clickOk() {
            this.addClass(globalVm.$el.children[0].children[2], "scroll");
            if (callBack) {
              var aryMetadata = params.result;
              var rtn = [];
              for (var i = 0; i < this.selectData.length; i++) {
                var obj = this.selectData[i];
                var item = {};
                for (var j = 0; j < aryMetadata.length; j++) {
                  var key = aryMetadata[j];
                  item[key] = obj[key];
                }
                rtn.push(item);
              }
              callBack(rtn);
            }
            Dialog.close(alias);
          },
          clickClose() {
            this.addClass(globalVm.$el.children[0].children[2], "scroll");
            Dialog.close(alias);
          }
        }
      });
    });
  });
}
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.string.replace-all.js";
import { loadingComponent, createVueInstance } from '@/assets/app.js';
import { RxUtil } from "@/assets/util.js";
export default {
  name: "rx-form",
  data() {
    return {
      currentView: loadingComponent,
      formVm: {}
    };
  },
  props: {
    formContainerId: String,
    formModel: Object,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.loadForm(this.readonly);
  },
  methods: {
    /**
     * {template:"",data:{},permission:{},script:""}
     * 加载表单数据。
     */
    loadForm(readonly) {
      var obj = this.formModel;
      var htmlObj = RxUtil.parseHtml(obj.content);
      var html = htmlObj.html;
      html = html.replaceAll("&#39;", "'").replaceAll("&amp;", "&");
      createVueInstance.options.template = `<div >${html}</div> `;
      this.currentView = createVueInstance;
      this.formVm = this.$refs.component;
      this.$nextTick(function () {
        if (obj.script) {
          //插入脚本
          //Util.insertScript(this.formContainerId, obj.script);
        }
        this.formVm = this.$refs.component;
        this.formVm.data = obj.jsonData;
        this.formVm.permission = obj.permission;

        //					debugger;
        this.formVm.formKey = obj.formKey;
        this.formVm.readonly = readonly;
      });
    },
    getData() {
      return this.formVm.data;
    },
    getOpinionData() {
      return this.formVm.opinionData;
    },
    beforeSubmit() {
      if (this.formVm._beforeSubmit) {
        return this.formVm._beforeSubmit(this.formVm.data);
      }
      return true;
    },
    initData(data) {
      if (!data.initData) {
        return;
      }
      for (var key in data.initData) {
        var ary = data[FormConstant.SubPrefix + key];
        for (var i = 0; i < ary.length; i++) {
          var o = ary[i];
          o.index_ = i;
        }
      }
    },
    setReadonly(val) {
      this.formVm.setReadonly(val);
    },
    showSubHeader() {
      //如果是把子表的表格显示出来
      this.$nextTick(function () {
        var _html = this.$refs.component.$el;
        var s = _html.getElementsByClassName('rx-table-box');
        for (let i = 0; i < s.length; i++) {
          s[i].style = "";
        }
      });
    }
  }
};
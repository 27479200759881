/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "currency-render",
  props: {
    val: [String, Number],
    format: [String]
  },
  data() {
    return {
      styleObj: {},
      currentValue: this.val
    };
  },
  mounted() {},
  methods: {},
  watch: {}
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { RxUtil } from "@/assets/util.js";
import { calcRight, componentAjaxJson } from "@/assets/app.js";
import Popup from "vux/src/components/popup/index.vue";
import Checklist from "vux/src/components/checklist/index.vue";
export default {
  name: "rx-checkboxlist",
  components: {
    Popup,
    Checklist
  },
  props: {
    value: String,
    conf: String,
    name: String,
    data: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectJson: [],
      checkKey: [],
      showPop: false,
      checkValue: []
    };
  },
  mounted() {
    if (this.data[this.name]) {
      this.checkValue = this.data[this.name].split(",");
    }
    if (!this.conf) return;
    var conf = JSON.parse(this.conf);
    var from = conf.from;
    var params = {};
    if (from == 'dic') {
      var dickey = conf.dickey;
      params = {
        dic: dickey
      };
      componentAjaxJson(conf, params, this, data => {
        this.selectJson = this.changeAttr("key", "value", "key", "value", data);
      });
    } else if (from == 'self') {
      //自定义是key  name形式
      var data = conf.data;
      this.selectJson = this.changeAttr("key", "name", "key", "value", data);
    } else if (from == 'sql' || from == 'url') {
      componentAjaxJson(conf, params, this, data => {
        var textfield = conf.textfield;
        var valuefield = conf.valuefield;
        this.selectJson = this.changeAttr(valuefield, textfield, "key", "value", data);
      });
    }
  },
  methods: {
    //属性转换
    changeAttr(valueField, textField, key, value, data) {
      var ary = [];
      this.checkVal = [];
      for (var i = 0; i < data.length; i++) {
        var ob = {};
        ob[key] = data[i][valueField]; //key是实际值 value是本文值
        ob[value] = data[i][textField];
        ary.push(ob);
      }
      return ary;
    },
    onChange() {
      var checkValue = this.checkValue;
      var selectJson = this.selectJson;
      var names = [];
      for (let i = 0, j = selectJson.length; i < j; i++) {
        for (let m = 0, n = checkValue.length; m < n; m++) {
          if (checkValue[m] == selectJson[i].key) {
            names.push(selectJson[i].value); //显示值
            break;
          }
        }
      }
      this.data[this.name] = checkValue.toString();
      this.data[this.name + '_name'] = names.toString();
      this.showPop = false;
    },
    onCancel() {
      this.showPop = false;
    },
    onClear() {
      this.checkValue = [];
      this.data[this.name] = "";
      this.data[this.name + '_name'] = "";
      this.showPop = false;
    },
    valid(chkReq_) {
      if (chkReq_ && this.required) {
        if (!this.checkKey || this.checkKey.length == 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }
};
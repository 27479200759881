/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import { RxUtil } from '@/assets/util.js';
import { loadCustomList } from '@/assets/app.js';
import router from '@/router/index';
import '@/components/common';
import FieldRender from '@/components/list/render/FieldRender';
Vue.component(FieldRender.name, FieldRender);
import listMore from '@/components/common/listMore';
Vue.component(listMore.name, listMore);

/**
 * 加载表单
 */
var loadingComponent = {
  template: "<div></div>"
};
var timeOutEvent = 0; //定时器  

var listComponet = Vue.component("list-container", {
  template: '',
  data() {
    return {
      page: 0,
      total: 0,
      pageSize: 20,
      list: [],
      selectIds: "",
      search: [],
      boData: {},
      topBtnsJson: {},
      mapping: [],
      showNav: false,
      isSearch: false,
      //判断是否搜索
      scriptBtnPop: false,
      scriptBtns: [],
      checkMode: false,
      checkIds: [],
      isAll: false,
      mainData: {}
    };
  },
  methods: {
    fixedBoxBtn(event) {
      event.stopPropagation();
    },
    checkAll() {
      let customdatas = this.$refs.customdatas; //[0].children[0].children[1]
      let checkIds = [];
      this.isAll = this.$refs.check.$refs.input.checked;
      let isAll = this.isAll;
      customdatas.forEach(function (obj, index) {
        let inputObj = obj.children[0].children[1];
        inputObj.checked = isAll;
      });
    },
    unchooseAll() {
      let customdatas = this.$refs.customdatas; //[0].children[0].children[1]
      customdatas.forEach(function (obj, index) {
        let inputObj = obj.children[0].children[1];
        inputObj.checked = false;
      });
    },
    moreBtn(flag) {
      this.scriptBtnPop = !this.scriptBtnPop;
    },
    chooseData() {
      let customdatas = this.$refs.customdatas; //[0].children[0].children[1]
      let checkIds = [];
      customdatas.forEach(function (obj, index) {
        let inputObj = obj.children[0].children[1];
        let checked = inputObj.checked;
        if (checked) {
          checkIds.push(inputObj.value);
        }
      });
      this.checkIds = checkIds;
    },
    scirptBtnHandler(obj) {
      this.chooseData();
      var checkIds = this.checkIds;
      let len = this.checkIds.length;
      if (!len) {
        this.errorToast("请选择操作记录!");
        return;
      }
      this.$dialog.confirm({
        mes: '选择了' + len + '条记录,是否进行【' + obj.btnLabel + '】操作？',
        opts: [{
          txt: '取消',
          color: false
        }, {
          txt: '确定',
          color: '#25c4b5',
          callback: () => {
            var url = _baseUrl + obj.url;
            var chooseData = this.list.filter(function (item) {
              return checkIds.contains(item.ID_);
            });
            var params = "data=" + encodeURIComponent(JSON.stringify(chooseData));
            this.$ajax.post(url, params).then(res => {
              let data = res.data;
              if (data.success) {
                this.successToast(data.message, 1500);
                this.loadList(true, false);
                this.checkMode = false;
                this.unchooseAll();
                this.scriptBtnPop = false;
              }
            });
          }
        }]
      });
    },
    showSenior() {
      this.$refs.searchPanel.show();
    },
    resetSearch() {
      for (let key in this.search) {
        this.search[key] = "";
      }
      this.loadList(true, false);
    },
    handSearch() {
      this.loadList(true, false);
    },
    toggle(item) {
      item.expand = !item.expand;
    },
    refreshList() {
      this.loadList(true, false);
    },
    loadList(initPage, merge) {
      var listUrl = +this.boData.isShare == "YES" ? "/dev/cus/customData/share/" + this.boData.key + "/getData.do" : "/dev/cus/customData/" + this.boData.key + "/getData.do";
      listUrl = _baseUrl + listUrl;
      if (initPage) {
        this.page = 0;
      } else {
        this.page++;
      }
      //设置关联查询条件
      var searchParams = "pageIndex=" + this.page + "&pageSize=" + this.pageSize;
      var mapping = this.mapping;
      var aryParam = [];
      for (var i = 0; i < mapping.length; i++) {
        var tmp = mapping[i];
        //{"mField":"name","qField":"F_CUSTOM"}
        var val = !this.mainData ? '' : this.mainData[tmp.mField];
        aryParam.push(tmp.qField + "=" + val);
      }
      if (aryParam.length > 0) {
        searchParams += "&" + aryParam.join("&");
      }
      var tmp = RxUtil.serialJsonToForm(this.search);
      if (tmp) {
        searchParams += "&" + tmp;
      }
      loadCustomList(listUrl, searchParams, this, data => {
        this.total = data.total;
      }, merge);
    },
    getStatusDisplay(status) {
      if (!status) return "";
      var json = {
        RUNNING: "运行中",
        DRAFTED: "草稿",
        SUCESS_END: "成功结束",
        DISCARD_END: "作废",
        ABORT_END: "异常中止结束",
        PENDING: "挂起"
      };
      return json[status];
    },
    formAdd() {
      var relField = {};
      for (var i = 0; i < this.mapping.length; i++) {
        var tmp = this.mapping[i];
        //{"mField":"name","qField":"F_CUSTOM"}
        var val = !this.mainData ? '' : this.mainData[tmp.mField];
        relField[tmp.field] = val;
      }
      router.push({
        name: "customForm",
        params: {
          boKey: this.boData.key,
          alias: this.boData.formAlias,
          addAlias: this.boData.formAddAlias,
          detailAlias: this.boData.formDetailAlias,
          //新增表单
          id: "-1",
          readonly: false,
          type: 'add',
          relField: JSON.stringify(relField)
        }
      });
      //				this.$store.commit('setInstData', 1);
    },

    formEdit(selectIds) {
      if (this.checkMode) return;
      router.push({
        name: "customForm",
        params: {
          boKey: this.boData.key,
          alias: this.boData.formAlias,
          addAlias: this.boData.formAddAlias,
          detailAlias: this.boData.formDetailAlias,
          //新增表单
          id: selectIds,
          readonly: true,
          type: 'detail',
          relField: 0
        }
      });
    },
    formDel() {
      var ids = this.selectIds;
      if (!ids) {
        this.errorToast("请选择要删除的记录!");
        return;
      }
      this.$dialog.confirm({
        mes: '确定要删除选中的记录吗！',
        opts: [{
          txt: '取消',
          color: false,
          callback: () => {}
        }, {
          txt: '确定',
          color: '#39f',
          callback: () => {
            this.delById();
          }
        }]
      });
    },
    formDetail() {
      var ids = this.selectIds;
      if (!ids) {
        this.errorToast("请选择一条记录!");
        return;
      }
      router.push({
        name: "customForm",
        params: {
          boKey: this.boData.key,
          alias: this.boData.formAlias,
          id: this.selectIds,
          readonly: true
        }
      });
    },
    delById() {
      var aryId = this.selectIds.split(",");
      var url = _baseUrl + "/sys/customform/sysCustomFormSetting/" + this.boData.formAlias + "/removeById.do";
      var params = "id=" + this.selectIds;
      this.ajaxPost(url, params).then(res => {
        //删除 选择的记录显示。
        for (var i = 0; i < aryId.length; i++) {
          var id = aryId[i];
          for (var j = 0; j < this.list.length; j++) {
            var o = this.list[j];
            if (o[this.boData.idField] == id) {
              this.list.splice(j, 1);
            }
          }
        }
      });
    },
    delById() {
      var aryId = this.selectIds.split(",");
      var url = _baseUrl + "/sys/customform/sysCustomFormSetting/" + this.boData.formAlias + "/removeById.do";
      var params = "id=" + this.selectIds;
      this.$ajax.post(url, params).then(res => {
        //删除 选择的记录显示。
        for (var i = 0; i < aryId.length; i++) {
          var id = aryId[i];
          for (var j = 0; j < this.list.length; j++) {
            var o = this.list[j];
            if (o[this.boData.idField] == id) {
              this.list.splice(j, 1);
            }
          }
        }
      });
    },
    gotouchstart() {
      let that = this;
      clearTimeout(timeOutEvent); //清除定时器
      timeOutEvent = 0;
      let self = this;
      timeOutEvent = setTimeout(function () {
        self.checkMode = true;
      }, 1000); //这里设置定时
    },

    //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
    gotouchend() {
      clearTimeout(timeOutEvent);
      if (timeOutEvent != 0) {
        //这里写要执行的内容（尤如onclick事件）
      }
    },
    //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按 
    gotouchmove() {
      clearTimeout(timeOutEvent); //清除定时器
      timeOutEvent = 0;
    }
  },
  watch: {
    search: {
      handler(val, oldValue) {
        var isSearch = false;
        for (let key in val) {
          if (val[key]) {
            isSearch = true;
          }
        }
        this.isSearch = isSearch;
      },
      deep: true
    }
  }
});
export default {
  name: "tab-list",
  props: {
    tablistid: String,
    tablistkey: String,
    mapping: String,
    maindata: Object
  },
  data() {
    return {
      currentView: loadingComponent,
      alias: "",
      listInst: {},
      topBtnsJson: {},
      showNav: true
    };
  },
  created() {
    var url = _baseUrl + "/sys/core/sysBoList/" + this.tablistkey + "/mobile.do";
    this.$ajax.get(url).then(res => {
      this.$dialog.loading.close();
      var rtnData = res.data;
      var html = rtnData.mobileHtml;
      var htmlObj = RxUtil.parseHtml(html);
      listComponet.options.template = htmlObj.html;
      //插入脚本
      RxUtil.insertScript(this.tablistid, htmlObj.script);
      //设置组件
      this.currentView = listComponet;
      this.$nextTick(function () {
        this.listInst = this.$refs.component;
        if ("list-container" == this.listInst.constructor.extendOptions.name) {
          this.listInst.search = params_json.search;
          this.listInst.boData = rtnData;
          this.listInst.mainData = this.maindata;
          var topBtnsJson = rtnData.topBtnsJson;
          if (this.mapping) {
            this.listInst.mapping = JSON.parse(this.mapping);
          }
          if (topBtnsJson) {
            var topBtns = JSON.parse(topBtnsJson);
            this.listInst.topBtnsJson = topBtns;
            this.listInst.scriptBtns = topBtns.scriptBtns;
            console.info(this.listInst.topBtnsJson);
          }
          this.listInst.loadList(true, false);
        }
      });
    });
  }
};
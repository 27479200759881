/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.readonly ? _c('div', {
    staticClass: "readOnlyBgColor"
  }, [_vm._v(_vm._s(_vm.display))]) : _c('div', [_c('rx-boxs', {
    attrs: {
      "containstyle": "width:2.2rem;",
      "textField": "text",
      "valField": "id",
      "allowSelect": true,
      "single": _vm.single,
      "required": _vm.required,
      "type": 'btnEdit'
    },
    on: {
      "select": _vm.showDialog
    },
    model: {
      value: _vm.jsonData,
      callback: function ($$v) {
        _vm.jsonData = $$v;
      },
      expression: "jsonData"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
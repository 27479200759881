/* this file is transformed by vux-loader */
/* eslint-disable */
import { RxUtil } from "@/assets/util.js";
export default {
  name: "rx-spiner",
  props: {
    value: [Number, String],
    readonly: {
      type: Boolean,
      default: false
    },
    unit: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 100000000
    },
    min: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: "请输入数值"
    },
    onblur: Function,
    onfocus: Function
  },
  data() {
    return {
      iserror: false,
      currentValue: this.value
    };
  },
  mounted() {},
  method: {
    blurHandler(e) {
      this.valid();
      this.onblur && this.onblur(e);
    },
    focusHandler(e) {
      this.showClear = true;
      this.onfocus && this.onfocus(e);
    }
  },
  watch: {
    currentValue: function (val, oldVal) {
      this.$emit('input', val);
    },
    value: function (val, oldVal) {
      if (val != oldVal) {
        this.currentValue = this.value;
      }
    }
  }
};
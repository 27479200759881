/* this file is transformed by vux-loader */
/* eslint-disable */
import { calcRight } from "@/assets/app.js";
import { RxUtil } from "@/assets/util.js";
export default {
  name: "rx-date",
  props: {
    value: String
  },
  data() {
    return {
      format: "",
      currentValue: "",
      right: ""
    };
  },
  watch: {
    value: function (val, oldVal) {
      if (val && val.length == 19) {
        this.currentValue = val.substr(0, 16);
      } else {
        this.currentValue = val;
      }
    },
    currentValue: function (val, oldVal) {
      this.$emit('input', val);
    }
  },
  created() {
    this.init();
  },
  methods: {
    clearValue() {
      this.currentValue = "";
    },
    init() {
      var dateType = "";
      if (this.conf) {
        var format = JSON.parse(this.conf).format;
        if (format == 'yyyy-MM-dd HH:mm' || format == 'yyyy-MM-dd HH:mm:ss') {
          dateType = "YYYY-MM-DD HH:mm";
        } else if (format == 'yyyy-MM-dd') {
          dateType = "YYYY-MM-DD";
        } else if (format == 'HH:mm') {
          dateType = "HH:mm";
        } else {
          dateType = "YYYY-MM-DD";
        }
      } else {
        dateType = "YYYY-MM-DD";
      }
      this.format = dateType;
      var val = this.value;
      if (val && val.length == 19) {
        this.currentValue = val.substr(0, 16);
      } else {
        this.currentValue = val;
      }
    },
    parseStartDate(json, time, compare, format) {
      if (compare == "GTE") {
        json["startDate"] = time;
      } else if (compare == "GT") {
        var date = new Date(time);
        date.setDate(date.getDate() + 1);
        json["startDate"] = date;
      } else if (compare == "LTE") {
        json["endDate"] = time;
      } else if (compare == "LT") {
        var date = new Date(time);
        date.setDate(date.getDate() - 1);
        json["endDate"] = date;
      }
    },
    showPlugin() {
      const self = this;
      var json = {
        cancelText: '取消',
        clearText: '清空',
        confirmText: '确定',
        format: self.format,
        value: self.currentValue,
        onConfirm(val) {
          self.currentValue = val;
        },
        onClear(val) {
          self.currentValue = "";
        }
      };
      if (self.conf) {
        var jsonConf = JSON.parse(this.conf);
        var startDate = jsonConf.startDate;
        if (startDate && startDate.type == 'today') {
          self.parseStartDate(json, new Date(), startDate.compare, self.format);
        } else if (startDate && startDate.type == 'control') {
          var mainData = self.$parent.data;
          var time = mainData[startDate.control];
          //子表
          if (!time) {
            for (var key in mainData) {
              if (key.indexOf("SUB_") != -1) {
                var doc = self.$el.parentElement.parentElement.parentElement.parentElement;
                var subTables = doc.getElementsByClassName('subTable');
                for (var i = 0; i < subTables.length; i++) {
                  var dateBoxs = subTables[i].getElementsByClassName("dateBox");
                  for (var j = 0; j < dateBoxs.length; j++) {
                    if (dateBoxs[j] == self.$el) {
                      var ary = mainData[key];
                      time = ary[i][startDate.control];
                    }
                  }
                }
              }
            }
          }
          self.parseStartDate(json, time, startDate.compare, self.format);
        }
      }
      this.$vux.datetime.show(json);
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { RxUtil } from "@/assets/util.js";
import { LightBox, LightBoxImg, LightBoxTxt } from 'vue-ydui/dist/lib.rem/lightbox';
import router from '@/router/index';
Vue.component(LightBox.name, LightBox);
Vue.component(LightBoxImg.name, LightBoxImg);
export default {
  name: 'approve-opinions',
  data() {
    return {
      showImgtag: false,
      showImg: '',
      fwUrl: _baseUrl,
      data: [],
      toggleWord: ['展开', '收起'],
      handerInfo: {}
    };
  },
  props: {
    instId: String,
    nodeUsers: Array
  },
  created() {
    this.getHistory(this.instId);
  },
  methods: {
    getHistory(instId) {
      var url = _baseUrl + '/bpm/core/bpmNodeJump/listForInstMobile.do?instId=' + instId;
      this.$ajax.get(url).then(res => {
        var data = res.data;
        var nodeJumps = data.nodeJumps || [];
        var bpmInst = data.bpmInst;
        //当前处理人
        if (bpmInst) {
          nodeJumps.unshift({
            handler: bpmInst.taskNodeUsers,
            nodeName: bpmInst.taskNodes,
            curNode: true
          });
        }
        //下节点处理人
        var nodeUsers = this.nodeUsers || [];
        for (let i = 0, j = nodeUsers.length; i < j; i++) {
          var nodeUser = nodeUsers[i];
          if (!nodeUser) continue;
          nodeJumps.unshift({
            handler: nodeUser.taskNodeUser ? nodeUser.taskNodeUser.userFullnames : "",
            nodeName: nodeUser.nodeText,
            nextNode: true
          });
        }
        this.data = nodeJumps;
        console.log(this.data);
      });
    },
    lookImg(fileId) {
      console.log(fileId);
      this.showImg = this.fwUrl + "/sys/core/file/download/" + fileId + ".do";
      this.showImgtag = true;
      console.log(this.showImgtag);
    },
    getFilePath(fileId) {
      return this.getRealUrl() + "/sys/core/file/previewFile.do?fileId=" + fileId;
    },
    downloadFile(file) {
      this.$dialog.alert({
        mes: "该类附件请到PC端下载预览，感谢。"
      });
    },
    preViewOffice(file) {
      var netUrl = this.getRealUrl();
      let src = netUrl + "/scripts/PDFShow/web/viewer.html?file=" + netUrl + "/sys/core/file/download/" + file.fileId + ".do";
      router.push({
        name: 'preViewViaPdf',
        params: {
          "src": src
        }
      });
    },
    getFileType(file) {
      var extName = file.extName.toLowerCase();
      if (extName == "jpg" || extName == "png" || extName == "bmp" || extName == "jpeg" || extName == "gif") {
        return 1;
      } else if (extName == "pdf") {
        return 2;
      } else if (extName == "doc" || extName == "docx" || extName == "xls" || extName == "xlsx" || extName == "ppt" || extName == "pptx") {
        return 3;
      }
      return 4;
    },
    showFile(item) {
      item.show = !item.show;
      item.toggleTag = item.toggleTag == 0 ? 1 : 0;
    },
    onTypeRenderer(nextJumpType) {
      var s = "";
      if (nextJumpType == "orgPathReturn") {
        s = '-原路返回';
      } else if (nextJumpType == "normal") {
        s = '-重走流程';
      }
      return s;
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { RxUtil } from "@/assets/util.js";
export default {
  name: "rx-boxs",
  props: {
    value: String,
    textField: String,
    valField: String,
    containstyle: String,
    allowSelect: {
      type: Boolean,
      default: true
    },
    single: {
      type: Boolean,
      default: false
    },
    showid: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      list: [],
      iserror: false
    };
  },
  activated() {
    this.resetData();
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    resetData() {
      this.list = [];
    },
    removeSelect(index) {
      this.list.splice(index, 1);
      var v = this.getValue();
      this.$emit('input', v);
    },
    selectItem(item) {
      item.selected = !item.selected;
    },
    setValue(value) {
      if (!value) return;
      //value 格式  [{id:"",text:""},{id:"",text:""}]
      this.render(value);
      var v = this.getValue();
      this.$emit('input', v);
    },
    render(value) {
      if (!value) {
        this.list = [];
        return;
      }
      //value 格式  [{id:"",text:""},{id:"",text:""}]
      if (this.single) {
        //单选  清空原来选的值
        this.list = [];
      }
      var json = eval("(" + value + ")");
      for (var i = 0; i < json.length; i++) {
        var obj = json[i];
        var rtn = RxUtil.isInList(this.list, "id", obj[this.valField]);
        if (rtn) continue;
        var o = {};
        o.id = obj[this.valField];
        o.name = obj[this.textField];
        o.selected = false;
        this.list.push(o);
      }
    },
    /**
     * 取得选择的ID值。
     * isAry:
     * true 返回数组
     * false 返回逗号分割的字符串
     */
    getIdVal(isAry) {
      return this.getSingleVal(isAry, true);
    },
    /**
     * 取得选择的文本值。
     * isAry:
     * true 返回数组
     * false 返回逗号分割的字符串
     */
    getTextVal() {
      return this.getSingleVal(isAry, false);
    },
    getSingleVal(isAry, isId) {
      if (this.list.length == 0) {
        return isAry ? [] : "";
      }
      var jsonAry = [];
      for (var i = 0; i < this.list.length; i++) {
        var obj = this.list[i];
        var v = isId ? obj.id : obj.name;
        jsonAry.push(v);
      }
      return isAry ? jsonAry : jsonAry.join(",");
    },
    getValue() {
      if (this.list.length == 0) return "[]";
      var jsonAry = [];
      for (var i = 0; i < this.list.length; i++) {
        var o = {};
        var obj = this.list[i];
        o[this.textField] = obj.name;
        o[this.valField] = obj.id;
        jsonAry.push(o);
      }
      return JSON.stringify(jsonAry);
    },
    handSelect() {
      this.$emit('select', this);
    }
  },
  watch: {
    value: function (val, oldVal) {
      this.render(val);
    }
  }
};
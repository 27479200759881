/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.replace-all.js";
import Vue from 'vue';
import { openDialog, closeDialog } from '@/assets/ui.js';
import { RxUtil, VTypes, _validVtype } from "@/assets/util.js";
import _ from 'lodash';
import router from '@/router/index';
import axios from "axios";
var MathUtil = {};
MathUtil.toNumber = function (x) {
  if (x === null || x === undefined || x === '') return '';
  if (typeof x == "string") {
    x = x.replace(/,/g, "");
  }
  var match = x.toString().match(/([$|￥])\d+\.?\d*/);
  if (match) {
    x = x.replace(match[1], '');
  }
  return Number(x);
};

/**
 * 求最大值
 * @param {Object} ary
 */
MathUtil.max = function (ary) {
  if (!ary || ary.length == 0) return 0;
  var tmp = 0,
    x = 0,
    size = ary.length;
  for (var i = 0; i < size; i++) {
    x = MathUtil.getNum(ary[i]);
    if (x > tmp) tmp = x;
  }
  return tmp;
};

/**
 * 求最小值
 * @param {Object} ary
 */
MathUtil.min = function (ary) {
  if (!ary || ary.length == 0) return 0;
  var tmp = 0,
    x = 0,
    size = ary.length;
  for (var i = 0; i < size; i++) {
    x = MathUtil.getNum(ary[i]);
    if (x < tmp) {
      tmp = x;
    }
  }
  return tmp;
};
/**
 * 求平均值。
 * @param {Object} ary
 */
MathUtil.avg = function (ary) {
  if (!ary || ary.length == 0) return 0;
  var tmp = 0,
    x = 0,
    size = ary.length;
  for (var i = 0; i < size; i++) {
    x = MathUtil.getNum(ary[i]);
    tmp += x;
  }
  tmp = tmp / size;
  tmp = MathUtil.roundFixed(tmp, 2);
  return tmp;
};

/**
 * 获取数据为空则为0
 * @param {Object} num
 */
MathUtil.getNum = function (num) {
  if (!num) return 0;
  if (isNaN(num)) return 0;
  return parseFloat(num);
};

/**
 * 求和
 * @param {Object} ary
 */
MathUtil.sum = function (ary) {
  if (typeof ary == 'number') return ary;
  if (!ary || ary.length == 0) return 0;
  var tmp = 0,
    x = 0,
    size = ary.length;
  for (var i = 0; i < size; i++) {
    x = MathUtil.getNum(ary[i]);
    tmp += x;
  }
  tmp = MathUtil.roundFixed(tmp, 2);
  return tmp;
};

/**
 * 四舍五入计算。
 * num 需要处理的数
 * fixed: 保留的小数位
 */
MathUtil.roundFixed = function (num, fixed) {
  num = MathUtil.getNum(num);
  var pos = num.toString().indexOf('.');
  if (pos == -1) return num;
  var decimal_places = num.toString().length - pos - 1;
  var _int = num * Math.pow(10, decimal_places);
  var divisor_1 = Math.pow(10, decimal_places - fixed);
  var divisor_2 = Math.pow(10, fixed);
  return Math.round(_int / divisor_1) / divisor_2;
};

/**
 * 加载列表。
 * @param url
 * @param params
 * @param _self
 * @param callback
 */
export function loadScrollList(url, params, _self, callback) {
  //_self.$dialog.loading.open('正在加载数据请稍候...');

  _self.$ajax.post(url, params).then(response => {
    _self.$dialog.loading.close();
    let _list = response.data.rowList;
    if (callback) {
      callback(response.data);
    }
    var len = _list.length;
    for (var i = 0; i < len; i++) {
      var o = _list[i];
      o.expand = false;
    }
    _list = [..._self.list, ..._list];
    _self.list = _list;
    if (_self.list.length == response.data.total) {
      _self.$refs.infinitescroll_.$emit('ydui.infinitescroll.loadedDone');
      return;
    }
    _self.$refs.infinitescroll_.$emit('ydui.infinitescroll.finishLoad');
    _self.pageIndex = _self.pageIndex + 1;
  }).catch(function (error) {
    console.log(error);
  });
}
export function loadRefreshList(url, params, _self, callback, merage) {
  //_self.$dialog.loading.open('正在加载数据请稍候...');
  _self.$ajax.post(url, params).then(response => {
    //_self.$dialog.loading.close();
    const _list = response.data.rowList || response.data.data;
    callback(response.data);
    var len = _list.length;
    for (var i = 0; i < len; i++) {
      var o = _list[i];
      o.expand = false;
    }
    if (merage) {
      _self.list = [..._self.list, ..._list];
    } else {
      _self.list = _list;
    }
    _self.$refs.pullrefreshList_.$emit('ydui.pullrefresh.finishLoad');
  }).catch(function (error) {
    console.log(error);
  });
}
export function loadPage(url, params, _self, key, merage, callback) {
  //	_self.$dialog.loading.open('正在加载数据请稍候...');
  _self.$ajax.post(url, params).then(response => {
    _self.$dialog.loading.close();
    let _list = response.data.rowList;
    var len = _list.length;
    for (var i = 0; i < len; i++) {
      var o = _list[i];
      o.expand = false;
    }
    var listName = key + "List";
    var pageName = key + "Page";
    if (merage) {
      _list = [..._list, ..._self[listName]];
    }
    _self[listName] = _list;
    _self[pageName] = _self[pageName] + 1;
    callback(response.data);
  });
}
export function loadCustomList(url, params, _self, callback, merage) {
  //_self.$dialog.loading.open('正在加载数据请稍候...');

  _self.$ajax.post(url, params).then(response => {
    _self.$dialog.loading.close();
    var rtn = response.data;
    let _list = rtn instanceof Array ? rtn : rtn.data;
    callback(response.data);
    var len = _list.length;
    for (var i = 0; i < len; i++) {
      var o = _list[i];
      o.expand = false;
    }
    if (merage) {
      _self.list = [..._self.list, ..._list];
    } else {
      _self.list = _list;
    }
    _self.$refs.pullrefreshList_.$emit('ydui.pullrefresh.finishLoad');
  }).catch(function (error) {
    console.log(error);
  });
}
export function openOpinion(vueInst, procInstId) {
  var conf = {};
  conf.id = "historyOpinion";
  conf.title = "审批历史";
  var temp = `<div>
                <article class="m-list list-theme1" v-for="item in data" v-if="data.length>0">
                    <div class="list-mes" >
                        <div class="list-title">{{item.nodeName}}</div>
                        <div class="list-other">
                            <ul>
                              <li>开始时间:{{item.createTime}}</li>
                              <li>结束时间:{{item.completeTime}}</li>
                              <li>状态:{{item.checkStatusText}}</li>
                              <li>意见:{{item.remark}}</li>
                            </ul>
                        </div>
                    </div>
                </article>
                <article class="m-list list-theme1"  v-if="data.length==0">
                  <div class="list-mes" >
                        <div class="list-title">还没有审批记录!</div>
                  </div>
                </article>
    	    </div>`;
  conf.content = temp;
  conf.full = true;
  openDialog(conf);
  showOpinion(vueInst, procInstId);
}
function showOpinion(vueInst, actInstId) {
  var url = _baseUrl + '/bpm/core/bpmNodeJump/listForInst.do?actInstId=' + actInstId;
  vueInst.$ajax.get(url).then(function (response) {
    var data = response.data.data;
    var app = new Vue({
      el: '#body_historyOpinion',
      data: {
        data: data
      }
    });
  });
}

/**
 * 计算控件权限。
 */
export function calcRight(vm) {
  var readonly = false;
  var read = vm.readonly;
  if (typeof read == "string") {
    readonly = read == "true" ? true : false;
  } else {
    readonly = vm.readonly;
  }
  if (readonly) return "r";
  var tmp = vm.permissionkey;
  if (!tmp) return readonly ? "r" : "w";
  if (!vm.permission) return "w";
  if (!vm.permission[tmp]) {
    return readonly ? "r" : "w";
  }
  return vm.permission[tmp].right;
}

/**
 * 将formmodels转换成 提交的JSON结构。
 */
export function convertBoJson(formModels) {
  var jsonData = {};
  var aryJson = [];
  if (!formModels || formModels.length == 0) {
    return jsonData;
  }
  for (var i = 0; i < formModels.length; i++) {
    var formModel = formModels[i];
    var formData = {};
    formData.boDefId = formModel.boDefId;
    formData.formKey = formModel.formKey;
    formData.data = formModel.jsonData;
    aryJson.push(formData);
  }
  //改：用品采购需将jsonData置空
  if (formModels[0].formKey != "ypcg") {
    jsonData.bos = aryJson;
  }
  return jsonData;
}

/**
 * 初始化formModels数据。
 * @param {Object} formModels
 */
export function initFormModels(formModels) {
  for (var j = 0; j < formModels.length; j++) {
    var formModel = formModels[j];
    var json = formModel.jsonData;
    for (var key in json) {
      if (!key.startWith("SUB_")) continue;
      var ary = json[key];
      for (var i = 0; i < ary.length; i++) {
        var o = ary[i];
        o.selected = false;
      }
    }
  }
}

/**
 * 加载表单
 */
export var loadingComponent = {
  template: "<div>正在加载表单</div>"
};

/**
 * 创建表单vue实例。
 * @param {Object} containerId	容器ID
 * @param {Object} formModel	表单数据
 */
export var createVueInstance = Vue.component('mobileForm', {
  template: '',
  data() {
    return {
      //    		data:formData,
      //	        readonly:readonly,
      //	        permission:rightJson,

      data: {},
      readonly: false,
      permission: {},
      showUserDialog: false,
      showGroupDialog: false,
      //选择的用户控件.
      currentUserVm: {},
      currentGroupVm: {},
      singleUser: false,
      singleGroup: false,
      //子表行编辑。
      curRow: {},
      action: "add",
      entName: "",
      index: 0,
      //是否选择
      isSelected: false,
      formula: {},
      query: {},
      boDefId: "",
      //formModel.boDefId,
      orginData: {},
      orginArrayData: [],
      title: "",
      extJson: {}
    };
  },
  mounted() {
    this.$nextTick(function () {
      //解析公式
      this.parseFormula();
      //解析自定义查询
      this.parseQuery();
      //子表必填
      this.parseSubTable();
    });
  },
  computed: {
    dataWatch() {
      return JSON.parse(JSON.stringify(this.data));
    }
  },
  created: function () {
    for (var s in this.data) {
      this.orginData[s] = this.data[s];
    }
  },
  methods: {
    isReadonly(fieldName, entName) {
      if (this.readonly) return true;
      var readonly = true;
      try {
        //不为空说明是子表
        if (entName) {
          readonly = this.permission.sub[entName].fields[fieldName].right == 'r';
        } else {
          readonly = this.permission.main[fieldName].right == 'r';
        }
      } catch (e) {
        return readonly;
      }
      return readonly;
    },
    onload() {
      if (window["onload_" + this.boDefId]) {
        window["onload_" + this.boDefId](this, this.data);
      }
    },
    isRequired(fieldName, entName) {
      //为none的时候隐藏
      var hidden;
      try {
        //不为空说明是子表
        if (entName) {
          hidden = this.permission.sub[entName].fields[fieldName].right == 'none';
        } else {
          hidden = this.permission.main[fieldName].right == 'none';
        }
      } catch (e) {
        return false;
      }
      return hidden;
    },
    calcHidden(fieldName, entName) {
      //为none的时候隐藏
      var hidden;
      try {
        //不为空说明是子表
        if (entName) {
          hidden = this.permission.sub[entName].fields[fieldName].mobileHidden;
        } else {
          hidden = this.permission.main[fieldName].mobileHidden;
        }
      } catch (e) {
        return false;
      }
      return hidden;
    },
    //添加子表行数据。
    add(entName) {
      var row = {
        selected: false
      };
      if (this.data.initData) {
        var newRow = this.data.initData[entName];
        if (newRow) {
          Object.assign(row, newRow);
        }
      }
      var subData = this.data["SUB_" + entName];
      if (!subData) {
        subData = [];
      }
      subData.push(row);
      this.$set(this.data, "SUB_" + entName, subData);
    },
    //处理子表行
    handOk() {
      var data = this.data["SUB_" + this.entName];
      if (this.action == "add") {
        data.push(this.curRow);
      } else {
        data[this.index] = this.curRow;
      }
    },
    //添加行
    addDialog(entName) {
      this.entName = entName;
      this.action = "add";
      var row = {
        selected: false
      };
      if (this.data.initData) {
        var newRow = this.data.initData[entName];
        if (newRow) {
          Object.assign(row, newRow);
        }
      }
      var dialogName = "dialog_" + entName;
      this.curRow = row;
      this.$refs[dialogName].entName = entName;
      this.$refs[dialogName].show();
    },
    //编辑行
    editDialog(entName, index) {
      this.entName = entName;
      this.action = "edit";
      this.index = index;
      var data = this.data["SUB_" + entName];
      this.curRow = data[index];
      var dialogName = "dialog_" + entName;
      this.$refs[dialogName].show();
    },
    //删除子表行记录
    remove(entName, index) {
      this.$dialog.confirm({
        mes: '是否删除此记录？',
        opts: [{
          txt: '取消',
          color: false
        }, {
          txt: '确定',
          color: '#25c4b5',
          callback: () => {
            this.data["SUB_" + entName].splice(index, 1);
          }
        }]
      });
    },
    /**
     * 是否选中一行数据。
     */
    selected(item) {
      item.selected = !item.selected;
      this.isSelected = true;
    },
    //关闭用户对话框。
    closeUserDialog() {
      this.addClass(this.$el.parentElement, "scroll");
      this.showUserDialog = false;
    },
    //关闭用户组对话框。
    closeGroupDialog() {
      this.addClass(this.$el.parentElement, "scroll");
      this.showGroupDialog = false;
    },
    //选择用户。
    selectUser(user) {
      this.addClass(this.$el.parentElement, "scroll");
      this.showUserDialog = false;
      //设置用户。
      this.currentUserVm.setUser(user);
    },
    //选择用户组
    selectGroup(group) {
      this.addClass(this.$el.parentElement, "scroll");
      this.showGroupDialog = false;
      //设置用户组。
      this.currentGroupVm.setGroup(group);
    },
    //将用户选择对象实例绑定到当前作用域的currentUserVm对象。
    onOpenUserDialog(vm) {
      this.removeClass(vm.$parent.$el.parentElement, "scroll");
      this.showUserDialog = true;
      var sysUser = this.$refs.sysUser;
      sysUser.initConfig(vm.conf);
      sysUser.search(vm);
      // 设置选择用户。
      sysUser.setValue("");
      this.currentUserVm = vm;
      this.singleUser = vm.single;
    },
    onOpenGroupDialog(vm) {
      this.removeClass(vm.$parent.$el.parentElement, "scroll");
      this.showGroupDialog = true;
      var sysGroup = this.$refs.sysGroup;
      sysGroup.initConfig(vm.conf);
      //用戶組還是部門
      sysGroup.type = vm.type;
      sysGroup.search();
      // 设置选择用户组。
      sysGroup.setValue("");
      this.currentGroupVm = vm;
      this.singleGroup = vm.single;
    },
    hasClass(elem, cls) {
      cls = cls || '';
      if (cls.replace(/\s/g, '').length == 0) return false;
      return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ');
    },
    addClass(ele, cls) {
      if (!this.hasClass(ele, cls)) {
        ele.className = ele.className == '' ? cls : ele.className + ' ' + cls;
      }
    },
    removeClass(ele, cls) {
      if (this.hasClass(ele, cls)) {
        let newClass = ' ' + ele.className.replace(/[\t\r\n]/g, '') + ' ';
        while (newClass.indexOf(' ' + cls + ' ') >= 0) {
          newClass = newClass.replace(' ' + cls + ' ', ' ');
        }
        ele.className = newClass.replace(/^\s+|\s+$/g, '');
      }
    },
    getAddRight(entName) {
      if (this.readonly) return false;
      var r = this.permission.sub[entName].tbright.add;
      if (r) return r;
    },
    getDelRight(entName) {
      if (this.readonly) return false;
      var r = this.permission.sub[entName].tbright.del;
      return r;
    },
    getEditRight(entName) {
      if (this.readonly) return false;
      var r = this.permission.sub[entName].tbright.edit;
      return r;
    },
    getMgrRight(entName) {
      if (this.readonly) return false;
      var r = this.permission.sub[entName].tbright.edit || this.permission.sub[entName].tbright.del;
      return r;
    },
    hasRight(entName) {
      try {
        return !this.permission.sub[entName].tbright.hidden;
      } catch (e) {
        return false;
      }
    },
    getRight(type, entName) {
      if (type == 'main') {
        return this.permission.main;
      } else {
        try {
          return this.permission.sub[entName].fields;
        } catch (e) {
          return false;
        }
      }
    },
    //按钮点击事件。
    //页面上添加按钮
    //<input type="button" v-on:click="handClick('add')" />
    handClick(action) {
      this["func_" + action](this);
    },
    //解析公式
    parseFormula() {
      var formula = FormCalc.parseFormula(this.boDefId);
      this.formula = formula;
    },
    //解析自定义查询
    parseQuery() {
      var query = FormCalc.parseQuery(this.boDefId);
      this.query = query;
      //自定义查询
      FormCalc.calcQuery(this, this.query, this.data, "load");
    },
    //子表必填
    parseSubTable() {
      var boDefId = this.boDefId;
      var extJson_ = window["extJson_" + boDefId];
      if (!extJson_) return;
      for (var key in extJson_) {
        var val = extJson_[key];
        if (typeof val != 'string') continue;
        var o = eval("(" + val + ")");
        extJson_[key] = o;
      }
      this.extJson = extJson_;
    },
    calc: _.debounce(function calcFormula() {
      //自定义查询
      FormCalc.calcQuery(this, this.query, this.data, "valueChange");
      //表单公式计算。
      FormCalc.calc(this.formula, this.data);
      //计算日期。
      FormCalc.calcDate(this.boDefId, this.data);
      this.$forceUpdate();
    }, 500),
    validSubRow(entName) {
      var rules = window["validRule_" + this.boDefId];
      var ruleObj = rules[entName];
      var obj = this.curRow;
      for (var field in ruleObj) {
        var rule = ruleObj[field];
        var val = obj[field];
        //校验的字段名
        var fieldName = rule.field;
        var rtn = ValidUtil.validRequired(val, rule, true, field, entName, this.permission);
        if (!rtn) return {
          valid: false,
          msg: fieldName + "必填"
        };
      }
      return {
        valid: true
      };
    },
    //验证表单
    valid(require) {
      var rtn = ValidUtil.valid(require, this.boDefId, this.data, this.permission);
      return rtn;
    },
    //验证子表必填
    validSubTable() {
      var boDefId = this.boDefId;
      var data = this.data;
      var extJson_ = this.extJson;
      if (!extJson_) return {
        success: true
      };
      for (var key in extJson_) {
        var ob = extJson_[key];
        var required = ob.required;
        var subData = data["SUB_" + key];
        var tableName = ob.tableName;
        if (required && (!subData || subData.length == 0)) {
          return {
            success: false,
            msg: "子表-" + tableName + "-必填"
          };
        }
      }
      return {
        success: true
      };
    },
    //XmlHttpRequest
    sendXmlHttpRequest(url, params) {
      return RxUtil.sendXmlHttpRequest(url, params);
    }
  },
  watch: {
    //观察数据变化。
    dataWatch: {
      handler: function (newValue, oldValue) {
        if (!this.isSelected) {
          console.info("watch:" + newValue);
          this.calc();
          if (window.custFunctions) {
            var evalFun = [];
            var evalData = "{";
            var funIndex = 0;
            for (var s in this.data) {
              if (newValue[s] != this.orginData[s]) {
                if (custFunctions[s]) {
                  evalFun[funIndex] = s;
                  funIndex++;
                }
                ;
              }
              var subIndex = 0;
              if (s.indexOf("SUB_") != -1) {
                for (var i = 0; i < newValue[s].length; i++) {
                  var tempIndex = 0;
                  for (var key in newValue[s][i]) {
                    var a = newValue[s][i][key];
                    var b = this.orginArrayData[key];
                    if (b != null && a !== b) {
                      if (custFunctions[s + "_" + key]) {
                        evalFun[funIndex] = s + "_" + key;
                        funIndex++;
                        tempIndex++;
                      }
                      ;
                    }
                  }
                  if (subIndex != tempIndex) {
                    break;
                  }
                }
              }
              if (s.indexOf("SUB_") != -1) {
                var evalSubData = "[";
                for (var i = 0; i < newValue[s].length; i++) {
                  evalSubData += "{";
                  for (var key in newValue[s][i]) {
                    if (key.indexOf("contains") != -1) {
                      continue;
                    }
                    evalSubData += key + ":'" + newValue[s][i][key] + "',";
                  }
                  evalSubData = evalSubData.substr(0, evalSubData.length - 1);
                  evalSubData += "},";
                }
                evalSubData = evalSubData.substr(0, evalSubData.length - 1);
                evalSubData += "]";
                evalData += s + ":" + evalSubData + ",";
                continue;
              }
              if (s.indexOf("initData") != -1) {
                continue;
              }
              evalData += s + ":'" + this.data[s] + "',";
            }
            evalData = evalData.substr(0, evalData.length - 1);
            evalData += "}";
            for (var i = 0; i < evalFun.length; i++) {
              var str = "custFunctions." + evalFun[i] + "(" + evalData + ")";
              eval(str);
            }
            for (var s in this.orginData) {
              if (s.indexOf("SUB_") != -1) {
                for (var i = 0; i < newValue[s].length; i++) {
                  for (var key in newValue[s][i]) {
                    this.orginArrayData[key] = newValue[s][i][key];
                  }
                }
                continue;
              }
              this.orginData[s] = newValue[s];
            }
          }
        }
        this.isSelected = false;
        if (window['valuechanged_' + this.boDefId]) {
          window['valuechanged_' + this.boDefId](this, newValue, oldValue);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  }
});
//在表单上写脚本如下
/*
var custFuntions= [{
 		name:"add",
 		action:function(vueInst){
 			//获取表单的数据。
 			var data=vueInst.data;
 		}
 }]
*/
//  if(window.custFuntions){
//  	for(var i=0;i<custFuntions.length;i++){
//			var json=custFuntions[i];
//			vue["func_" +json.name ]=json.action;
//		}
//  }
//  return vue;
//}

var FormCalc = {
  //解析公式。
  parseFormula: function (boDefId) {
    var formula = {};
    var formulas = window["formulas_" + boDefId];
    if (!formulas) return formula;
    var mainFormula = formulas.main;
    //解析子表。
    for (var key in formulas) {
      if (key == "main") continue;
      var tableFormula = formulas[key];
      var aryFormula = [];
      var regStr = /\{(\(.*?\)){0,1}(\w*?)\.(\w*?)\}/g;
      for (var target in tableFormula) {
        var tmp = tableFormula[target];
        var fields = this.parseFields(tmp);
        var formulaStr = tmp.replace(regStr, function (a, b, c, d) {
          return "MathUtil.getNum(row." + d + ")";
        });
        formulaStr = "row." + target + "=MathUtil.roundFixed(" + formulaStr + ",2)";
        var o = {
          formula: formulaStr,
          fields: fields
        };
        aryFormula.push(o);
      }
      formula[key] = aryFormula;
    }
    if (mainFormula) {
      formula.main = mainFormula;
    }
    return formula;
  },
  //解析自定义查询
  parseQuery: function (boDefId) {
    var query = {};
    var querys = window["querys_" + boDefId];
    if (!querys) return query;
    var mainQuery = querys.main;
    //解析子表。
    for (var key in querys) {
      if (key == "main") continue;
      var tableQuery = querys[key];
      query[key] = tableQuery;
    }
    if (mainQuery) {
      query.main = mainQuery;
    }
    return query;
  },
  //解析公式中的字段。
  //公式格式:{a}+ {b}
  parseFields: function (formula) {
    var reg = /\{(\(.*?\)){0,1}(\w*?)\}/g;
    var match = reg.exec(formula);
    var fields = [];
    while (match != null) {
      //目标字段
      var field = match[2];
      fields.push(field);
      match = reg.exec(formula);
    }
    return fields;
  },
  calcQuery: function (self, querys, formData, type) {
    //计算子表
    for (var alias in querys) {
      var aryQuery = querys[alias];
      var zbData = formData["SUB_" + alias];
      if (!zbData) continue;
      var orginData = self.orginData["SUB_" + alias];
      for (var key in aryQuery) {
        var queryAry = JSON.parse(aryQuery[key]);
        for (var i = 0; i < queryAry.length; i++) {
          var query = queryAry[i];
          var events = query.event;
          var aryEvent = events.split(",");
          for (var j = 0; j < aryEvent.length; j++) {
            var event = aryEvent[j];
            if (type == "load" && (event == "addinit" || event == "editinit" || event == "detailinit")) {
              this.executeQuery(self, query);
            } else if (type == "valueChange" && (event == "enter" || event == "valuechanged")) {
              for (var k = 0; k < zbData.length; k++) {
                if (k < orginData.length && zbData[k][key] != orginData[k][key]) {
                  this.executeQuery(self, query, k);
                }
                var zb = [];
                for (var l = 0; l < zbData.length; l++) {
                  zb.push(JSON.parse(JSON.stringify(zbData[l])));
                }
                self.orginData["SUB_" + alias] = zb;
              }
            }
          }
        }
      }
    }
    //计算主表
    //{"main":{"bbb":"[{\"gridReturn\":[{\"name\":\"NAME_\",\"comment\":\"名称\",\"mapVal\":\"ddd\"},
    //{\"name\":\"CODE_\",\"comment\":\"编码\"}],\"isMain\":true,\"name\":\"111\",\"event\":\"valuechanged\",\"customquery\":\"user\",
    //\"gridInput\":[{\"mode\":\"mapping\",\"modeName\":\"映射\",\"name\":\"CODE_\",\"bindVal\":\"ccc\",\"comment\":\"编码\"}],
    //\"table\":\"main\"}]"}}
    var mainData = formData;
    var mainQuerys = querys.main;
    if (mainQuerys) {
      for (var key in mainQuerys) {
        var queryAry = JSON.parse(mainQuerys[key]);
        for (var i = 0; i < queryAry.length; i++) {
          var query = queryAry[i];
          var events = query.event;
          var aryEvent = events.split(",");
          for (var j = 0; j < aryEvent.length; j++) {
            var event = aryEvent[j];
            if (type == "load" && (event == "addinit" || event == "editinit" || event == "detailinit")) {
              this.executeQuery(self, query);
            } else if (type == "valueChange" && (event == "enter" || event == "valuechanged")) {
              var orginData = self.orginData;
              if (mainData[key] != orginData[key]) {
                /*orginData[key]=mainData[key];
                if(mainData[key+"_name"]){
                  orginData[key+"_name"]=mainData[key+"_name"];
                }*/
                this.executeQuery(self, query);
              }
            }
          }
        }
      }
    }
  },
  executeQuery: function (self, query, index) {
    var customQuery = query.customquery;
    var gridInput = query.gridInput;
    var gridReturn = query.gridReturn;
    var mainData = self.data;
    //构建输入参数
    var params = {};
    var table = query.table;
    for (var i = 0; i < gridInput.length; i++) {
      var input_ = gridInput[i];
      var mode = input_.mode;
      var name = input_.name;
      var bindVal = input_.bindVal;
      if (input_.thistype == 'main') {
        bindVal = bindVal.replace("__main", "");
      }
      if (mode == "mapping") {
        var val;
        if (table == "main") {
          val = mainData[bindVal];
        } else {
          try {
            var idx = index ? index : 0;
            val = mainData["SUB_" + table][idx][bindVal];
          } catch (e) {}
        }
        params[name] = val;
      } else {
        var func = new Function(bindVal);
        var val = func();
        params[name] = val;
      }
    }
    var th = this;
    //执行自定义查询 doQuery(self,alias, params,callBack,sync){
    globalMixin.methods.doQuery(self, customQuery, params, function (data) {
      var aryData = data;
      if (table == "main") {
        var obj = {};
        if (aryData.length > 0) {
          obj = aryData[0];
        }
        ;
        var result = th.getResultRow(obj, gridReturn);
        for (var key in result) {
          //if(self.data.hasOwnProperty(key)){
          self.data[key] = result[key];
          //}
        }
      } else {
        if (index || index == 0) {
          var rData = {};
          if (aryData.length > 0) {
            rData = aryData[0];
          }
          var row = th.getResultRow(rData, gridReturn);
          if (!self.data["SUB_" + table][index]) {
            self.data["SUB_" + table][index] = row;
            return;
          }
          for (var key in row) {
            //if(self.data["SUB_"+table][index].hasOwnProperty(key)){
            self.data["SUB_" + table][index][key] = row[key];
            //}
          }
        } else {
          for (var i = 0; i < aryData.length; i++) {
            var rData = aryData[i];
            var row = th.getResultRow(rData, gridReturn);
            if (!self.data["SUB_" + table][i]) {
              self.data["SUB_" + table][i] = row;
              continue;
            }
            for (var key in row) {
              //if(self.data["SUB_"+table][index].hasOwnProperty(key)){
              self.data["SUB_" + table][i][key] = row[key];
              //}
            }
          }
        }
      }
    });
  },

  getResultRow: function (row, gridReturn) {
    var result = {};
    if (!row) row = {};
    for (var i = 0; i < gridReturn.length; i++) {
      var o = gridReturn[i];
      var mapVal = o.mapVal;
      if (o.thistype == 'main') {
        mapVal = mapVal.replace("__main", "");
      }
      var val = row[o.name];
      if (typeof val != "number" && !val) {
        val = "";
      }
      if (mapVal) {
        result[mapVal] = val;
      }
    }
    return result;
  },
  calc: function (formulas, formData) {
    //计算子表
    for (var key in formulas) {
      var aryFormula = formulas[key];
      var data = formData["SUB_" + key];
      if (!data) continue;
      for (var i = 0; i < aryFormula.length; i++) {
        var o = aryFormula[i];
        var formula = o.formula;
        for (var j = 0; j < data.length; j++) {
          var row = data[j];
          eval(formula);
        }
      }
    }
    //计算主表
    //{total: "{jine} +MathUtil.sum([{zibiao1.amount}*{zibiao1.price}])"}
    var mainData = formData;
    var mainFormulas = formulas.main;
    if (mainFormulas) {
      for (var key in mainFormulas) {
        var formula = mainFormulas[key];
        var regStr = /\{(\(.*?\)){0,1}(\w*?)\}/g;
        //替换主表。
        var formulaStr = formula.replace(regStr, function (a, b, c) {
          return "MathUtil.getNum(mainData." + c + ")";
        });
        var regStr = /\[(.*?)\]/g;
        //没有子表。
        var match = regStr.exec(formulaStr);
        if (!match) {
          var val = eval(formulaStr);
          val = MathUtil.roundFixed(val, 2);
          formData[key] = val;
          continue;
        }
        while (match != null) {
          var rowFormula = match[1];
          var regRowExp = /\{(\(.*?\)){0,1}(\w*?)\.(\w*?)\}/g;
          var rowMatch = regRowExp.exec(rowFormula);

          // 1.获取子表名
          var tableName = "";
          var fields = [];
          while (rowMatch != null) {
            tableName = rowMatch[2];
            fields.push(rowMatch[3]);
            rowMatch = regRowExp.exec(rowFormula);
          }
          //2.替换公式
          var regStr = /\[(.*?)\]/g;
          formulaStr = formulaStr.replace(match[0], function (a, b, c) {
            return "mainData.SubAry_" + tableName;
          });
          var rowFormula = rowFormula.replace(regRowExp, function (a, b, c, d) {
            return "MathUtil.getNum(row." + d + ")";
          });
          var tmpAry = [];
          var gridData = formData["SUB_" + tableName];
          if (gridData) {
            for (var i = 0; i < gridData.length; i++) {
              var row = gridData[i];
              var val = eval(rowFormula);
              tmpAry.push(val);
            }
            mainData["SubAry_" + tableName] = tmpAry;
          }
          match = regStr.exec(formulaStr);
        }
        //计算子表。
        var val = eval(formulaStr);
        val = MathUtil.roundFixed(val, 2);
        formData[key] = val;
      }
    }
  },
  /**
   * 计算一行数据。
   */
  calcDateRow: function (tbSetting, row) {
    for (var key in tbSetting) {
      var reg = /\{(\w*?)\}/g;
      var setting = tbSetting[key];
      setting = setting.replace(reg, function (match) {
        var obj = row[match.substring(1, match.length - 1)];
        if (obj instanceof Date) {
          return "new Date(" + obj.getTime() + ")";
        } else if (obj) {
          var dateObj = new Date(obj);
          //没有转换到
          if (isNaN(dateObj.getTime()) || !dateObj instanceof Date) {
            dateObj = new Date(obj.replace(/-/g, '/'));
          }
          return "new Date(" + dateObj.getTime() + ")";
        }
      });
      try {
        row[key] = eval(setting);
      } catch (e) {}
    }
  },
  //计算日期
  calcDate: function (boDefId, formData) {
    var dateCalcs = window["dateCalcs_" + boDefId];
    if (!dateCalcs) return;
    //计算主表
    var mainSetting = dateCalcs.main;
    if (mainSetting) {
      this.calcDateRow(mainSetting, formData);
    }
    for (var subTable in dateCalcs) {
      if (subTable == "main") continue;
      var tbSetting = dateCalcs[subTable];
      if (!tbSetting) continue;
      var data = formData["SUB_" + subTable];
      if (!data || data.length == 0) continue;
      for (var i = 0; i < data.length; i++) {
        var row = data[i];
        this.calcDateRow(tbSetting, row);
      }
    }
  }
};
//---------------------------------------验证规则jsonData key值不能为空-------------------------
export var ValidUtil = {
  //验证
  valid: function (require, boDefId, data, permission) {
    var rules = window["validRule_" + boDefId];
    if (!rules) return {
      valid: true
    };
    var mainRules = rules.main;
    // console.log(rules)
    console.log(data);
    //主表
    for (var key in mainRules) {
      var rule = mainRules[key];
      var val = data[key];
      //校验的字段名
      var fieldName = rule.field;
      //验证必填
      var rtn = this.validRequired(val, rule, require, key, "", permission);
      if (!rtn) return {
        valid: false,
        msg: fieldName + "必填"
      };
      rtn = this.validVtype(val, rule);
      if (!rtn.valid) return {
        valid: rtn.valid,
        msg: fieldName + rtn.msg
      };
    }
    //遍历子表
    for (var key in rules) {
      if (key == "main") continue;
      var ruleObj = rules[key];
      var subData = data["SUB_" + key];
      if (!subData || subData.length == 0) continue;
      //考虑到子表一对一的情况
      if (!(subData.constructor === Array)) {
        subData = [subData];
      }
      //遍历行数据
      for (var i = 0; i < subData.length; i++) {
        var row = subData[i];
        //遍历验证规则
        for (var field in ruleObj) {
          var rule = ruleObj[field];
          var val = row[field];
          //校验的字段名
          var fieldName = rule.field;
          var rtn = this.validRequired(val, rule, require, field, key, permission);
          if (!rtn) return {
            valid: false,
            msg: fieldName + "必填"
          };
          rtn = this.validVtype(val, rule);
          if (!rtn.valid) return {
            valid: rtn.valid,
            msg: fieldName + rtn.msg
          };
        }
      }
    }
    return {
      valid: true
    };
  },
  //验证必填  val:字段属性  rule:字段规则 require：是否必填   field：字段名称
  //key：实体名称，为空是为主表  permission:流程字段权限或者表单字段权限
  validRequired: function (val, rule, require, field, key, permission) {
    if (!require) return true;
    let rightReq = this.rightReq(field, key, permission);
    //权限必填优先字段必填
    let flag = false;
    //如果是只读的话  则不必填
    if (rightReq) {
      //权限必填
      flag = true;
    } else if (rule.required) {
      //字段配置必填
      flag = true;
    }
    if (!flag) return true;
    return this.isNotNull(val);
  },
  isNotNull(val) {
    if (val === null || val === undefined || val === '') {
      return false;
    }
    return true;
  },
  validVtype: function (val, ruleObj) {
    if (!ruleObj.vtype) return {
      valid: true,
      msg: ""
    };
    var rtn = _validVtype(val, ruleObj.vtype);
    return rtn;
  },
  rightReq: function (fieldName, entName, permission) {
    //流程或者表单权限判定
    var req = false;
    try {
      //不为空说明是子表
      var perm = {};
      if (entName) {
        perm = permission.sub[entName].fields[fieldName];
      } else {
        perm = permission.main[fieldName];
      }
      //不隐藏 只读 并且必填
      req = !perm.mobileHidden && perm.right == 'w' && perm.require;
    } catch (e) {
      return false;
    }
    return req;
  }
};

//转大写
export function upperJSONKey(json) {
  if (json instanceof Array) {
    for (var key in json) {
      this.upperJSONKey(json[key]);
    }
  } else if (json instanceof Object) {
    for (var key in json) {
      // var reg =/^[a-z0-9_]+$/;
      var reg = /[a-z]+$/;
      if (json[key] instanceof Object || json[key] instanceof Array) {
        this.upperJSONKey(json[key]);
      } else {
        if (reg.test(key)) {
          var lowerKey = key.toUpperCase();
          json[lowerKey] = json[key];
          delete json[key];
        }
      }
    }
  }
  return json;
}
//转时间戳 --》加需要转的时间
export function timestampToTime(formDataURL) {
  var timestamp;
  timestamp = formDataURL.FWRQ;
  var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var D = date.getDate() + ' ';
  var h = date.getHours() + ':';
  var m = date.getMinutes() + ':';
  var s = date.getSeconds();
  formDataURL.FWRQ = Y + M + D;
  return formDataURL;
}
export function loadFormInst(self, readonly, jsonDataCG) {
  console.log('loadFormInst', self);
  var formModels = self.formModels;
  var aryVm = [];
  var divContainer = document.getElementById("frmContainer");
  var formMobileIds = RxUtil.getCache("formMobileIds");
  var formMobileIdAry = [];
  if (formMobileIds) {
    formMobileIdAry = JSON.parse(formMobileIds);
  }
  for (var i = 0; i < formModels.length; i++) {
    var formModel = formModels[i];
    var flag = false;
    for (var j = 0; j < formMobileIdAry.length; j++) {
      var formMobileId = formMobileIdAry[j];
      if (formMobileId.id && formModel.viewId == formMobileId.id) {
        //存在id相同的手机表单
        if (formModel.timeStamp != formMobileId.t) {
          //不版本一致的话  缓存起来
          RxUtil.setCache(formModel.viewId, formModel.content);
          formMobileId.t = formModel.timeStamp;
        } else {
          formModel.content = RxUtil.getCache(formModel.viewId);
        }
        flag = true; //存在相同的手机表单Id
      }
    }

    if (!flag) {
      //缓存content和{id,t}
      var objId = {};
      objId.id = formModel.viewId;
      objId.t = formModel.timeStamp;
      formMobileIdAry.push(objId);
      RxUtil.setCache(formModel.viewId, formModel.content);
    }
  }
  RxUtil.setCache("formMobileIds", JSON.stringify(formMobileIdAry));
  if (formModels.length == 1) {
    var formModel = formModels[0];
    var htmlObj = RxUtil.parseHtml(formModel.content);
    var html = htmlObj.html;
    html = html.replaceAll("&#39;", "'").replaceAll("&amp;", "&");
    var deepObj = createVueInstance; //JSON.parse(JSON.stringify(createVueInstance));
    deepObj.options.template = `<div >${html}</div> `;
    //------------------------------------------------------------------------------------------------------------------------------------------------------------------
    //插入脚本
    RxUtil.insertScript("frmContainer", htmlObj.script);
    var vues = [];
    vues.push(deepObj);
    self.currentViews = vues;
    self.$nextTick(function () {
      self.formVms = self.$refs.component;
      console.log(self, self.formVms);
      if (typeof self.formVms == 'undefined') {
        return;
      }
      self.formVms.forEach(async function (formVm) {
        if ("mobileForm" == formVm.constructor.extendOptions.name) {
          formVm.permission = formModel.permission ? eval('(' + formModel.permission + ')') : {};
          var formData; // from数据
          //url生成表单
          // console.log(formModel)
          if (typeof self.bpmInst != "undefined") {
            var formKey = formModel.formKey;
            switch (formKey) {
              // case "fwlc":
              // 	var busKey = self.bpmInst.busKey;
              // 	let urlFW =_baseUrl +`/customizeProc/postingproc/pOSTINGPROC/getJson.do?ids=${busKey}`;
              // 	await axios.post(urlFW).then((res) => {
              // 		var formDataURL = upperJSONKey(res.data);
              // 		formData = timestampToTime(formDataURL)
              // 	});
              // 	break;
              case "sjqjd":
                var busKey = self.bpmInst.busKey;
                formData = formModel.jsonData;
                let url = _baseUrl + `/QjSjqj/core/sjqj/getSj.do?ids=${busKey}`;
                axios.post(url).then(resSJ => {
                  formData.F_QJRQ = resSJ.data[0];
                  formData.F_QSSJ = resSJ.data[1];
                  formData.F_ZZSJ = resSJ.data[0];
                });
                // let urlSjqj =_baseUrl +`/QjSjqj/core/sjqj/getJson.do?ids=${busKey}`;
                // await axios.post(urlSjqj).then((res) => {
                // 	var formDataURL = res.data;
                // 	formModel.jsonData.F_XM_NAME = formDataURL.xmName;
                // 	formModel.jsonData.F_KS_NAME = formDataURL.ksName;
                // 	formModel.jsonData.F_QJFS_NAME = formDataURL.qjfsName;
                // 	formModel.jsonData.F_HJSJ = formDataURL.hjsj;
                // 	formModel.jsonData.F_XS = formDataURL.xs;
                // 	formModel.jsonData.F_LJCS = formDataURL.ljcs;
                // 	formModel.jsonData.F_DLR_NAME = formDataURL.dlrName;
                // 	formModel.jsonData.F_DBSX = formDataURL.dbsx;

                // 	let urlSJsj =_baseUrl +`/QjSjqj/core/sjqj/getSj.do?ids=${busKey}`;
                // 	axios.post(urlSJsj).then(resSJ=>{
                // 		formModel.jsonData.F_QJRQ = resSJ.data[0];
                // 		formModel.jsonData.F_QSSJ = resSJ.data[1];
                // 		formModel.jsonData.F_ZZSJ = resSJ.data[0];
                // 	})
                // 	formData = formModel.jsonData;
                // });
                break;
              case "ptqjd":
                formData = formModel.jsonData;
                var busKey = self.bpmInst.busKey;
                let urlSJsj = _baseUrl + `/QjPtqj/core/ptqj/getSj.do?ids=${busKey}`;
                axios.post(urlSJsj).then(resSJ => {
                  formData.F_QJRQ = resSJ.data[0];
                  formData.F_QSSJ = resSJ.data[1];
                  formData.F_ZZSJ = resSJ.data[0];
                });
                // var busKey = self.bpmInst.busKey;
                // let urlPtqj =_baseUrl +`/QjPtqj/core/ptqj/getJson.do?ids=${busKey}`;
                // await axios.post(urlPtqj).then((res) => {
                // 	var formDataURL = res.data;
                // 	formModel.jsonData.F_XM_NAME = formDataURL.xmName;
                // 	formModel.jsonData.F_KS_NAME = formDataURL.ksName;
                // 	formModel.jsonData.F_QJFS_NAME = formDataURL.qjfsName;
                // 	formModel.jsonData.	F_JQLX_NAME = formDataURL.jqlxName;
                // 	formModel.jsonData.F_HJSJ = formDataURL.hjsj;
                // 	formModel.jsonData.F_DLR_NAME = formDataURL.dlrName;
                // 	formModel.jsonData.F_LJCS = formDataURL.ljcs;
                // 	formModel.jsonData.F_XS = formDataURL.xs;
                // 	formModel.jsonData.F_QDBSX = formDataURL.qdbsx;
                // 	let urlSJsj =_baseUrl +`/QjPtqj/core/ptqj/getSj.do?ids=${busKey}`;
                // 	axios.post(urlSJsj).then(resSJ=>{
                // 		formModel.jsonData.F_QJRQ = resSJ.data[0];
                // 		formModel.jsonData.F_QSSJ = resSJ.data[1];
                // 		formModel.jsonData.F_ZZSJ = resSJ.data[0];
                // 	})
                // 	formData = formModel.jsonData;
                // });
                break;
              case "bxqj":
                var busKey = self.bpmInst.busKey;
                let urlNJ = _baseUrl + `/customizeProc/annualLeaveForm/annualLeaveForm/getJson.do?ids=${busKey}`;
                await axios.post(urlNJ).then(res => {
                  var formDataURL = upperJSONKey(res.data);
                  formDataURL.JQKSSJ = format(formDataURL.JQKSSJ);
                  formDataURL.JQJSSJ = format(formDataURL.JQJSSJ);
                  formData = formDataURL;
                });
                break;
              case "rz":
                var busKey = self.bpmInst.busKey;
                let urlRZ = _baseUrl + `/customizeProc/onboardingAppForm/onboardingAppForm/getJson.do?ids=${busKey}`;
                await axios.post(urlRZ).then(res => {
                  var formDataURL = upperJSONKey(res.data);
                  let urlSJsj = _baseUrl + `/customizeProc/onboardingAppForm/onboardingAppForm/getSj.do?ids=${busKey}`;
                  axios.post(urlSJsj).then(resSJ => {
                    formDataURL.RZSJ = resSJ.data[0];
                    formDataURL.HTDQR = resSJ.data[1];
                  });
                  formData = formDataURL;
                });
                break;
              case "lz":
                var busKey = self.bpmInst.busKey;
                let urlLZ = _baseUrl + `/customizeProc/resignationAppForm/resignationAppForm/getJson.do?ids=${busKey}`;
                await axios.post(urlLZ).then(res => {
                  var formDataURL = upperJSONKey(res.data);
                  formDataURL.RZSJ = format(formDataURL.RZSJ);
                  formDataURL.HTDQR = format(formDataURL.HTDQR);
                  formDataURL.SQLZRQ = format(formDataURL.SQLZRQ);
                  formDataURL.HZLZRQ = format(formDataURL.HZLZRQ);
                  formData = formDataURL;
                });
                break;
              case "yydd":
                var busKey = self.bpmInst.busKey;
                let urlRYDD = _baseUrl + `/customizeProc/persTransfer/persTransfer/getJson.do?ids=${busKey}`;
                await axios.post(urlRYDD).then(res => {
                  var formDataURL = upperJSONKey(res.data);
                  formDataURL.RZRQ = format(formDataURL.RZRQ);
                  formData = formDataURL;
                });
                break;
              case "yctd":
                var busKey = self.bpmInst.busKey;
                let urlYCTD = _baseUrl + `/customizeProc/carApplicant/carApplicant/getJson.do?ids=${busKey}`;
                await axios.post(urlYCTD).then(res => {
                  var formDataURL = upperJSONKey(res.data);
                  formDataURL.CCSJ = format(formDataURL.CCSJ);
                  formData = formDataURL;
                });
                break;
              case "yysqb":
                formData = formModel.jsonData;
                formData.YYRQ = format(formData.YYRQ);
                break;
              case "ypcg":
                var busKey = self.bpmInst.busKey;
                let urlYPCG = _baseUrl + `/customizeProc/purRequisition/purRequisition/getJson.do?ids=${busKey}`;
                await axios.post(urlYPCG).then(res => {
                  // var formDataURL = upperJSONKey(res.data);
                  formData = res.data;
                });
                break;
              case "fwlc":
                formData = formModel.jsonData;
                formData.FWRQ = format(formData.FWRQ);
                break;
              default:
                formData = formModel.jsonData;
            }
          } else {
            // // 草稿判断
            // if(jsonDataCG!=""){
            // 	formModel.jsonData =
            // 	console.log(formData)
            // }
            formData = formModel.jsonData;
          }
          // formData = formModel.jsonData;
          //子表数据加上可以选择字段。
          for (var key in formData) {
            if (!key.startWith("SUB_")) continue;
            var data = formData[key];
            for (var i = 0; i < data.length; i++) {
              data[i].selected = false;
            }
          }
          formVm.title = self.title;
          formVm.readonly = readonly;
          formVm.data = formData;
          formVm.boDefId = formModel.boDefId;
          formVm.bpmTask = self.bpmTask;
          formVm.onload();
        }
      });
    });
  }
}
export function componentAjaxJson(conf, params, _self, callback) {
  var from = conf.from;
  var url = "";
  if (from == 'dic') {
    url = _baseUrl + "/mobile/component/ajax/getDicJsonsByKey.do";
  } else if (from == 'sql') {
    var sqlKey = conf.sql;
    url = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_" + sqlKey + ".do";
  } else if (from == 'url') {
    url = conf.url;
    url = url.replace("${ctxPath}", _baseUrl);
  }
  ajaxJson(url, params, _self, callback);
}
function ajaxJson(url, params, _self, callback) {
  _self.$ajax({
    url: url,
    method: 'post',
    params: params
  }).then(response => {
    var rtn = response.data;
    if (rtn instanceof Array) {
      callback(rtn);
    } else if (rtn.success) {
      callback(rtn.data);
    }
  }).catch(error => {
    console.log(error);
  });
}

/**
 * 对话框对象
 *
 */
var Dialog = {
  index: 100
};
Dialog.createDialogDiv = function (conf) {
  var div = document.createElement('div');
  div.id = "dialog_" + conf.id;
  div.className = "rx-dialog";
  div.style.zIndex = conf.index;
  return div;
};
/**
 * 根据别名打开对话框。
 * @param {Object} alias
 */
Dialog.open = function (wrapperId, alias, callback) {
  var app = document.getElementById(wrapperId);
  var dialog = document.getElementById("dialog_" + alias);
  this.index++;
  if (dialog) {
    dialog.style.zIndex = this.index;
  } else {
    dialog = this.createDialogDiv({
      id: alias,
      index: this.index
    });
    app.appendChild(dialog);
  }
  app.style.display = "block";
  dialog.style.display = "block";
  if (callback) {
    callback(alias);
  }
};

/**
 * 隐藏对话框。
 * @param {Object} alias
 */
Dialog.hidden = function (alias) {
  var dialog = document.getElementById("dialog_" + alias);
  dialog.style.display = "none";
};
Dialog.close = function (alias) {
  var dialog = document.getElementById("dialog_" + alias);
  var wrapper = document.getElementById("wrapper");
  wrapper.removeChild(dialog);
  wrapper.style.display = "none";
};
export { Dialog };

/**
 * 初始化微信配置。
 */
export function initWxConfig(vm) {
  var url = _baseUrl + "/initConfig.do";
  var curUrl = location.href.split("#")[0];
  var params = "url=" + curUrl;
  vm.$ajax.post(url, params).then(res => {
    var data = res.data;
    wx.config({
      beta: true,
      debug: false,
      // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: data.appId,
      // 必填，公众号的唯一标识
      timestamp: data.timestamp,
      // 必填，生成签名的时间戳
      nonceStr: data.nonceStr,
      // 必填，生成签名的随机串
      signature: data.signature,
      // 必填，签名，见附录1
      jsApiList: ['chooseImage', 'previewImage', 'uploadImage', 'downloadImage', 'previewFile', 'getLocation']
    });
    wx.error(function (res) {
      console.log("调用微信jsapi返回的状态:" + res.errMsg);
    });
  }).catch(function (error) {
    //vm.errorToast(error,1000);
    console.info(error);
  });
}

/**
 * 全局代码。
 */
export var globalMixin = {
  methods: {
    goBack: function () {
      this.isBack = true;
      router.go(-1);
    },
    goToIndex: function () {
      router.push({
        name: 'index'
      });
    },
    displayCount(count) {
      if (count > 99) {
        return "99+";
      }
      return count;
    },
    jump(name, params) {
      router.push({
        name: name,
        params: params
      });
    },
    successToast(msg, timeout, to, params) {
      this.toast("success", msg, timeout, to, params);
    },
    errorToast(msg, timeout, to, params) {
      this.toast("error", msg, timeout, to, params);
    },
    errorToastBack(msg) {
      this.toastBackSelf(msg);
    },
    toastBackSelf(msg) {
      this.$dialog.confirm({
        title: msg,
        opts: [{
          txt: '返回',
          color: "#25c4b5",
          callback: () => {
            this.goBack();
          }
        }]
      });
    },
    toast(icon, msg, timeout, to, params) {
      timeout = timeout || 500;
      var config = {
        mes: msg,
        timeout: timeout,
        icon: icon
      };
      if (to) {
        config.callback = () => {
          var toJson = {
            name: to
          };
          if (params) {
            toJson.params = params;
          }
          router.push(toJson);
        };
      }
      this.$dialog.toast(config);
    },
    displayMoney(n) {
      return RxUtil.toChineseMoney(n);
    },
    imageUrl(fileId) {
      if (fileId) {
        return this.getRealUrl() + "/sys/core/file/download/" + fileId + ".do";
      }
    },
    //处理文件类型的URL
    getRealUrl() {
      if (_customUrl) {
        var netWork = localStorage.getItem("netWork"); //取设置的网络
        return netWork;
      }
      return _baseUrl;
    },
    /**
     * 调用自定义sql。
     * alias：别名
     * params: json 数据。
     * callBack 回调函数 参数为data,
     * sync 是否同步，默认为异步。
     */
    doQuery(self, alias, params, callBack, sync) {
      sync = sync || false;
      var url = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_" + alias + ".do";
      if (sync) {
        var rtn = RxUtil.sendXmlHttpRequest(url, params);
        var json = eval("(" + rtn + ")");
        if (callBack) {
          callBack(json.data);
        }
      } else {
        var param = "";
        if (params) {
          param = RxUtil.serialJsonToForm({
            "params": JSON.stringify(params)
          });
        }
        self.$ajax.post(url, param).then(response => {
          var rtn = response.data;
          if (callBack) {
            callBack(rtn.data);
          }
        }).catch(function (error) {
          console.info(error);
        });
      }
    },
    getCurrentUser(call) {
      var user = this.$store.state.userModule.user;
      if (user.hasOwnProperty("osUser")) {
        if (call) {
          call(user);
          return;
        }
      }
      var url = _baseUrl + '/mobile/oa/personalInfo/getUser.do';
      this.$ajax.get(url).then(response => {
        if (response.data.hasOwnProperty("osUser")) {
          this.$store.commit('setUser', response.data);
          RxUtil.setCache("userID", response.data.osUser.identityId);
          RxUtil.setCache("userName", response.data.osUser.identityName);
          RxUtil.setCache("userNo", response.data.osUser.userNo);
          if (response.data.mainDepName == null) {
            RxUtil.setCache("mainGroupName", "");
          } else {
            RxUtil.setCache("mainGroupName", response.data.mainDepName);
          }
          if (call) {
            call(response.data);
          }
        }
      });
    },
    replaceUnicode(postData) {
      var jsonStr = JSON.stringify(postData);
      jsonStr = encodeURIComponent(jsonStr);
      return jsonStr;
    }
  }
};

//自己扩展-------------------------------------------------------
export function timesFnt(timestamp, Tstr) {
  let year = timestamp.getFullYear();
  const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
  const date = timestamp.getDate().toString().padStart(2, "0") + Tstr;
  const hours = timestamp.getHours() + ":";
  const minutes = timestamp.getMinutes();
  const seconds = timestamp.getSeconds();
  return year + "-" + month + "-" + date + hours + add0(minutes) + ":00";
}
export function add0(m) {
  return m < 10 ? '0' + m : m;
}
export function format(shijianchuo) {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours() + 1;
  var mm = time.getMinutes() + 1;
  var s = time.getSeconds() + 1;
  return y + '-' + add0(m) + '-' + add0(d);
}
export function formatHM(shijianchuo) {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours() + 1;
  var mm = time.getMinutes() + 1;
  var s = time.getSeconds() + 1;
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm);
}
export function formatHMT(shijianchuo) {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours() + 1;
  var mm = time.getMinutes() + 1;
  var s = time.getSeconds() + 1;
  return y + '-' + add0(m) + '-' + add0(d) + 'T' + add0(h) + ':' + add0(mm) + ":" + add0(s);
}
export function getTextByJs(arr) {
  var str = "";
  for (var i = 0; i < arr.length; i++) {
    str += arr[i].text + ",";
  }
  //去掉最后一个逗号(如果不需要去掉，就不用写)
  if (str.length > 0) {
    str = str.substr(0, str.length - 1);
  }
  return str;
}
export function getTextLst(arr) {
  var str = "";
  for (var i = 0; i < arr.length; i++) {
    str += arr[i] + ",";
  }
  //去掉最后一个逗号(如果不需要去掉，就不用写)
  if (str.length > 0) {
    str = str.substr(0, str.length - 1);
  }
  return str;
}
export function unique(arr) {
  // 根据唯一标识pkId来对数组进行过滤
  const res = new Map(); //定义常量 res,值为一个Map对象实例
  //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
  return arr.filter(arr => !res.has(arr.pkId) && res.set(arr.pkId, 1));
}
export function uniqueRypxz(arr) {
  // 根据唯一标识pkId来对数组进行过滤
  const res = new Map(); //定义常量 res,值为一个Map对象实例
  //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
  return arr.filter(arr => !res.has(arr.ID_) && res.set(arr.ID_, 1));
}
export function convertCurrency(money) {
  //汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟');
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆');
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘');
  //整数金额时后面跟的字符
  var cnInteger = '整';
  //整型完以后的单位
  var cnIntLast = '元';
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = '';
  //分离金额后用的数组，预定义
  var parts;
  if (money == '') {
    return '';
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return '';
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf('.') == -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1);
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
}

//改变数组对象key值
export function convertKey(arr, key) {
  let newArr = [];
  // item={ name:"kd", key:1 }
  arr.forEach((item, index) => {
    let newObj = {};
    for (var i = 0; i < key.length; i++) {
      newObj[key[i]] = item[Object.keys(item)[i]];
    }
    newArr.push(newObj);
  });
  return newArr;
}

//保留两位小数
export function keepTwoDecimalFull(num) {
  var result = parseFloat(num);
  if (isNaN(result)) {
    return "0";
  }
  result = Math.round(num * 100) / 100;
  var s_x = result.toString(); //将数字转换为字符串
  var pos_decimal = s_x.indexOf('.'); //小数点的索引值
  // 当整数时，pos_decimal=-1 自动补0
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += '.';
  }
  // 当数字的长度< 小数点索引+2时，补0
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0';
  }
  return s_x;
}
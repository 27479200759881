/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'qjhcjh-Module',
  data() {
    return {
      data1: [],
      data2: [],
      show: false,
      item: {}
    };
  },
  props: {
    instId: String,
    // busad:String,
    user_No: String
  },
  created() {
    this.getHistory();
  },
  methods: {
    showPopup(item) {
      this.item = item;
      this.show = true;
    },
    getHistory() {
      console.log('create...');
      var url = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + this.instId + "&userAccount=" + this.user_No;
      var pkid = '';
      this.$ajax.post(url).then(response => {
        var resData = response.data.data.bos[0];
        pkid = resData.pkId;
        var url2 = _baseUrl + "/equipmentMgmt/sbqjhcjh/qjHcJh/getJson.do?ids=" + pkid; //
        this.$ajax.post(url2).then(response => {
          this.data1 = response.data;
          var data_save = {};
          data_save.jhbh = this.data1.jhbh;
          data_save.jhzdrName = this.data1.jhzdrName;
          data_save.jhmc = this.data1.jhmc;
          data_save.jhrq = this.data1.jhrq;
          data_save.hcnr = this.data1.hcnr;
          data_save.hczq = this.data1.hczq;
          this.$store.commit('setErrorModuleData', data_save); // 把数据传出去。传给errorModuleData,可以搜定义，在审批界面可以取到。
        });

        var url3 = _baseUrl + "/equipmentMgmt/sbqjhcjhxq/qjHcJhList/listData.do?Q_JHBH_S_LK=" + pkid;
        this.$ajax.post(url3).then(res => {
          /*
          bh: "S4-999"
          createBy: "1448237583057317889"
          createTime: "2022-05-30 14:19:51"
          fzr: "周忠杰"
          hcnr: "5"
          id: "1531158430335373313"
          identifyLabel: "1531158430335373313"
          jczq: "4"
          jhbh: "1531158430280847361"
          jhrq: "2022-05-30 00:00:00"
          mc: "微机控制环刚度试验机"
          pkId: "1531158430335373313"
          tenantId: "1"
          xmrq: "2022-05-30 00:00:00"
          zt: "待审核"
          */
          this.data2 = res.data.data;
          this.$store.commit('setErrorModuleData2', this.data2); //传出去再我的待办里可能要用到。
        });
      });
    },

    dateToTime(cTime) {
      let date = new Date(cTime);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      date = year + '-' + month + '-' + day;
      //console.log(date); // 2018-10-09
      return date;
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import router from '@/router/index';
export default {
  name: "rx-img",
  data() {
    return {
      //files: [{fileId:"sd",fileName:"dd.jpg"},{fileId:"sd",fileName:"dd.xls"},{fileId:"sd",fileName:"dd.ppt"}],
      files: [],
      operators: [{
        label: '拍照',
        callback: () => {
          this.takePhoto();
        }
      }, {
        label: '从相册中选择',
        callback: () => {
          this.selectAttch();
        }
      }]
    };
  },
  props: {
    value: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isOffice(file) {
      var mediaType = ['pdf', 'txt', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
      if (file) {
        for (var i = 0, j = mediaType.length; i < j; i++) {
          if (file.fileName.toLowerCase().endsWith(mediaType[i])) {
            return true;
          }
        }
      }
      return false;
    },
    preViewOffice(file) {
      let src = this.getRealUrl() + "/scripts/PDFShow/web/viewer.html?file=" + this.getRealUrl() + "/sys/core/file/download/" + file.fileId + ".do";
      router.push({
        name: 'preViewViaPdf',
        params: {
          "src": src
        }
      });
    },
    getIcon(file) {
      if (!file) return;
      var fileName = file.fileName.toLowerCase();
      if (!fileName) return;
      var indexS = fileName.lastIndexOf(".");
      var extName = fileName.substr(indexS, fileName.length);
      var mediaType = ['.mp3', '.mp4', '.avi', '.rmvb'];
      var pptType = ['.ppt', '.pptx'];
      var pdfType = ['.pdf'];
      var docType = ['.doc', '.docx'];
      var xlsType = ['.xls', '.xlsx'];
      var txtType = ['.txt'];
      var picType = ['.jpg', '.png', 'jpeg'];
      if (mediaType.contains(extName)) {
        return 1;
      } else if (xlsType.contains(extName)) {
        return 2;
      } else if (docType.contains(extName)) {
        return 3;
      } else if (pptType.contains(extName)) {
        return 4;
      } else if (txtType.contains(extName)) {
        return 5;
      } else if (pdfType.contains(extName)) {
        return 6;
      } else if (picType.contains(extName)) {
        return 7;
      }
    },
    imageUrl(file) {
      return this.getRealUrl() + "/sys/core/file/download/" + file.fileId + ".do";
    },
    /**
     * 删除选测图片
     */
    removeSelect(idx) {
      this.files.splice(idx, 1);
    },
    /**
     * 选中图片。
     */
    checkFile(idx) {
      var file = this.files[idx];
      file.selected = !file.selected;
    },
    /**
     * 选择相册中的图片。
     */
    selectAttch() {
      console.info("===============开始选照片!===============");
      var _self = this;
      plus.gallery.pick(function (e) {
        var fileAry = [];
        for (var i = 0; i < e.files.length; i++) {
          var path = e.files[i];
          fileAry.push(path);
        }
        _self.upload(fileAry);
      }, function (e) {}, {
        filter: "image",
        multiple: true
      });
    },
    /**
     * 拍照
     */
    takePhoto() {
      console.info("===============开始拍照!===============");
      var _self = this;
      var cmr = plus.camera.getCamera();
      var fmt = cmr.supportedImageFormats[0];
      cmr.captureImage(function (path) {
        plus.io.resolveLocalFileSystemURL(path, function (entry) {
          var tmpPath = entry.toLocalURL();
          var fileAry = [];
          fileAry.push(tmpPath);
          _self.upload(fileAry);
        });
      }, function (error) {}, {
        format: fmt
      });
    },
    recordVideo() {
      console.info("===============开始录像!===============");
      var _self = this;
      var cmr = plus.camera.getCamera();
      var res = cmr.supportedVideoResolutions[0];
      var fmt = cmr.supportedVideoFormats[0];
      cmr.startVideoCapture(function (path) {
        plus.io.resolveLocalFileSystemURL(path, function (entry) {
          var tmpPath = entry.toLocalURL();
          var fileAry = [];
          fileAry.push(tmpPath);
          _self.upload(fileAry);
        });
      }, function (error) {}, {
        resolution: res,
        format: fmt
      });
    },
    initValue(val) {
      if (!val) return [];
      var fileAry = JSON.parse(val);
      for (var i = 0; i < fileAry.length; i++) {
        var file = fileAry[i];
        file.selected = false;
      }
      this.files = fileAry;
    },
    chooseType() {
      document.getElementById('upload_file').click();
    },
    fileChange(el) {
      console.info("this.files");
      console.info(this.files);
      if (!el.target.files[0].size) return;
      this.fileList(el.target);
      el.target.value = '';
    },
    fileList(fileList) {
      let files = fileList.files;
      for (let i = 0; i < files.length; i++) {
        //判断是否为文件夹
        if (files[i].type != '') {
          this.fileAdd(files[i]);
        } else {
          //文件夹处理
          this.folders(fileList.items[i]);
        }
      }
    },
    //文件夹处理
    folders(files) {
      let _this = this;
      //判断是否为原生file
      if (files.kind) {
        files = files.webkitGetAsEntry();
      }
      files.createReader().readEntries(function (file) {
        for (let i = 0; i < file.length; i++) {
          if (file[i].isFile) {
            _this.foldersAdd(file[i]);
          } else {
            _this.folders(file[i]);
          }
        }
      });
    },
    foldersAdd(entry) {
      let _this = this;
      entry.file(function (file) {
        _this.fileAdd(file);
      });
    },
    fileAdd(file) {
      //总大小
      //判断是否为图片文件
      if (file.type.indexOf('image') == -1) {
        this.$dialog.toast({
          mes: '请选择图片文件'
        });
      } else {
        let formdata = new FormData();
        formdata.append('file', file);
        var url = this.getRealUrl() + "/sys/core/file/upload.do";
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        var _self = this;
        this.$dialog.loading.open('正在上传...');
        this.$uploadJson.post(url, formdata, config).then(response => {
          _self.$dialog.loading.close();
          if (response.status == 200) {
            _self.handFiles(response.data);
          }
        }).catch(error => {
          _self.$dialog.loading.close();
          this.errorToast('上传图片出错！', 1000);
        });
      }
    },
    handFiles(json) {
      var aryFiles = json.data;
      for (var i = 0; i < aryFiles.length; i++) {
        var file = aryFiles[i];
        this.files.push({
          fileId: file.fileId,
          fileName: file.fileName,
          selected: false
        });
      }
    }
  },
  mounted() {
    this.initValue(this.value);
  },
  watch: {
    files: {
      handler(newValue, oldValue) {
        var str = JSON.stringify(newValue);
        this.$emit('input', str);
      },
      deep: true
    },
    value: function (val, oldVal) {
      if (val != oldVal) {
        this.initValue(val);
      }
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { CellGroup, CellItem, Toast } from 'vue-ydui';
import router from '../router/index';
import { sha256_digest } from "@/assets/sha256.js";
import { RxUtil } from "@/assets/util.js";
import Tab from "vux/src/components/tab/tab.vue";
import TabItem from "vux/src/components/tab/tab-item.vue";
import Swiper from "vux/src/components/swiper/swiper.vue";
import SwiperItem from "vux/src/components/swiper/swiper-item.vue";
import XDialog from "vux/src/components/x-dialog/index.vue";
Vue.component(Tab.name, Tab);
Vue.component(TabItem.name, TabItem);
Vue.component(Swiper.name, Swiper);
Vue.component(SwiperItem.name, SwiperItem);
Vue.component(XDialog.name, XDialog);
export default {
  data() {
    return {
      user: {},
      index: 0,
      showSetDialog: false,
      netWork: "",
      editUrl: false
    };
  },
  computed: {
    showBar: function () {
      //这里不能用箭头函数
      return this.$store.state.pageModule.keyboard;
    }
  },
  props: {},
  watch: {},
  created() {
    //运行设置网络
    if (_customUrl) {
      this.editUrl = true;
      var netWork = localStorage.getItem("netWork");
      console.log(netWork);
      if (!netWork) {
        this.showSetDialog = true;
      }
    }
    var source = this.$route.params.source;
    if (source == "editPwd") {
      //修改密码来到login
      if (!RxUtil.getCache("token")) return;
      var aryToken = RxUtil.getCache("token").split("#");
      this.user.username = aryToken[0];
      this.user.password = "";
      var tokenCache = aryToken[0] + "#" + "";
      RxUtil.setCache("token", tokenCache);
    } else if (source == "loginOut") {
      //退出登录来到login
      if (!RxUtil.getCache("token")) return;
      var aryToken = RxUtil.getCache("token").split("#");
      this.user.username = aryToken[0];
      this.user.password = "";
      RxUtil.cleanCache("token");
    } else {
      //从界面进来的话，直接登录
      this.autoLogin();
    }

    //清除tab\邮件文件夹数据
    this.$store.commit('cleanFolderData');
  },
  methods: {
    toScanCode() {
      router.push({
        name: 'qrcode'
      });
    },
    toUploadAndCamera() {
      router.push({
        name: 'uploadCamera'
      });
    },
    autoLogin() {
      var token = RxUtil.getCache("token");
      if (!token) return;
      var url = _baseUrl + "/login.do";
      var aryToken = token.split("#");
      if (!aryToken[1]) return; //密码为空
      var isCas = _isCasLogin ? 1 : 0;
      var params = "acc=" + aryToken[0] + "&pd=" + aryToken[1] + "&from=mobile&isCas=" + isCas;
      this.$dialog.loading.open('自动登录中,请稍候...');
      this.$ajax.post(url, params).then(response => {
        globalVm.$dialog.loading.close();
        var rtn = response.data;
        //不成功则跳转到登录页面。
        if (rtn.success) {
          router.push({
            name: "index"
          });
        }
      }).catch(function (error) {
        globalVm.errorToast('登录失败！' + error.message, '1000');
        globalVm.$dialog.loading.close();
      });
    },
    login: function () {
      //设置CID tag
      RxUtil.setCache("summitCidTag", "0");
      this.user.remberMe = this.user.remberMe ? 1 : 0;
      var url = _baseUrl + "/login.do";
      var isCas = _isCasLogin ? 1 : 0;
      if (!this.user.username || !this.user.password) {
        globalVm.errorToast('请输入账号或密码！', "500");
      }
      ;
      var pwd = _isCasLogin ? this.user.password : sha256_digest(this.user.password);
      var params = "acc=" + this.user.username + "&pd=" + pwd + "&remberMe=" + this.user.remberMe + "&from=mobile&isCas=" + isCas;

      //显示登录
      globalVm.$dialog.loading.open('正在登录,请稍候...');
      this.$ajax.post(url, params).then(response => {
        //关闭
        globalVm.$dialog.loading.close();
        var rtn = response.data;
        if (rtn.success) {
          var tokenCache = this.user.username + "#" + pwd;
          RxUtil.setCache("token", tokenCache);
          this.successToast('登录成功!', "500", "index");
        } else {
          globalVm.errorToast('登录失败!' + rtn.message);
        }
      }).catch(function (error) {
        globalVm.errorToast(error);
        globalVm.$dialog.loading.close();
        console.log(error);
      });
      // console.log(this.user.remberMe)
    },

    setDialogShow() {
      var netWork = localStorage.getItem("netWork");
      this.netWork = netWork;
      this.showSetDialog = true;
    },
    setConfirm() {
      localStorage.setItem("netWork", this.netWork);
      this.showSetDialog = false;
    },
    setCancel() {
      this.showSetDialog = false;
    }
  },
  components: {
    CellGroup,
    CellItem
  }
};
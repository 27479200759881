/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login';
import TaskDetail from '@/components/bpm/TaskDetail';
Vue.use(Router);
export default new Router({
  routes: [{
    path: '/testhome',
    name: 'home',
    component: () => import('../view/home.vue')
  }, {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layer: 0
    }
  }, {
    path: '/',
    name: 'root',
    redirect: "/index",
    meta: {
      layer: 1
    }
  }, {
    //主页
    path: '/index',
    name: 'index',
    component: () => import('@/components/IndexRoute'),
    meta: {
      layer: 1,
      keepAlive: true
    }
  }, {
    path: '/myToDo',
    name: 'myToDo',
    component: () => import('@/components/bpm/MyTask/MyTask'),
    meta: {
      layer: 2
    }
  }, {
    path: '/solTask/:solId',
    name: 'solTask',
    component: () => import('@/components/bpm/MyTask/SolTask'),
    meta: {
      layer: 2
    }
  }, {
    path: '/getInstInfo/:busKey/:instId_/:instId/:type/:treeName',
    name: 'getInstInfo',
    component: () => import('@/components/bpm/GetInstInfo'),
    meta: {
      layer: 3
    }
  }, {
    path: '/task/:taskId/:instId/:bpmTypeName',
    name: 'task',
    // component: () => import('@/components/bpm/TaskDetail'),
    component: TaskDetail,
    meta: {
      layer: 3
    }
  }, {
    path: '/img/:type/:id',
    name: 'procImage',
    component: () => import('@/components/bpm/FlowImage'),
    meta: {
      layer: 4
    }
  }, {
    path: '/personalInfo',
    name: 'personalInfo',
    component: () => import('@/components/oa/PersonalInfo'),
    meta: {
      layer: 2
    }
  }, {
    path: '/map',
    name: 'map',
    component: () => import('@/components/common/Map')
  }, {
    path: '/signHistory',
    name: 'signHistory',
    component: () => import('@/components/oa/SignHistory'),
    meta: {
      layer: 3
    }
  }, {
    path: '/editPassword',
    name: 'editPassword',
    component: () => import('@/components/oa/EditPassword'),
    meta: {
      layer: 2
    }
  }, {
    path: '/about',
    name: 'about',
    component: () => import('@/components/oa/About'),
    meta: {
      layer: 2
    }
  }, {
    //公告详情
    path: '/insNewsDetail',
    name: 'insNewsDetail',
    component: () => import('@/components/oa/InsNewsDetail'),
    meta: {
      layer: 2
    }
  }, {
    //消息详情
    path: '/msgDetail',
    name: 'msgDetail',
    component: () => import('@/components/oa/MsgDetail'),
    meta: {
      layer: 2
    }
  }, {
    //消息管理
    path: '/messageBox',
    name: 'messageBox',
    component: () => import('@/components/oa/MessageBox'),
    meta: {
      layer: 2
    }
  }, {
    //内部邮件管理
    path: '/mailBox',
    name: 'mailBox',
    component: () => import('@/components/oa/MailBox'),
    meta: {
      layer: 2
    }
  }, {
    //回复邮件管理
    path: '/innerMailDetail',
    name: 'innerMailDetail',
    component: () => import('@/components/oa/InnerMailDetail'),
    meta: {
      layer: 3
    }
  }, {
    //用户列表
    path: '/sysUser',
    name: 'sysUser',
    component: () => import('@/components/common/SysUser')
  }, {
    //发邮件
    path: '/sendMail',
    name: 'sendMail',
    component: () => import('@/components/oa/SendMail'),
    meta: {
      layer: 3
    }
  }, {
    //转发、回复邮件
    path: '/replyTransmitMail',
    name: 'replyTransmitMail',
    component: () => import('@/components/oa/ReplyTransmitMail')
  },
  // 扫描二维码
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('@/components/oa/qrCode')
  }, {
    path: '/uploadCamera',
    name: 'uploadCamera',
    component: () => import('@/components/oa/uploadCamera')
  }, {
    //流程审批
    path: '/approve',
    name: 'approve',
    component: () => import('@/components/bpm/approve'),
    meta: {
      layer: 4
    }
  }, {
    //驳回
    path: '/reject',
    name: 'reject',
    component: () => import('@/components/bpm/reject'),
    meta: {
      layer: 4
    }
  }, {
    //流程审批
    path: '/commu/:taskId',
    name: 'commu',
    component: () => import('@/components/bpm/Commu'),
    meta: {
      layer: 4
    }
  }, {
    //流程审批
    path: '/trans/:taskId',
    name: 'trans',
    component: () => import('@/components/bpm/Trans'),
    meta: {
      layer: 4
    }
  }, {
    path: '/mySolutions',
    name: 'mySolutions',
    component: () => import('@/components/bpm/MySolutions'),
    meta: {
      layer: 2
    }
  },
  // {
  //   path: '/startForm/:solId/:instId',
  //   name: 'startForm',
  //   component: () => import('@/components/bpm/StartForm'),
  //   meta: {
  //     layer: 3,
  //   }
  // },

  {
    path: '/startFlow',
    name: 'startFlow',
    component: () => import('@/components/bpm/StartFlow')
  }, {
    path: '/myDrafts',
    name: 'myDrafts',
    component: () => import('@/components/bpm/MyDraft'),
    meta: {
      layer: 2
    }
  }, {
    //发送内部消息
    path: '/sendMsg',
    name: 'sendMsg',
    component: () => import('@/components/oa/SendMsg'),
    meta: {
      layer: 3
    }
  }, {
    path: '/bpmMessageBoard',
    name: 'bpmMessageBoard',
    component: () => import('@/components/bpm/BpmMessageBoard'),
    meta: {
      layer: 4
    }
  }, {
    path: '/customList/:alias',
    name: 'customList',
    component: () => import('@/components/list/CustomList.vue'),
    meta: {
      layer: 3
    }
  }, {
    path: '/CustomList_Wx/:alias/:openId',
    name: 'CustomList_Wx',
    component: () => import('@/components/list/CustomList_Wx.vue'),
    meta: {
      layer: 3
    }
  }, {
    path: '/customForm/:alias/:addAlias/:detailAlias/:id/:readonly/:type/:relField',
    name: 'customForm',
    component: () => import('@/components/list/CustomForm')
  }, {
    path: '/CustomForm_Wx/:boKey/:openId',
    name: 'CustomForm_Wx',
    component: () => import('@/components/list/CustomForm_Wx')
  }, {
    path: '/myCustomList',
    name: 'myCustomList',
    component: () => import('@/components/list/MyCustomList'),
    meta: {
      layer: 2
    }
  }, {
    path: '/myInst',
    name: 'myInst',
    component: () => import('@/components/bpm/MyProcess/MyProcess'),
    meta: {
      layer: 2
    }
  }, {
    path: '/success',
    name: 'success',
    component: () => import('@/components/common/Success')
  }, {
    path: '/preViewViaPdf/:src',
    name: 'preViewViaPdf',
    component: () => import('@/components/common/preViewViaPdf')
  }, {
    path: '/ReplyCommu/:taskId',
    name: 'ReplyCommu',
    component: () => import('@/components/bpm/ReplyCommu'),
    meta: {
      layer: 4
    }
  }, {
    path: '/RevokeCommu/:taskId',
    name: 'RevokeCommu',
    component: () => import('@/components/bpm/RevokeCommu'),
    meta: {
      layer: 4
    }
  }, {
    path: '/myApply',
    name: 'myApply',
    component: () => import('@/components/bpm/MyInst/MyApply'),
    meta: {
      layer: 4
    }
  }, {
    path: '/myAttend',
    name: 'myAttend',
    component: () => import('@/components/bpm/MyInst/MyAttend'),
    meta: {
      layer: 4
    }
  }, {
    path: '/myCopy',
    name: 'myCopy',
    component: () => import('@/components/bpm/MyInst/MyCopy'),
    meta: {
      layer: 4
    }
  }, {
    path: '/myCctome',
    name: 'myCctome',
    component: () => import('@/components/bpm/MyInst/MyCctome'),
    meta: {
      layer: 4
    }
  }, {
    path: '/myMsg',
    name: 'myMsg',
    component: () => import('@/components/bpm/MyInst/MyMsg'),
    meta: {
      layer: 4
    }
  }, {
    path: '/myAgents',
    name: 'myAgents',
    component: () => import('@/components/bpm/MyInst/MyAgents'),
    meta: {
      layer: 4
    }
  }, {
    path: '/noauth',
    name: 'noauth',
    component: () => import('@/components/oa/NoAuth'),
    meta: {
      layer: 4
    }
  },
  //------------------------------------招标----------------------------------------
  //业务项目
  {
    path: '/yewhome',
    name: 'yewhome',
    component: () => import('../view/zhaobiao/yewu/yewhome.vue'),
    children: [{
      path: '/ywProject',
      name: 'ywProject',
      component: () => import('../view/zhaobiao/yewu/page/ywProject.vue'),
      meta: {
        isShowTabBar: true
      }
    }, {
      path: '/ywTrack',
      name: 'ywTrack',
      component: () => import('../view/zhaobiao/yewu/page/ywTrack.vue')
    }, {
      path: '/ywNote',
      name: 'ywNote',
      component: () => import('../view/zhaobiao/yewu/page/ywNote.vue')
    }]
  }, {
    //业务项目详细信息
    path: '/ywInfo',
    name: 'ywInfo',
    component: () => import('../view/zhaobiao/yewu/page/formInfo/ywInfo.vue')
  }, {
    //业务跟踪
    path: '/ywTrackForm',
    name: 'ywTrackForm',
    component: () => import('../view/zhaobiao/yewu/page/form/ywTrackForm.vue')
  }, {
    //业务跟踪详细信息
    path: '/ywTrackInfo',
    name: 'ywTrackInfo',
    component: () => import('../view/zhaobiao/yewu/page/formInfo/ywTrackInfo.vue')
  }, {
    //跟踪批注
    path: '/ywNoteFrom',
    name: 'ywNoteFrom ',
    component: () => import('../view/zhaobiao/yewu/page/form/ywNoteFrom.vue')
  }, {
    //跟踪批注
    path: '/ywNoteInfo',
    name: 'ywNoteInfo ',
    component: () => import('../view/zhaobiao/yewu/page/formInfo/ywNoteInfo.vue')
  },
  //招标管理
  {
    path: '/ywLIst',
    name: 'ywLIst ',
    component: () => import('../view/zhaobiao/zbguanli/ywLIst.vue')
  },
  //--------------------流程发起------------------------
  {
    path: "/lcfqFwlc/:solId/:busad",
    name: "lcfqFwlc",
    component: () => import('../view/lcfq/page/lcfqFwlc.vue')
  }, {
    path: "/lcfqSjqj/:solId/:instId",
    name: "lcfqSjqj",
    component: () => import('../view/lcfq/page/lcfqSjqj.vue')
  }, {
    path: "/lcfqPtqj/:solId/:instId",
    name: "lcfqPtqj",
    component: () => import('../view/lcfq/page/lcfqPtqj.vue')
  }, {
    path: "/lcfqNxqj/:solId/:busad",
    name: "lcfqNxqj",
    component: () => import('../view/lcfq/page/lcfqNxqj.vue')
  }, {
    path: "/lcfRyrz/:solId/:busad",
    name: "lcfRyrz",
    component: () => import('../view/lcfq/page/lcfRyrz.vue')
  }, {
    path: "/lcfqRylz/:solId/:busad",
    name: "lcfqRylz",
    component: () => import('../view/lcfq/page/lcfqRylz.vue')
  }, {
    path: "/lcfqRydd/:solId/:busad",
    name: "lcfqRydd",
    component: () => import('../view/lcfq/page/lcfqRydd.vue')
  }, {
    path: "/lcfqYpcg/:solId/:instId",
    name: "lcfqYpcg",
    component: () => import('../view/lcfq/page/lcfqYpcg.vue')
  }, {
    path: '/lcfqFwhgypcg/:solId/:instId',
    name: 'lcfqFwhgypcg',
    component: () => import('../view/lcfq/page/lcfqFwhgypcg.vue')
  }, {
    path: '/lcfqHys/:solId/:instId',
    name: 'lcfqHys',
    component: () => import('../view/lcfq/page/lcfqHys.vue')
  }, {
    path: "/lcfqGsjl/:solId/:instId",
    name: "lcfqGsjl",
    component: () => import('../view/lcfq/page/lcfqGsjl.vue')
  }, {
    path: "/lcfqGwfw/:solId/:instId",
    name: "lcfqGwfw",
    component: () => import('../view/lcfq/page/lcfqGwfw.vue')
  }, {
    path: "/lcfqCwggsq/:solId/:instId",
    name: "lcfqCwggsq",
    component: () => import('../view/lcfq/page/lcfqCwggsq.vue')
  },
  //用印管理
  {
    path: "/lcfqYysp/:solId/:instId",
    name: "lcfqYysp",
    component: () => import('../view/lcfq/page/yzgl/lcfqYysp.vue')
  }, {
    path: "/lcfqYyqy/:solId/:instId",
    name: "lcfqYyqy",
    component: () => import('../view/lcfq/page/yzgl/lcfqYyqy.vue')
  }, {
    path: "/lcfqYyyj/:solId/:instId",
    name: "lcfqYyyj",
    component: () => import('../view/lcfq/page/yzgl/lcfqYyyj.vue')
  }, {
    path: "/lcfqYzfc/:solId/:instId",
    name: "lcfqYzfc",
    component: () => import('../view/lcfq/page/yzgl/lcfqYzfc')
  }, {
    path: "/lcfqSbcg/:solId/:instId",
    name: "lcfqSbcg",
    component: () => import('../view/lcfq/page/sbgl/lcfqSbcg.vue')
  }, {
    path: "/lcfqQjhc/:solId/:instId/:busad",
    name: "lcfqQjhc",
    component: () => import('../view/lcfq/page/sbgl/lcfqQjhc.vue')
  }, {
    path: "/lcfqZgsg/:solId/:busad",
    name: "lcfqZgsg",
    component: () => import('../view/lcfq/page/sbgl/lcfqZgsg.vue')
  },
  //质量体系//组织结构
  {
    path: "/lcfqRypx/:solId/:instId",
    name: "lcfqRypx",
    component: () => import('../view/lcfq/page/zzjg/lcfqRypx.vue')
  }, {
    path: "/lcfqNljd/:solId/:instId",
    name: "lcfqNljd",
    component: () => import('../view/lcfq/page/zzjg/lcfqNljd.vue')
  }, {
    path: "/lcfqRypxz/:solId/:instId",
    name: "lcfqRypxz",
    component: () => import('../view/lcfq/page/zzjg/lcfqRypxz.vue')
  }, {
    path: "/lcfqRyjdz/:solId/:instId",
    name: "lcfqRyjdz",
    component: () => import('../view/lcfq/page/zzjg/lcfqRyjdz.vue')
  }, {
    path: "/lcfqGjjh/:solId/:instId",
    name: "lcfqGjjh",
    component: () => import('../view/lcfq/page/jzcs/lcfqGjjh.vue')
  }, {
    path: "/lcfqGjzjh/:solId/:instId",
    name: "lcfqGjzjh",
    component: () => import('../view/lcfq/page/jzcs/lcfqGjzjh.vue')
  }, {
    path: "/lcfqFxjh/:solId/:instId",
    name: "lcfqFxjh",
    component: () => import('../view/lcfq/page/yfcs/lcfqFxjh.vue')
  }, {
    path: "/lcfqFxzjh/:solId/:instId",
    name: "lcfqFxzjh",
    component: () => import('../view/lcfq/page/yfcs/lcfqFxzjh.vue')
  }, {
    path: "/lcfqXhpcg/:solId/:instId",
    name: "lcfqXhpcg",
    component: () => import('../view/lcfq/page/spcg/lcfqXhpcg.vue')
  }, {
    path: "/lcfqSbsq/:solId/:instId",
    name: "lcfqSbsq",
    component: () => import('../view/lcfq/page/lcfqSbsq.vue')
  }, {
    path: "/lcfqHksq/:solId/:instId",
    name: "lcfqHksq",
    component: () => import('../view/lcfq/page/lcfqHksq.vue')
  }, {
    path: "/lcfqFw/:solId/:instId",
    name: "lcfqFw",
    component: () => import('../view/lcfq/page/lcfqFw.vue')
  }, {
    path: "/lcfqHtsp/:solId/:instId",
    name: "lcfqHtsp",
    component: () => import('../view/lcfq/page/lcfqHtsp.vue')
  }, {
    path: "/lcfqPcsq/:solId/:instId",
    name: "lcfqPcsq",
    component: () => import('../view/lcfq/page/lcfqPcsq.vue')
  }, {
    path: "/lcfqBywx/:solId/:instId",
    name: "lcfqBywx",
    component: () => import('../view/lcfq/page/cllc/lcfqBywx.vue')
  }, {
    path: "/lcfqMbhzjl/:solId/:instId",
    name: "lcfqMbhzjl",
    component: () => import('../view/lcfq/page/hjkzmb/lcfqMbhzjl.vue')
  }, {
    path: "/lcfqYxjh/:solId/:instId",
    name: "lcfqYxjh",
    component: () => import('../view/lcfq/page/jkjg/lcfqYxjh.vue')
  }, {
    path: "/lcfqJkzjh/:solId/:instId",
    name: "lcfqJkzjh",
    component: () => import('../view/lcfq/page/jkjg/lcfqJkzjh.vue')
  }, {
    path: "/lcfqWjgs",
    name: "lcfqWjgs",
    component: () => import('../view/lcfq/page/lcfqWjgs.vue')
  },
  //测试
  {
    path: "/MyclRadio",
    name: "MyclRadio",
    component: () => import('../view/common/cllcRadio/MyclRadio.vue')
  }, {
    path: "/FwFwbk",
    name: "FwFwbk",
    component: () => import('../view/common/fw/FwFwbk.vue')
  },
  //---------我的待办---------
  {
    path: "/Ypcg",
    name: "Ypcg",
    component: () => import('../view/form/Ypcg.vue')
  },
  //-----------------------------------------------------------------------------------------------
  {
    //招标管理主页
    path: '/zbInfoHom',
    name: 'zbInfoHom ',
    component: () => import('../view/zhaobiao/zbguanli/zblc/zbInfoHom.vue'),
    redirect: "/zyInfo",
    children: [{
      path: '/zyInfo',
      name: 'zyInfo',
      component: () => import('../view/zhaobiao/zbguanli/zblc/page/zyInfo.vue'),
      meta: {
        isShowTabBar: true
      }
    }, {
      path: '/hkh',
      name: 'hkh',
      component: () => import('../view/zhaobiao/zbguanli/zblc/page/hkh.vue')
    }, {
      path: '/bszz',
      name: 'bszz',
      component: () => import('../view/zhaobiao/zbguanli/zblc/page/bszz.vue')
    }, {
      path: '/tbjg',
      name: 'tbjg',
      component: () => import('../view/zhaobiao/zbguanli/zblc/page/tbjg.vue')
    }]
  }]
});
import { render, staticRenderFns } from "./RxDate.vue?vue&type=template&id=51c88bb8&scoped=true&"
import script from "./RxDate.vue?vue&type=script&lang=js&"
export * from "./RxDate.vue?vue&type=script&lang=js&"
import style0 from "./RxDate.vue?vue&type=style&index=0&id=51c88bb8&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c88bb8",
  null
  
)

export default component.exports
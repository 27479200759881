/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'list-more',
  props: {
    total: [Number],
    list: [Array]
  },
  data() {
    return {
      count: 0,
      listLen: 0
    };
  },
  methods: {
    loadList() {
      this.$emit("loadMore");
      this.$emit("loadmore");
    }
  },
  watch: {
    list: function (val, oldVal) {
      this.listLen = val.length;
    },
    total: function (val) {
      this.count = val;
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import '../static/css/font.css';
import '../static/css/app.css';
//vant
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

//手机调试插件
// import VConsole from 'vconsole';
// var vConsole = new VConsole();

//文本框
// import VueQuillEditor from 'vue-quill-editor'
// // require styles
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor)

import VueScroller from 'vue-scroller';
Vue.use(VueScroller);
var Promise = require('promise');
import Vuex from 'vuex';
Vue.use(Vuex);
Vue.config.productionTip = false;
import YDUI from 'vue-ydui';
import 'vue-ydui/dist/ydui.flexible.js';
import axios from 'axios';
import 'vue-ydui/dist/ydui.base.css';
import 'vue-ydui/dist/ydui.rem.css';
import FastClick from 'fastclick';
FastClick.attach(document.body);
import { RxUtil } from "@/assets/util.js";
import jsonp from 'axios-jsonp';
import '@/assets/iconfont.js';
//import '@/styles/base.less';
//使用vuex
import { bpmModule } from '@/store/bpm-store.js';
import { mailModule } from '@/store/mail-store.js';
import { pageModule } from '@/store/page-store.js';
import { searchModule } from '@/store/search-store.js';
import { userModule } from '@/store/user-store.js';
// import {checkList} from "@/store/check-list.js";

import { globalMixin } from '@/assets/app.js';
import RxLayout from '@/components/common/layout/rx-layout';
Vue.component(RxLayout.name, RxLayout);
import { Dialog } from 'vant';
Vue.use(Dialog);
import DatetimePlugin from "vux/src/plugins/datetime/index.js";
Vue.use(DatetimePlugin);
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    "inline": false,
    //启用 inline 模式 默认false
    "button": false,
    //显示右上角关闭按钮 默认true
    "navbar": false,
    //显示缩略图导航 默认true
    "title": false,
    //显示当前图片的标题 默认true
    "toolbar": false,
    // 显示工具栏 默认true
    "tooltip": false,
    // 显示缩放百分比 默认true
    "movable": false,
    //图片是否可移动 默认true
    "zoomable": false,
    //图片是否可缩放 默认true
    "rotatable": false,
    //图片是否可旋转 默认true
    "scalable": false,
    //图片是否可翻转 默认true
    "transition": false,
    //使用css3过度 默认true
    "fullscreen": false,
    //是否全屏 默认true
    "keyboard": false,
    //是否支持键盘 默认true
    "url": "data-source" //设置大图片的URL
  }
});

import moment from 'moment'; //导入文件
Vue.prototype.$moment = moment; //赋值使用
moment.locale('zh-cn');

//使用模块化
const store = new Vuex.Store({
  modules: {
    bpmModule: bpmModule,
    mailModule: mailModule,
    pageModule: pageModule,
    searchModule: searchModule,
    userModule: userModule
    // checkList:checkList
  }
});

//页面加载交互
store.registerModule('loading', {
  state: {
    isLoading: false
  },
  mutations: {
    updateLoadingStatus(state, payload) {
      state.isLoading = payload.isLoading;
    }
  }
});
router.beforeEach(function (to, from, next) {
  store.commit('updateLoadingStatus', {
    isLoading: true
  });
  next();
});
router.afterEach(function (to) {
  store.commit('updateLoadingStatus', {
    isLoading: false
  });
});
const axiosInstance = axios.create({
  timeout: 60000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest'
  }
});
const axiosInstanceJson = axios.create({
  timeout: 60000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
});
const axiosJsonp = axios.create({
  timeout: 60000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  adapter: jsonp
});
const axiosUploadJson = axios.create({
  withCredentials: true
});

// global._baseUrl = "http://www.desunyun.com:7020/xzoa";
global._baseUrl = "/xzoa"; //本地在用       //http://61.177.215.68:7020   config里index.js进行的代理， 打包直接把这里换掉
//global._baseUrl = "http://61.177.215.68:7020/xzoa";//打包
// global._baseUrl = "http://47.100.165.67:8084/xzoa";//彭工打包
/**
 npm run build  config/index.js里 outPath="E:\\appbao\\xzapp";
 xuzhou-app工程里只留下 manifest.json和unpackage，  把xzapp里打包的static,和index.html拷贝进去
 云打包   证书密码：963257
**/

//global._baseUrl="http://linc.vipgz4.idcfengye.com/oa";

//用户自定义路径  如果为true 所有的请求都会取保存到本地缓存的路径
global._customUrl = false;
global._isCasLogin = false;
global._openMsgPush = false;
axiosInstance.interceptors.request.use(replaceNetWork);
axiosInstanceJson.interceptors.request.use(replaceNetWork);
function replaceNetWork(config) {
  if (_customUrl) {
    var url = config.url;
    var netWork = localStorage.getItem("netWork");
    if (!netWork) {
      router.push({
        name: "login"
      });
      return;
    }
    config.url = url.replace(_baseUrl, netWork);
  }
  return config;
}
function interceptAjax(res) {
  // debugger
  var tmp = res.headers["exceptionaction"];
  console.log(tmp);
  if (!tmp) return res;
  //wxOauth();
  //系统已经登录。
  var token = RxUtil.getCache("token");
  if (!token) {
    router.push({
      name: "login"
    });
    return;
  }
  var aryToken = token.split("#");
  var isCas = _isCasLogin ? 1 : 0;
  var params = "acc=" + aryToken[0] + "&pd=" + aryToken[1] + "&from=mobile&isCas=" + isCas;
  var url = _baseUrl + "/login.do";
  globalVm.$ajax.post(url, params).then(response => {
    var rtn = response.data;
    //不成功则跳转到登录页面。
    if (!rtn.success) {
      router.push({
        name: "login"
      });
    } else {
      router.go(0);
    }
  }).catch(function (error) {
    router.push({
      name: "login"
    });
  });
}
function wxOauth() {
  var url = _baseUrl + "/wxOauth.do?url=" + window.location.href;
  window.open(url, "_self");
}
axiosInstance.interceptors.response.use(res => interceptAjax(res));
axiosInstanceJson.interceptors.response.use(res => interceptAjax(res));
Vue.prototype.$ajax = axiosInstance;
Vue.prototype.$ajaxJson = axiosInstanceJson;
Vue.prototype.$jsonp = axiosJsonp;
Vue.prototype.$uploadJson = axiosUploadJson;

//全局代码共享
Vue.mixin(globalMixin);
Vue.use(YDUI);

//FastClick的ios点击穿透解决方案
FastClick.prototype.focus = function (targetElement) {
  let length;
  if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
};
var curPath = "";
global.globalVm = new Vue({
  el: '#app',
  router,
  render: function (h) {
    return h(App);
  },
  store,
  watch: {
    '$route'(to, from) {
      globalVm.$dialog.loading.close();
      curPath = to.name;
    }
  }
});
document.addEventListener("plusready", function () {
  if (!window.plus) return;
  plus.key.addEventListener('backbutton', function () {
    console.info("curPath:" + curPath);
    var isLogin = curPath == "index" || curPath == "login" || curPath == "insNews" || curPath == "personalAddrBookIndex" || curPath == "workSpace" || curPath == "myInfo";
    if (isLogin) {
      // 事件处理
      plus.nativeUI.confirm("退出程序？", function (event) {
        if (event.index) {
          plus.runtime.quit();
        }
      }, {
        "buttons": ["取消", "确定"]
      });
    } else {
      router.go(-1);
    }
  }, false);
  if (_openMsgPush) {
    // 添加监听从系统消息中心点击某条消息启动应用事件
    plus.push.addEventListener("click", function (msg) {
      //console.info("推送click：" + JSON.stringify(msg));
      // 分析msg.payload处理业务逻辑
      if (typeof msg.payload == "string") {
        var pushJson = JSON.parse(msg.payload);
      } else if (typeof msg.payload == "object") {
        var pushJson = msg.payload;
      }
      var actionJson = {
        "approve": "myToDo",
        "agent": "myToDo",
        "": "index",
        "bpmMessageBoard": "bpmMessageBoard"
      };
      var goTo = actionJson[pushJson.action];
      if (goTo != undefined) {
        if (goTo == 'bpmMessageBoard') {
          var params = {
            procInstId: pushJson.procInstId,
            messageId: pushJson.messageId,
            fromAt: pushJson.fromAt
          };
          var query = {
            messageId: pushJson.messageId
          };
        }
        router.push({
          name: goTo,
          params: params,
          query: query
        });
      }
    }, false);
    plus.push.addEventListener("receive", function (msg) {
      console.info("推送receive：" + JSON.stringify(msg));
      var pushJson = {};
      if (RxUtil.isJson(msg.content)) {
        pushJson = JSON.parse(msg.content);
      } else {
        pushJson = msg.content;
      }
      // 分析msg.payload处理业务逻辑

      var actionJson = {
        "approve": "myToDo",
        "agent": "myToDo",
        "": "index",
        "bpmMessageBoard": "bpmMessageBoard"
      };
      var goTo = actionJson[pushJson.action];
      var params = {};
      if (goTo != undefined) {
        if (plus.os.name == 'Android') {
          if (goTo == 'bpmMessageBoard') {
            var params = {
              procInstId: pushJson.procInstId,
              messageId: pushJson.messageId,
              fromAt: pushJson.fromAt
            };
            var query = {
              messageId: pushJson.messageId
            };
          }
          router.push({
            name: goTo,
            params: params,
            query: query
          });
        } else if (plus.os.name == 'iOS') {
          pushJson.msgOrigin = "LocalMsg";
          plus.push.createMessage("待办消息", JSON.stringify(pushJson), null);
        } else {
          console.info("未知手机系统，暂不支持,很抱歉");
        }
      }
    }, false);
  }
});
/* this file is transformed by vux-loader */
/* eslint-disable */
export const searchModule = {
  //state 数据
  state: {
    toDoSearchVal: "",
    instSearchTreeIds: "",
    instSearchVal: ""
  },
  //同步定义
  mutations: {
    setToDoSearchVal(state, data) {
      state.toDoSearchVal = data;
    },
    setInstSearchTreeIds(state, data) {
      state.instSearchTreeIds = data;
    },
    setInstSearchVal(state, data) {
      state.instSearchVal = data;
    }
  }
  /*
  getters: {
   getCount(state) {
     return state.count + 5;
   },
  }*/
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "rx-layout",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "rx-btnedit",
  props: {},
  data() {
    return {};
  },
  methods: {},
  created() {}
};
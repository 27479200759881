/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'rx-tab-panel',
  props: {
    label: String,
    active: Boolean,
    tabkey: [String, Number]
  },
  computed: {
    classes() {
      return this.$parent.$parent.activeIndex == this._uid ? 'tab-panel-active' : 'tab-panel-unActive';
    }
  },
  methods: {
    panelId() {
      return "panel_" + this.tabkey;
    }
  },
  watch: {
    active() {
      this.$parent.init(true);
    },
    label() {
      this.$parent.init('label');
    }
  }
};
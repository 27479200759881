/* this file is transformed by vux-loader */
/* eslint-disable */
export const bpmModule = {
  //state 数据
  state: {
    taskData: {
      bpmTask: {},
      destNodeUserList: [],
      isReachEndEvent: false,
      jsonData: {}
    },
    instData: {
      bpmSolution: {},
      formModels: [],
      instId: ""
    },
    tabbarBtn: true,
    startFlowData: {
      approveCmd: {},
      processConfig: {},
      nodeIdUsers: {}
    },
    errorModuleData: {},
    errorModuleData2: {}
  },
  //同步定义
  mutations: {
    setData(state, data) {
      state.taskData = data;
    },
    setInstData(state, data) {
      state.instData = data;
    },
    cleanInstData(state) {
      state.instData = {};
    },
    setTabbarBtn(state, data) {
      state.tabbarBtn = data;
    },
    setErrorModuleData(state, data) {
      console.log('写入成功', data);
      state.errorModuleData = data;
    },
    setErrorModuleData2(state, data) {
      console.log('写入成功2', data);
      state.errorModuleData2 = data;
    }
  }
  /*
  getters: {
   getCount(state) {
     return state.count + 5;
   },
  }*/
};
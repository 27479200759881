/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "rx-forms",
  data() {
    return {};
  },
  props: {
    formModels: Array,
    readonly: Boolean
  },
  methods: {
    panelId(item) {
      return "panel_" + item.formKey;
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: "ui-editor",
  props: {
    value: String,
    height: {
      type: String,
      default: "2rem"
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      html: ''
    };
  },
  methods: {
    paste(e) {
      e.preventDefault();
      const html = e.clipboardData.getData('text/html');
      document.execCommand('insertHTML', false, html);
      this.input();
    },
    input() {
      const v = this.$el.innerHTML;
      this.html = v;
      this.$emit('input', v);
    },
    onclick(ev) {
      var range = window.getSelection();
      range.selectAllChildren(this.$el);
      range.collapseToEnd();
    }
  },
  watch: {
    value(v) {
      if (v !== this.html) {
        this.$el.innerHTML = v;
      }
    }
  },
  mounted() {
    const v = this.value;
    if (v) {
      this.$el.innerHTML = v;
      this.html = v;
    }
    //this.$el.focus();
    console.info(this.readonly);
  }
};
import { render, staticRenderFns } from "./RxGroup.vue?vue&type=template&id=f86cfc88&scoped=true&"
import script from "./RxGroup.vue?vue&type=script&lang=js&"
export * from "./RxGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f86cfc88",
  null
  
)

export default component.exports
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import rxboxs from "@/components/common/rx-boxs";
import Vue from "vue";
Vue.component(rxboxs.name, rxboxs);
export default {
  name: "rx-group",
  props: {
    name: String,
    data: Object,
    conf: String,
    type: String,
    single: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      group: "",
      rxBoxVm: {}
    };
  },
  computed: {
    display: function () {
      return this.data[this.name + "_name"];
    }
  },
  mounted() {},
  methods: {
    //打开对话框。
    selectGroupDialog(vm) {
      //发布事件。
      this.$emit("opengroupdialog", this);
    },
    /**
     * 设置选中的用户。
     */
    setGroup(aryGroup) {
      if (!aryGroup || aryGroup.length == 0) {
        this.changeData("", "");
        return;
      }
      var groups = [];
      var aryId = [];
      var aryName = [];
      for (var i = 0; i < aryGroup.length; i++) {
        var group = aryGroup[i];
        //fullname
        var o = {};
        o.id = group.groupId;
        o.text = group.name;
        groups.push(o);
        aryId.push(group.groupId);
        aryName.push(group.name);
      }
      this.group = JSON.stringify(groups);
      this.changeData(aryId.join(","), aryName.join(","));
    },
    /**
     * 修改对应的数据。
     */
    changeData(groupIds, names) {
      this.data[this.name] = groupIds;
      this.data[this.name + "_name"] = names;
    },
    getGroupJson() {
      var ids = this.data[this.name];
      if (!ids) return "";
      var names = this.data[this.name + "_name"];
      var aryIds = !ids ? [] : ids.split(",");
      var aryNames = !names ? [] : names.split(",");
      var aryGroup = [];
      for (var i = 0; i < aryIds.length; i++) {
        var o = {};
        o.id = aryIds[i];
        o.text = aryNames[i];
        aryGroup.push(o);
      }
      return JSON.stringify(aryGroup);
    }
  },
  created() {
    var groupJson = this.getGroupJson();
    this.group = groupJson;
    this.$watch("data." + this.name, function (val, oldVal) {
      var groupJson = this.getGroupJson();
      this.group = groupJson;
    });
    this.$watch("data." + this.name + "_name", function (val, oldVal) {
      var groupJson = this.getGroupJson();
      this.group = groupJson;
    });
  },
  watch: {
    group: function (val, oldVal) {
      if (!val) return;
      var ary = eval("(" + val + ")");
      var aryId = [];
      var aryText = [];
      for (var i = 0; i < ary.length; i++) {
        var o = ary[i];
        aryId.push(o.id);
        aryText.push(o.text);
      }
      this.data[this.name] = aryId.join(",");
      this.data[this.name + "_name"] = aryText.join(",");
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
function getDialogHtml(id, title, content, needFoot) {
  var strTmp = '<div class="dialog-header"><div class="dialog-title">' + title + '</div>' + '<div class="dialog-close"><div class="icon-close" id="head_close_' + id + '">×</div></div></div>' + '<div class="dialog-body" id="body_' + id + '">' + content + '</div>';
  if (needFoot) {
    strTmp += '<div class="dialog-footer">' + '	<div class="footer-left">' + '		<button id="btn_ok_' + id + '" class="btn btn-primary" >确定</button>' + '	</div>' + '	<div class="footer-right">' + '		<button class="btn btn-hollow"  id="btn_close_' + id + '" >取消</button>' + '	</div>' + '</div>';
  }
  return strTmp;
}

/**
 * conf:{
 * 	id:"",
 * 	title:"",
 * 	content:"",
 * 	foot:true,
 * 	full:true
 * }
 * @param {Object} conf
 */
export function openDialog(conf) {
  var full = conf.full;
  var dialogs = document.querySelectorAll("dialog-container");
  var dialogObj = document.getElementById(conf.id);
  if (dialogObj) {
    dialogObj.style.display = "block";
  } else {
    var node = document.createElement("div");
    node.setAttribute("name", "dialog-container");
    if (!conf.full && conf.height) {
      node.setAttribute("style", "height:" + conf.height);
    }
    node.setAttribute("class", full ? "dialog-container-full" : "dialog-container");
    node.setAttribute("id", conf.id);
    var tmpHtml = getDialogHtml(conf.id, conf.title, conf.content, conf.foot);
    node.innerHTML = tmpHtml;
    node.style.zIndex = 100;
    document.body.appendChild(node);
  }
  var btnHeadClose = document.getElementById("head_close_" + conf.id);
  if (btnHeadClose) {
    btnHeadClose.addEventListener('click', function (event) {
      closeDialog(event, btnHeadClose);
    }, false);
  }
  var btnClose = document.getElementById("btn_close_" + conf.id);
  if (btnClose) {
    btnClose.addEventListener('click', function (event) {
      closeDialog(event, btnClose);
    }, false);
  }
  var btn = document.getElementById("btn_ok_" + conf.id);
  if (btn) {
    btn.addEventListener("click", function (event) {
      //closeDialog(event,btnClose)
      conf.callback(event, btn);
    });
  }
}
export function closeDialog(event, obj) {
  var parent = obj.parentNode;
  while (parent) {
    if (parent.hasAttribute("name") && parent.getAttribute("name") == "dialog-container") {
      break;
    }
    parent = parent.parentNode;
  }
  if (parent) {
    parent.parentNode.removeChild(parent);
  }
}